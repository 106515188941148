import classNames from 'classnames'
import { ProgressSpinner } from 'primereact/progressspinner'
import { FC } from 'react'

interface ILoader {
  className?: string
}

export const Loader: FC<ILoader> = ({ className }) => {
  return (
    <div
      className={classNames(
        `w-full h-full absolute inset-0 flex justify-center items-center z-10 bg-opacity-40 bg-black`,
        className
      )}
    >
      <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth='4' animationDuration='.5s' />
    </div>
  )
}
