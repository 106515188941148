import { FC } from 'react'
import { UiText } from '../UiText/UiText'

interface IUiTextArea extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  title: string
}

export const UiTextArea: FC<IUiTextArea> = ({ title, ...props }) => {
  return (
    <div className={`flex flex-col gap-[4px]`}>
      <UiText className='text-grey'>{title}</UiText>
      <textarea
        {...props}
        className={`resize-none text-area !tracking-normal min-h-[100px] bg-white px-[8px] py-[10px] h-[36px] border-solid border-[1px] !text-grey text-base border-green rounded-[3px] focus:outline-none  ${
          props.className
        } ${props.disabled ? 'opacity-[0.6]' : ''}`}
        placeholder='Введите текст'
      ></textarea>
    </div>
  )
}
