import { ApiCashReport, ApiPayment } from '@@types/apiLogicTypes'

import { action, computed, observable } from 'mobx'

export class PaymentsStore {
  @observable
  payments: ApiPayment[] = []

  @observable
  cashReports: ApiCashReport[] = []

  @observable
  cashboxSum: number = 0

  @computed get uniqClients() {
    const set = new Set()
    this.payments.forEach((e) => set.add(e.order.user.name))
    return Array.from(set)
  }

  @action
  changeCashBoxSum = (sum: number) => {
    this.cashboxSum = sum
  }

  @action
  changeCashboxReport = (report: ApiCashReport) => {
    this.cashReports = this.cashReports.map((e) =>
      e.id === report.id
        ? {
            ...e,
            name: report.name,
            check_number: report.check_number,
            sum: report.sum,
            file_path: report.file_path,
            status: report.status,
          }
        : e
    )
  }

  @action
  addCashboxReport = (report: ApiCashReport) => {
    //@ts-ignore
    this.cashReports.unshift({ ...report, type: +report.type })
  }

  @action
  removeCashboxReport = (id: number) => {
    this.cashReports = this.cashReports.filter((e) => e.id !== id)
  }
}
