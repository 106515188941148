import { ApiPayment } from '@@types/apiLogicTypes'
import { Loader } from '@components/ui/Loader/Loader'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { GetDataCheck } from '@logic/kkmLogic'
import NotesForm from '@modules/Schedule/Constructor/NotesForm/NotesForm'
import { observer } from 'mobx-react-lite'
import { Dialog } from 'primereact/dialog'
import { useContext, useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Form } from './Form/Form'

export type TCheck = {
  slip: string
  fiskalNumber: number
}

const TicketSale = observer(() => {
  const logic = useLogic()
  const [isLoading, setIsLoading] = useState(true)
  const [initialPayment, setInitialPayment] = useState<ApiPayment | undefined>(undefined)
  const { id } = useParams()
  const navigate = useNavigate()
  const context = useContext(ToastContext)

  const [visible, setVisible] = useState(false)
  const [strChecks, setStrChecks] = useState<TCheck[]>([])
  console.log(strChecks)

  const {
    auth: { selectedPark },
  } = useStore()

  const setInitialPaymentHandler = (payment: ApiPayment, isNeedToUpdateChecks?: boolean) => {
    if (!isNeedToUpdateChecks) setInitialPayment(payment)
    else setInitialPayment((prev) => ({ ...prev!, status: payment.status, checks: payment.checks }))
  }

  const updatePayment = async () => {
    setIsLoading(true)

    await logic
      .loadPaymentById(+id!)
      .then((data) => {
        data.data && setInitialPayment(data.data)
      })
      .finally(() => setIsLoading(false))
  }

  const toContactPageHandler = (flag?: string) => {
    flag = flag || ''

    const path = generatePath(AppRoutesPaths.ContactEdit, {
      id: String(initialPayment!.order.user.id),
    })
    navigate(path + flag)
  }

  const getCheckHander = async () => {
    if (!initialPayment || !initialPayment?.checks) {
      context?.toastShowHandler({
        status: false,
        errors: 'Чек не найден',
      })
      return
    }
    setStrChecks(
      initialPayment.checks.map((e) => {
        return {
          slip: e.slip,
          fiskalNumber: +e.fiskal_number,
        }
      })
    )
    setVisible(true)
  }

  //Установка initialPayment из mobx хранилища, либо тянем с сервера
  useEffect(() => {
    if (!selectedPark) return
    setIsLoading(true)

    const fetchTicketsAndDiscounts = async () => {
      await Promise.all([logic.loadTicket(), logic.loadDiscounts(selectedPark.id), logic.loadServices()])

      if (!id) {
        setInitialPayment(undefined)
        setIsLoading(false)
        return
      }

      ;(async () => {
        await logic
          .loadPaymentById(+id)
          .then((data) => {
            data.data && setInitialPayment(data.data)
          })
          .finally(() => setIsLoading(false))
      })()
    }

    fetchTicketsAndDiscounts()
  }, [id, selectedPark])

  const backHander = () => {
    window.history.back()
  }

  const getDataCheckHandler = async (fiskalNumber: number) => {
    await GetDataCheck(fiskalNumber, async (result: any) => {
      if (result.Status === 0) {
        setStrChecks([{ fiskalNumber: fiskalNumber, slip: result.Slip }])
        setVisible(true)
      }
      if (result.Status === 2) context?.toastShowHandler({ status: false, errors: result.Error })
      else if (result.Status === 3) context?.toastShowHandler({ status: false, errors: 'Данные не найдены' })
    })
  }

  const printCheckHandler = async (fiskalNumber: number) => {
    await GetDataCheck(
      fiskalNumber,
      async (result: any) => {
        if (result.Status === 0) context?.toastShowHandler({ status: true, summary: 'Успешно' })
        if (result.Status === 2) context?.toastShowHandler({ status: false, errors: result.Error })
        else if (result.Status === 3) context?.toastShowHandler({ status: false, errors: 'Данные не найдены' })
      },
      1
    )
  }

  return (
    <Layout className='!p-0'>
      <div className='min-h-full bg-grey-hover w-full flex flex-col gap-4 h-[calc(100vh_-_82px)]'>
        <header className='bg-white py-3 px-4 flex gap-4 items-center'>
          {!initialPayment && (
            <>
              <i className='pi pi-angle-left text-grey cursor-pointer' onClick={backHander}></i>
              <UiText>Покупка билетов</UiText>
            </>
          )}
          {initialPayment && (
            <UiText>
              <span className='text-blue cursor-pointer' onClick={() => toContactPageHandler()}>
                {initialPayment.order.user.name}
              </span>{' '}
              –{' '}
              <span className='text-blue cursor-pointer' onClick={() => toContactPageHandler('#payments')}>
                Продажи
              </span>{' '}
              – Продажа №{initialPayment.id}
            </UiText>
          )}
        </header>
        <main
          className={`grid grid-cols-[2fr_1fr] h-full bg-grey-hover w-full gap-3 relative overflow-hidden ${
            initialPayment ? '' : '!flex'
          }`}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div
                className={`flex flex-col bg-white w-full gap-3 relative h-full p-4 overflow-auto ${
                  initialPayment ? 'max-w-full' : 'max-w-[858px] m-auto'
                }`}
              >
                <Form
                  initialPayment={initialPayment}
                  updatePaymentHandler={setInitialPaymentHandler}
                  showCheckHandler={getCheckHander}
                  loadPaymentHandler={updatePayment}
                />
              </div>
              {initialPayment && (
                <NotesForm initialState={initialPayment!.order} interactive={false} logOnClick={getDataCheckHandler} />
              )}
            </>
          )}
        </main>
      </div>
      <Dialog
        header='Чек'
        visible={visible}
        onHide={() => setVisible(false)}
        headerClassName='py-2 '
        contentClassName='pt-0 h-fit pb-4'
      >
        <div className='flex gap-8'>
          {initialPayment?.order?.payments?.map((e) => {
            if (!e.checks || e.checks.length === 0) return <></>
            return e.checks.map((c) => (
              <div className='flex flex-col  max-w-[350px]' key={c.id}>
                <pre className='m-0 check max-w-[350px] whitespace-pre-wrap'>{c.slip}</pre>
                <UiButton
                  className='w-[20px] h-[30px]'
                  onClick={() => printCheckHandler(+c.fiskal_number)}
                  color='blue'
                >
                  <i className='pi pi-print text-sm' />
                </UiButton>
              </div>
            ))
          })}
        </div>
      </Dialog>
    </Layout>
  )
})

export default TicketSale
