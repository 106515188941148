import { ApiCreateTicketForm } from '@@types/apiLogicTypes'
import { TicketContainer } from '@components/ui/TicketContainer/TicketContainer'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'

export const AdminAddTicket = observer(() => {
  const {
    services: { units: unitsStore, measures },
    ticket: { types },
    auth: { selectedPark },
  } = useStore()
  const logic = useLogic()

  const context = useContext(ToastContext)

  const title = 'Создание билетов'

  async function sendForm(form: ApiCreateTicketForm) {
    const ticketsForm: ApiCreateTicketForm = {
      name: form.name ?? '',
      category_id: form.category_id,
      measure_id: form.measure_id,
      unit_id: form.unit_id,
    }
    const { status, errors } = await logic.createAdminTickets(ticketsForm)

    if (status) {
      navigateTo(AppRoutesPaths.AdminPanelTickets)
    } else {
      context?.toastShowHandler({ status, errors })
    }
  }

  useEffect(() => {
    logic.loadAdminTickets()
  }, [])
  return (
    <>
      {selectedPark && (
        <TicketContainer
          title={title}
          isAdmin
          types={types ?? []}
          measures={measures ?? []}
          sendForm={sendForm}
          selectedPark={selectedPark}
          unitsStore={unitsStore ?? []}
        />
      )}
    </>
  )
})
