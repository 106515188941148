import { ToastTypeRef } from 'App'
import { createContext } from 'react'

export interface IToastShowHandler {
  status: boolean
  errors?: string | Record<string, string[]>
  summary?: string
}

export type ToastContextType = {
  toast: ToastTypeRef
  toastShowHandler: ({ status, errors, summary }: IToastShowHandler) => void
}

export const ToastContext = createContext<ToastContextType | null>(null)
