import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'

import { ApiServicesMeasures, ApiServicesTypes, ApiServicesUnits } from '@@types/apiLogicTypes'
import { useBoolStateTypeReturn } from '@hooks/commonHooks'
import { UseInputType } from '@hooks/useInput'
import Layout from '@layouts/NonScrollableLayout'
import cn from 'classnames'
import { SetStateAction } from 'react'
import { Checkbox } from '../Checkbox/Checkbox'
import { UiButton } from '../UiButton/UiButton'
import { UiText } from '../UiText/UiText'
import { UiTextField } from '../UiTextField/UiTextField'

export type TicketFormProps = {
  name: UseInputType
  sort: UseInputType
  priceWorkday: UseInputType
  priceWeekday: UseInputType
  types: ApiServicesTypes[] | null
  title: string
  selectedCategory: ApiServicesTypes | null
  setSelectedCategory: (value: SetStateAction<ApiServicesTypes | null>) => void
  measures: ApiServicesMeasures[] | null
  selectedMetering: ApiServicesUnits | null
  changeUnits(event: DropdownChangeEvent): void
  selectedValue: ApiServicesMeasures | null
  setSelectedValue: (value: SetStateAction<ApiServicesMeasures | null>) => void
  units: ApiServicesMeasures[] | null
  sendForm: () => void
  useBoolStateCheckbox: useBoolStateTypeReturn
  isAdmin?: boolean
}
export const TicketForm = (props: TicketFormProps) => {
  const {
    name,
    sort,
    selectedCategory,
    setSelectedCategory,
    types,
    measures,
    selectedMetering,
    changeUnits,
    selectedValue,
    setSelectedValue,
    priceWorkday,
    priceWeekday,
    units,
    sendForm,
    useBoolStateCheckbox,
    title,
    isAdmin,
  } = props
  return (
    <Layout title={title} scrollable={true}>
      <div className={cn('flex flex-col h-full overflow-x-auto rounded-[3px] gap-[16px]', 'no-scroll')}>
        <div className='flex flex-row  gap-[32px] items-center'>
          <UiTextField className='!w-[233px]' name='Название' {...name}></UiTextField>

          {types && (
            <div className='gap-[4px] flex flex-col max-w-[233px] w-[100%]'>
              <UiText className='text-grey'>Категория</UiText>

              <Dropdown
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.value)}
                options={types}
                optionLabel='name'
                placeholder='Категория'
                className='w-full md:w-14rem border-green worker'
                panelClassName='worker-panel'
                color='green'
              />
            </div>
          )}
        </div>
        <div className='flex flex-row  gap-[32px] items-center'>
          <div className='gap-[4px] flex flex-col max-w-[233px] w-[100%]'>
            <UiText className='text-grey'>Измерение</UiText>

            {measures && (
              <Dropdown
                value={selectedMetering}
                onChange={changeUnits}
                options={measures}
                optionLabel='text'
                placeholder='Измерение'
                className='w-full md:w-14rem border-green worker'
                panelClassName='worker-panel'
                color='green'
              />
            )}
          </div>

          <div className='gap-[4px] flex flex-col max-w-[233px] w-[100%]'>
            <UiText className='text-grey'>Значение</UiText>

            <Dropdown
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.value)}
              options={units ?? []}
              optionLabel='text'
              placeholder='Значение'
              className='w-full md:w-14rem border-green worker'
              panelClassName='worker-panel'
              color='green'
              emptyMessage='Нужно выбрать измерение'
              disabled={props.selectedMetering?.text === 'За ребенка'}
            />
          </div>
        </div>
        {!isAdmin && (
          <>
            <div className='flex gap-[32px] items-center'>
              <UiTextField
                className='!w-[233px]'
                name={
                  <span>
                    Цена в будни<span className='text-red'>*</span>
                  </span>
                }
                {...priceWorkday}
              />
              <UiTextField
                className='!w-[233px]'
                name={
                  <span>
                    Цена в выходные<span className='text-red'>*</span>
                  </span>
                }
                {...priceWeekday}
              />
            </div>
            <div className='flex gap-[32px]'>
              <UiTextField
                className='!w-[233px]'
                name='Сортировка'
                placeholder={'Введите значение'}
                {...sort}
              ></UiTextField>
              <div className='flex flex-col gap-1'>
                <UiText className='text-red' weight='bold'>
                  *0 - цена не отображается
                </UiText>
                <UiText className='text-red' weight='bold'>
                  {'-1 - вместо цены "Бесплатно"'}
                </UiText>
              </div>
            </div>
          </>
        )}
        <div className='mt-auto px-[16px] h-[52px] flex flex-row items-end gap-[16px] border-solid border-0 border-t-[1px] border-grey-secondary'>
          <UiButton onClick={sendForm} className='w-[233px]'>
            Сохранить
          </UiButton>
          {!isAdmin && (
            <div className='flex items-center gap-[8px]'>
              <Checkbox useBoolState={useBoolStateCheckbox}></Checkbox>
              <UiText className='text-grey mb-[3px]'>Доступно на сайте</UiText>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}
