import { LoadState } from '@@types/loadState'
import { ServicesContainer } from '@components/ui/ServicesContainer/ServicesContainer'
import { SkalalandSpiner } from '@components/ui/SkalalandSpiner/SkalalandSpiner'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'

import { useLogic, useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'

import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const AdminUpdateServices = observer(() => {
  const {
    services: { units: unitsStore, measures, selectUpdateServices, resetUpdateServices, loadById, types },
    auth: { selectedPark },
    rooms: { rooms },
  } = useStore()

  const logic = useLogic()
  const { id } = useParams()
  const navigate = useNavigate()
  const context = useContext(ToastContext)

  const title = 'Редактировать услуги'
  const subtitle = 'Редактирование услуги'

  async function sendForm(form: FormData) {
    if (!id) {
      return
    }

    form.delete('sort')
    form.delete('price')
    form.delete('park_id')
    form.delete('active')
    const { status, errors } = await logic.editAdminService(form, id)

    if (status) {
      navigateTo(AppRoutesPaths.AdminPanelServices)
    } else {
      context?.toastShowHandler({ status, errors })
    }
  }

  useEffect(() => {
    loadServices()

    return () => resetUpdateServices()
  }, [])

  async function loadServices() {
    if (!id) {
      navigateToServices()
      return
    }

    const response = await logic.loadAdminServicesById(id)

    if (!response) {
      navigateToServices()
      return
    }
  }

  function navigateToServices() {
    navigate(AppRoutesPaths.AdminPanelServices)
  }
  if (loadById === LoadState.Loading) {
    return <SkalalandSpiner />
  }
  return (
    <>
      {selectedPark && selectUpdateServices && rooms && (
        <ServicesContainer
          subtitle={subtitle}
          isAdmin
          title={title}
          rooms={rooms}
          initialValue={selectUpdateServices}
          types={types ?? []}
          measures={measures ?? []}
          callbackForm={sendForm}
          selectedPark={selectedPark}
          unitsStore={unitsStore ?? []}
        />
      )}
    </>
  )
})
