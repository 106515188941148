import { IconElement } from '../IconElement/IconElement'
import { UiButton } from '../UiButton/UiButton'
export type TableButtonsType = {
  onClickEdit?: () => void
  onClickDelete?: () => void
  onClickBox?: () => void
}
export const TableButtons = ({ onClickEdit, onClickDelete, onClickBox }: TableButtonsType) => {
  return (
    <div className='flex flex-row gap-[16px] px-[8px]'>
      {onClickEdit && (
        <UiButton onClick={onClickEdit} icon>
          <IconElement icon={'/icons/EditIcon.svg'} />
        </UiButton>
      )}
      {onClickBox && (
        <UiButton color='grey' icon onClick={onClickBox}>
          <IconElement icon={'/icons/BoxIcon.svg'} />
        </UiButton>
      )}
      {onClickDelete && (
        <UiButton onClick={onClickDelete} color='red' icon>
          <IconElement icon={'/icons/DeleteIcon.svg'} />
        </UiButton>
      )}
    </div>
  )
}
