import { ApiEmployee } from '@@types/apiLogicTypes'
import { buttonBodyTemplate, imgBodyTemplate, phoneTemplate } from '@components/ui/ColumnContents/ColumnContents'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { AppRoutesPaths } from '@config/navigation'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { onAdd } from '@utils/tableUtils'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { FC, useEffect, useState } from 'react'

interface IWorkersPage {
  isAdmin?: boolean
}

export const WorkersPage: FC<IWorkersPage> = observer(({ isAdmin }) => {
  const [isLoading, setIsLoading] = useState(false)
  const logic = useLogic()

  const {
    auth: { selectedPark },
    users: { employees, managers },
  } = useStore()

  useEffect(() => {
    if (!isAdmin) {
      if (employees.length) return

      setIsLoading(true)
      logic.loadEmployees().finally(() => setIsLoading(false))
    } else {
      if (managers.length) return

      setIsLoading(true)
      logic.loadManagers().finally(() => setIsLoading(false))
    }
  }, [selectedPark])

  const parkTemplate = (e: ApiEmployee) => {
    return <span>{e.parks.map((e) => e.name).join(', ')}</span>
  }

  const nameTemplate = (e: ApiEmployee) => {
    const surname = e.surname ? e.surname + ' ' : ''
    const patronymic = e.patronymic ? ' ' + e.patronymic : ''
    return <span>{surname + e.name + patronymic}</span>
  }

  return (
    <Layout title={isAdmin ? 'Управляющие' : 'Сотрудники'}>
      <div className='flex flex-col h-full overflow-x-auto rounded-[3px] gap-[16px]'>
        <UiButton
          onClick={() => onAdd(isAdmin ? AppRoutesPaths.AdminPanelEmployeeAdd : AppRoutesPaths.SettingEmployeeAdd)}
          className='w-[252px]'
        >
          Добавить {isAdmin ? 'управляющего' : 'сотрудника'}
        </UiButton>
        <DataTable
          value={
            isAdmin
              ? managers
              : employees.filter((e) => e.parks.some((item) => item.id === selectedPark?.id && e.roles[0].id !== 3))
          }
          showGridlines
          className={classnames('bg-white text-base')}
          style={{ fontSize: '12px' }}
          removableSort
          sortField='surname'
          sortOrder={1}
          emptyMessage='Нет данных'
          loading={isLoading}
        >
          <Column body={imgBodyTemplate} header='Фото' bodyClassName='text-center'></Column>
          <Column field='surname' header='ФИО' sortable body={nameTemplate} />
          {!isAdmin && <Column field='roles.0.ru' header='Должность' sortable />}
          {isAdmin && <Column header='Центр' body={parkTemplate} />}
          <Column header='Телефон' body={phoneTemplate} />
          <Column
            field=''
            header=''
            body={(item) =>
              buttonBodyTemplate({
                item: item,
                path: isAdmin ? AppRoutesPaths.AdminPanelEmployeeEdit : AppRoutesPaths.SettingEmployeeEdit,
              })
            }
          />
        </DataTable>
      </div>
    </Layout>
  )
})
