import { ApiCreateApplicationForm } from '@@types/apiLogicTypes'
import Counter from '@components/ui/Counter/Counter'
import FormDropdown from '@components/ui/Form/FormDropdown'
import FormMultiselect from '@components/ui/Form/FormMultiselect/FormMultiselect'
import { UiText } from '@components/ui/UiText/UiText'
import { useStore } from '@hooks/storeHook'
import times from '@utils/times'
import cn from 'classnames'
import { Calendar } from 'primereact/calendar'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface IHolidayPart {
  start: string
  end: string
  startHandler: (e: DropdownChangeEvent) => void
  endHandler: (e: DropdownChangeEvent) => void
  isAnimatorIncluded: boolean
  disabled: boolean
}

const HolidayPart: FC<IHolidayPart> = ({ start, end, startHandler, endHandler, isAnimatorIncluded, disabled }) => {
  const methods = useFormContext<ApiCreateApplicationForm>()

  const {
    rooms: { rooms },
    holiday: { crmHolidays },
    services: { entertainments, shows, animations },
  } = useStore()

  /**
   * Increment the count value by 1.
   */
  const incrementCount = () => {
    const count = methods.getValues('count')
    methods.setValue('count', count + 1)
  }

  /**
   * Decrements the 'count' value and updates it using the methods object.
   */
  const decrementCount = () => {
    const count = methods.getValues('count')
    if (count > 0) methods.setValue('count', count - 1)
  }

  return (
    <div className='flex flex-col gap-4 '>
      <UiText size='lg' weight='bold'>
        Праздник
      </UiText>
      <div className='flex gap-4 h-[36px]'>
        <Controller
          control={methods.control}
          name='date'
          rules={{ required: 'Date is required.' }}
          render={({ field, fieldState }) => (
            <Calendar
              value={field.value}
              disabled={disabled}
              onChange={field.onChange}
              showIcon={true}
              dateFormat='dd.mm.yy'
              locale='ru'
              readOnlyInput={true}
              className={cn(`worker shadow-none w-[120px]`, fieldState.error && '!border-red border-solid border')}
              inputClassName='shadow-none'
            />
          )}
        />
        <div className='flex gap-1 items-center'>
          <Dropdown
            value={start}
            className='!w-[82px] border-green worker text-red timeInput h-[36px]'
            panelClassName='worker-panel'
            color='green'
            options={times}
            onChange={(e) => startHandler(e)}
            disabled={disabled}
          />
          <p>—</p>
          <Dropdown
            value={end}
            className={cn('!w-[82px] border-green worker timeInput h-[36px]', end === '' && '!border-red')}
            panelClassName='worker-panel'
            color='green'
            options={times.filter((e) => e > start)}
            onChange={(e) => endHandler(e)}
            disabled={disabled}
          />
        </div>
      </div>
      <FormDropdown name='room' title='Патирум' options={rooms ?? []} size='full' required={true} disabled={disabled} />
      <FormDropdown
        name='order.holiday'
        title='Тариф'
        options={crmHolidays ?? []}
        size='full'
        required={true}
        disabled={disabled}
      />
      <div className='flex gap-4'>
        <Counter
          increment={incrementCount}
          decrement={decrementCount}
          title='Количество гостей'
          value={methods.watch('count')}
          disabled={disabled}
        />
      </div>
      <FormDropdown
        name='programs'
        title={`Анимационная программа (${isAnimatorIncluded ? 'включена' : 'не включена'})`}
        placeholder='Выберите программу'
        options={animations}
        size='full'
        required={isAnimatorIncluded}
        disabled={disabled}
      />
      <FormMultiselect
        name='services'
        title='Шоу'
        customSize='full'
        options={shows}
        disabled={methods.watch('order.holiday') === undefined || disabled}
      />
      <FormMultiselect
        name='entertainments'
        title='Развлечения'
        customSize='full'
        options={entertainments}
        disabled={methods.watch('order.holiday') === undefined || disabled}
      />
    </div>
  )
}

export default HolidayPart
