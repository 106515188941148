import { ApiHoliday } from '@@types/apiLogicTypes'
import { TableButtons } from '@components/ui/ContainerTable/TableButtons'
import { TablePageContainer } from '@components/ui/TablePageContainer/TablePageContainer'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { useLogic, useStore } from '@hooks/storeHook'
import { tableNameHoliday } from '@utils/variableApp'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useEffect } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

export const AdminPartyScreen = observer(() => {
  const logic = useLogic()
  const {
    auth: { selectedPark },
    holiday: { adminHolidays },
  } = useStore()

  const onAdd = () => {
    navigateTo(AppRoutesPaths.AdminPanelPartyAdd)
  }

  const navigate = useNavigate()

  useEffect(() => {
    logic.loadAdminHolidays()
  }, [selectedPark])

  function onClickEdit(item: ApiHoliday) {
    const path = generatePath(AppRoutesPaths.AdminPanelPartyUpdate, {
      id: String(item.id),
    })
    navigate(path)
  }

  const buttonBodyTemplate = (item: ApiHoliday) => {
    return <TableButtons onClickEdit={() => onClickEdit(item)} />
  }

  return (
    <>
      <TablePageContainer
        tableProps={{
          arrayHeadName: tableNameHoliday,
          children: <></>,
          isDeletePhoto: true,
        }}
        mainTitle='Праздник'
        textAdd={'Праздник'}
        onClickAdd={onAdd}
        textArchive={'Праздники'}
        isAdmin
      >
        <DataTable
          value={adminHolidays ?? []}
          showGridlines
          className={classnames('bg-white text-base')}
          style={{ fontSize: '12px' }}
          removableSort
          sortField='name'
          sortOrder={1}
          emptyMessage='Нет данных'
        >
          <Column field='name' header='Название' sortable></Column>
          <Column field='' header='' body={buttonBodyTemplate}></Column>
        </DataTable>
      </TablePageContainer>
    </>
  )
})
