import { UiText } from '@components/ui/UiText/UiText'
import { InputNumber } from 'primereact/inputnumber'
import { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface IFormInput extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string
  title?: string
  className?: string
  customPattern?: RegExp
  completed?: boolean
  containerClassName?: string
}

export const FormInput: FC<IFormInput> = ({
  name,
  title,
  className,
  placeholder = `Введите ${title?.toLocaleLowerCase()}`,
  customPattern,
  completed,
  containerClassName,
  ...props
}) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext()

  const getErrorMessage = () => {
    const keys: string[] = name.split('.')
    let result: any = errors

    for (const key of keys) {
      result = result?.[key]
    }

    return result
  }

  return (
    <div className={`flex flex-col gap-[4px] relative ${containerClassName}`}>
      {title && <UiText className='text-grey'>{title}</UiText>}
      {props.type === 'number' ? (
        <Controller
          name={name}
          control={control}
          rules={{ required: props.required && 'Поле обязательно', min: props.min, max: props.max }}
          render={({ field, fieldState }) => (
            <InputNumber
              id={field.name}
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
              disabled={props.disabled || completed}
              className={`text-field bg-white h-[36px] text-grey border-green rounded-[3px] min-w-[65px]`}
              inputClassName={`border-green !text-left min-w-[65px] ${fieldState.error && 'border-red'} ${className}`}
              placeholder={placeholder}
              min={0}
            />
          )}
        />
      ) : (
        <input
          className={`${className} ${
            !!getErrorMessage() && 'border-red'
          } text-field bg-white px-[8px] py-[10px] h-[36px] border-solid border-[1px] text-grey border-green rounded-[3px] focus:outline-none`}
          placeholder={placeholder}
          {...register(name, {
            required: props.required && 'Обязательное поле',
            pattern: customPattern,
          })}
          disabled={props.disabled || completed}
          {...props}
          required={undefined}
        ></input>
      )}
      {completed && <i className='pi pi-check-circle text-green absolute bottom-[10px] right-[8px]'></i>}
    </div>
  )
}
