import { ApiRoom, ApiRoomType, ApiServicesTypes, FormRoom, Park } from '@@types/apiLogicTypes'
import { itemPhotoFile } from '@components/Settings/ServicePhotoItem'
import { useBoolState } from '@hooks/commonHooks'
import { useInput } from '@hooks/useInput'
import { getImagesRooms } from '@utils/getImagesRooms'
import { useEffect, useState } from 'react'

import { findItem } from '@utils/getCategory'
import { RoomForm } from '../RoomForm/RoomForm'

export type RoomContainerProps = {
  callbackForm: (form: FormRoom) => void
  initialValue?: ApiRoom
  selectedPark?: Park
  title: string
  types: ApiRoomType[]
}
export const RoomContainer = (props: RoomContainerProps) => {
  const { callbackForm, selectedPark, initialValue, title, types } = props
  const selectedCategories = findItem(types ?? [], initialValue?.category_id ?? -1) as ApiServicesTypes

  const images = getImagesRooms(initialValue?.images)

  const useBoolStateCheckbox = useBoolState(Boolean(initialValue?.active ?? false))

  const name = useInput(String(initialValue?.name ?? ''))

  const [selectedCategory, setSelectedCategory] = useState<ApiServicesTypes | null>(selectedCategories ?? null)
  const [color, setSelectedColor] = useState<{ color: string }>({
    color: initialValue?.color ?? '',
  })
  const capacity = useInput(String(initialValue?.capacity ?? ''))

  const description = useInput(initialValue?.description ?? '')

  const [files, setFiles] = useState<itemPhotoFile[]>([...images])
  const [filesDeleteId, setFilesDeleteId] = useState<number[]>([])
  const [isDisabledFile, setDisabledFile] = useState<boolean>(false)

  function setUploadFile(item: itemPhotoFile) {
    setFiles((prev) => [...prev, item])
  }
  function onClickDelete(indexFileDelete: number, id?: number) {
    setFiles((prev) => prev.filter((_, index) => index !== indexFileDelete))
    if (id) {
      setFilesDeleteId((prev) => [...prev, id])
    }
  }

  async function sendForm() {
    if (selectedCategory && selectedPark) {
      callbackForm({
        color: color.color,
        capacity: capacity.value,
        description: description.value,
        name: name.value,
        park_id: String(selectedPark.id),
        active: String(Number(useBoolStateCheckbox[0])),
        id: initialValue?.id,
        delete_images: filesDeleteId,
        images: files,
        category_id: String(selectedCategory.id),
      })
    }
  }

  useEffect(() => {
    if (files.length > 3) {
      setDisabledFile(true)
    } else {
      setDisabledFile(false)
    }
  }, [files])

  return (
    <>
      <RoomForm
        name={name}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        types={types}
        sendForm={sendForm}
        useBoolStateCheckbox={useBoolStateCheckbox}
        isDisabledFile={isDisabledFile}
        setDisabledFile={setDisabledFile}
        files={files}
        onClickDelete={onClickDelete}
        setUploadFile={setUploadFile}
        title={title}
        capacity={capacity}
        description={description}
        setSelectedColor={setSelectedColor}
        color={color}
      />
    </>
  )
}
