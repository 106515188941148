import { AppRoutesPaths, navigateTo } from '@config/navigation'

import { ApiRoom } from '@@types/apiLogicTypes'
import { checkBoxBodyTemplate, imgBodyTemplate } from '@components/ui/ColumnContents/ColumnContents'
import { RoomTableItem } from '@components/ui/ContainerTable/RoomTableItem'
import { TableButtons } from '@components/ui/ContainerTable/TableButtons'
import { TablePageContainer } from '@components/ui/TablePageContainer/TablePageContainer'
import { ArchiveContext } from '@context/archiveContext'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { createFormData } from '@utils/createDataForm'
import { tableNameRooms } from '@utils/variableApp'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useContext, useEffect } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'

export const RoomScreen = observer(() => {
  const logic = useLogic()
  const {
    rooms: { rooms },
  } = useStore()
  const archiveContext = useContext(ArchiveContext)
  const toastContext = useContext(ToastContext)

  const onAdd = () => {
    navigateTo(AppRoutesPaths.SettingsAddRoom)
  }

  const onArchive = () => {
    navigateTo(AppRoutesPaths.SettingsArchiveRoom)
  }

  const location = useLocation()
  const inArchive = location && location.pathname === AppRoutesPaths.SettingsArchiveRoom
  const navigate = useNavigate()

  useEffect(() => {
    logic.loadRoomTypes()
    if (inArchive) logic.loadRooms(true)
    else logic.loadRooms()
  }, [location])

  const colorBodyTemplate = (item: ApiRoom) => {
    return (
      <div className={`absolute inset-0`} style={{ background: item.color }}>
        &nbsp;
      </div>
    )
  }

  async function onDelete(item: ApiRoom) {
    if (item.deleted === 0) {
      const { status, errors } = await logic.deleteRoom(item.id)
      if (!status) {
        toastContext?.toastShowHandler({ status, errors })
      } else logic.loadRooms()
    } else {
      const { status, errors } = await logic.editRoom(createFormData({ deleted: 0 }), item.id)
      if (!status) {
        toastContext?.toastShowHandler({ status, errors })
      } else logic.loadRooms(true)
    }
  }

  async function onChangeActive(item: { active: number | boolean; [key: string]: any }) {
    const form = new FormData()
    form.append('active', String(Number(!Boolean(Number(item.active)))))

    await logic.editActiveRoom(form, item.id)
  }

  function onClickEdit(item: ApiRoom) {
    const path = generatePath(AppRoutesPaths.SettingsEditRoom, {
      id: String(item.id),
    })
    navigate(path)
  }

  const buttonBodyTemplate = (item: ApiRoom) => {
    return (
      <TableButtons
        onClickEdit={!archiveContext?.inArchive ? () => onClickEdit(item) : undefined}
        onClickBox={() => onDelete(item)}
      />
    )
  }

  return (
    <ArchiveContext.Provider value={{ onArchive, inArchive }}>
      <TablePageContainer
        tableProps={{
          arrayHeadName: tableNameRooms,
          children: <TableList items={rooms ?? []} />,
          isDeletePhoto: true,
        }}
        mainTitle='Патирумы'
        textAdd={'Патирум'}
        onClickAdd={onAdd}
        textArchive={'Патирумы'}
      >
        <DataTable
          value={rooms ?? []}
          showGridlines
          className={classnames('bg-white text-base')}
          style={{ fontSize: '12px' }}
          removableSort
          sortField='name'
          sortOrder={1}
          emptyMessage='Нет данных'
          // filters={filters}
        >
          <Column field='photos' body={imgBodyTemplate} header='Фото' bodyClassName='text-center'></Column>
          <Column
            field='name'
            header='Название'
            sortable
            // filter
            // filterPlaceholder='Поиск по названию'
          ></Column>
          <Column field='category.name' header='Категория' sortable></Column>
          <Column field='capacity' header='Вместимость' sortable></Column>
          <Column
            field='color'
            header='Цвет'
            body={colorBodyTemplate}
            bodyStyle={{ padding: 0, position: 'relative' }}
          ></Column>
          {!inArchive && (
            <Column field='active' header='Сайт' body={(item) => checkBoxBodyTemplate(item, onChangeActive)}></Column>
          )}
          <Column field='' header='' body={buttonBodyTemplate}></Column>
        </DataTable>
      </TablePageContainer>
    </ArchiveContext.Provider>
  )
})

export const TableList = (props: { items: ApiRoom[] }) =>
  props.items.map((item) => <RoomTableItem key={item.id} item={item} />)
