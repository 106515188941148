import { Nullable } from '@common/tsUtils'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths } from '@config/navigation'
import { kanbanColors } from '@config/theme'
import { ToastContext } from '@context/toastContext'
import { useLogic } from '@hooks/storeHook'
import { lang } from '@locales/schedulerLanguages'
import { formatDateForApi, getNearestMondayAndSunday, isWeekend } from '@utils/formatDates'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Dispatch, FC, SetStateAction, useCallback, useContext } from 'react'
import { Calendar, EventProps, SlotInfo, ToolbarProps, Views, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop, { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import { generatePath, useNavigate } from 'react-router-dom'
import { IDate, IEvent } from '../SchedulePage'

const localizer = momentLocalizer(moment)
const DragAndDropCalendar = withDragAndDrop(Calendar<IEvent>)

interface IScheduler {
  events: IEvent[]
  setSelectedEvent: Dispatch<SetStateAction<Nullable<IEvent> | null>>
  isLoading: boolean
  setSelectedDate: Dispatch<SetStateAction<IDate | null>>
  defaultDate?: Date
}

export const Scheduler: FC<IScheduler> = observer(
  ({ events, setSelectedEvent, isLoading, setSelectedDate, defaultDate }) => {
    const logic = useLogic()
    const navigate = useNavigate()
    const context = useContext(ToastContext)

    const moveEvent = async ({ event, start, end }: EventInteractionArgs<IEvent>) => {
      const { status, errors } = await logic.updateApplicationsByTime(
        event.id,
        encodeURI(formatDateForApi(start.toString())),
        encodeURI(formatDateForApi(end.toString()))
      )
      if (status) {
        const { monday, sunday } = getNearestMondayAndSunday(event.start ?? new Date())
        await logic.loadScheduleApplications(monday.toISOString().split('T')[0], sunday.toISOString().split('T')[0])
      } else {
        context?.toastShowHandler({ status: status, errors })
      }
    }

    const resizeEvent = async ({ event, start, end }: EventInteractionArgs<IEvent>) => {
      const { status, errors } = await logic.updateApplicationsByTime(
        event.id,
        encodeURI(formatDateForApi(start.toString())),
        encodeURI(formatDateForApi(end.toString()))
      )
      if (status) {
        const { monday, sunday } = getNearestMondayAndSunday(event.start ?? new Date())
        await logic.loadScheduleApplications(monday.toISOString().split('T')[0], sunday.toISOString().split('T')[0])
      } else {
        context?.toastShowHandler({ status: status, errors })
      }
    }

    const confirm = (event: IEvent) => {
      confirmDialog({
        message: 'Вы действительно хотите удалить заявку?',
        header: 'Удаление заявки',
        acceptLabel: 'Да',
        rejectLabel: 'Нет',
        icon: 'pi pi-info-circle',
        rejectClassName: '!bg-black',
        defaultFocus: 'reject',
        acceptClassName: 'p-button-danger',
        accept: async () => {
          await logic.updateApplicationsById(Number(event.order_id), 1000)
          const { monday, sunday } = getNearestMondayAndSunday(new Date())
          await logic.loadScheduleApplications(monday.toISOString().split('T')[0], sunday.toISOString().split('T')[0])
        },
      })
    }

    function MyEvent(event: EventProps<IEvent>) {
      return (
        <div className='flex flex-col h-full gap-1 relative'>
          <UiText weight='bold' className='!text-2base'>
            {event.title}
          </UiText>
          {event.event?.holiday?.room?.name && (
            <UiText className='!text-2base'>{event.event?.holiday.room?.name}</UiText>
          )}
          <UiText className='!text-2base'>{event.event?.user.name}</UiText>
          <UiText className='!text-2base'>№ {event.event?.order_id}</UiText>
          <div onClick={() => confirm(event.event)} className='text-red absolute right-0 -top-[18px]'>
            x
          </div>
        </div>
      )
    }

    const onChangeEventHandler = useCallback((event: IEvent) => {
      const path = generatePath(AppRoutesPaths.ConstructorEdit, {
        id: String(event.order_id),
      })

      navigate(path)
    }, [])

    const onAddEventHandler = (event: SlotInfo) => {
      const newEnd = new Date(event.end)
      newEnd.setHours(event.end.getHours() + 2)
      window.sessionStorage.setItem('event_start', event.start.toString())
      window.sessionStorage.setItem('event_end', newEnd.toString())
      window.sessionStorage.setItem('event_date', event.start.toString())

      navigate(AppRoutesPaths.Constructor)
    }

    return (
      <div className='h-full max-h-full overflow-hidden -mt-[53px]'>
        {isLoading && (
          <div className='w-full h-full absolute inset-0 flex justify-center items-center z-10 bg-opacity-40 bg-black'>
            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth='4' animationDuration='.5s' />
          </div>
        )}
        <ConfirmDialog />
        <DragAndDropCalendar
          localizer={localizer}
          events={events}
          defaultView={Views.WEEK}
          culture={'ru'}
          messages={lang.ru}
          timeslots={1}
          min={new Date(2024, 0, 1, 10, 0, 0, 0)}
          max={new Date(2024, 3, 1, 22, 30, 0, 0)}
          dayPropGetter={customSlotPropGetter}
          eventPropGetter={customEventPropGetter}
          onRangeChange={() => {
            setSelectedEvent(null)
            setSelectedDate(null)
          }}
          components={{
            event: MyEvent,
            toolbar: (e) => RBCToolbar(e),
          }}
          onEventResize={resizeEvent}
          onEventDrop={moveEvent}
          resizable
          dayLayoutAlgorithm='no-overlap'
          views={{ month: false, day: false, agenda: false, week: true }}
          onDoubleClickEvent={(event) => onChangeEventHandler(event)}
          onSelectSlot={onAddEventHandler}
          selectable
          defaultDate={defaultDate}
        />
      </div>
    )
  }
)

const customSlotPropGetter = (date: Date) => {
  if (isWeekend(date))
    return {
      className: 'bg-bg-green',
    }
  else return {}
}

export const customEventPropGetter = (event: IEvent) => {
  return {
    className: '!text-black !rounded-none',
    style: {
      backgroundColor: colorPicker(event.status).secondary,
      borderColor: colorPicker(event.status).primary,
    },
  }
}

const colorPicker = (status: number) => {
  switch (status) {
    case 1:
      return kanbanColors.grey
    case 2:
      return kanbanColors.blue
    case 4:
      return kanbanColors.green
    case 5:
      return kanbanColors.violet
    default:
      return kanbanColors.yellow
  }
}

function RBCToolbar(props: ToolbarProps) {
  const logic = useLogic()
  const goToBack = () => {
    const prevDate = new Date(props.date)
    prevDate.setDate(prevDate.getDate() - 7)
    const { monday, sunday } = getNearestMondayAndSunday(prevDate)
    logic.loadScheduleApplications(monday.toISOString().split('T')[0], sunday.toISOString().split('T')[0])
    props.onNavigate('PREV')
  }

  const goToNext = () => {
    const nextDate = new Date(props.date)
    nextDate.setDate(nextDate.getDate() + 7)
    const { monday, sunday } = getNearestMondayAndSunday(nextDate)
    logic.loadScheduleApplications(monday.toISOString().split('T')[0], sunday.toISOString().split('T')[0])
    props.onNavigate('NEXT')
  }

  return (
    <div className='flex gap-4 items-center justify-end mb-4'>
      <UiButton className='w-9 h-9' id='prev-btn-icon' onClick={goToBack}>
        <i className='pi pi-chevron-left' />
      </UiButton>
      <UiText weight='bold' size='md'>
        {props.label}
      </UiText>
      <UiButton className='w-9 h-9' id='next-btn-icon' onClick={goToNext}>
        <i className='pi pi-chevron-right' />
      </UiButton>
    </div>
  )
}

export default Scheduler
