import { ApiCreateAttractionForm, ApiValidationResponse } from '@@types/apiLogicTypes'
import { AttractionContainer } from '@components/ui/AttractionContainer/AttractionContainer'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { createFormData } from '@utils/createDataForm'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'

export const AdminAddAttraction = observer(() => {
  const {
    auth: { selectedPark },
  } = useStore()
  const logic = useLogic()
  const context = useContext(ToastContext)
  const title = 'Создание аттракционов'

  async function sendForm(form: ApiCreateAttractionForm) {
    const formData = createFormData(form)

    if (form.images) {
      for (let i = 0; i < form.images.length; i++) {
        if (form.images[i].file) {
          formData.append(`images[]`, form.images[i]?.file ?? '')
        }
      }
    }
    formData.delete('images')
    formData.delete('delete_images')
    const { status, errors }: ApiValidationResponse = await logic.createAdminAttraction(formData)

    if (status) {
      navigateTo(AppRoutesPaths.AdminPanelAttraction)
    } else {
      context?.toastShowHandler({ status: false, errors: errors })
    }
  }

  useEffect(() => {
    logic.loadAdminAttraction()
  }, [])
  return <>{selectedPark && <AttractionContainer title={title} sendForm={sendForm} selectedPark={selectedPark} />}</>
})
