import { ArchiveContext } from '@context/archiveContext'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { UiButton } from '../UiButton/UiButton'

export type SettingsHeaderBtnType = {
  textAdd: string
  textArchive: string
  onClickAdd?: () => void
  isAdmin?: boolean
}

export const SettingsHeaderBtn = ({ textAdd, textArchive, onClickAdd, isAdmin }: SettingsHeaderBtnType) => {
  const navigate = useNavigate()
  const context = useContext(ArchiveContext)

  const backHandler = () => {
    navigate(-1)
  }

  return (
    <div className='flex flex-row gap-[16px]'>
      {!context?.inArchive ? (
        <>
          <UiButton onClick={onClickAdd} className='w-[252px]'>
            Добавить {textAdd}
          </UiButton>
          {!isAdmin && (
            <UiButton className='w-[252px]' color='blue' onClick={context?.onArchive}>
              Архивные {textArchive}
            </UiButton>
          )}
        </>
      ) : (
        <UiButton onClick={backHandler} className='w-[252px]'>
          Активные {textArchive}
        </UiButton>
      )}
    </div>
  )
}
