import { Loader } from '@components/ui/Loader/Loader'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths } from '@config/navigation'
import { useLogic, useStore } from '@hooks/storeHook'
import { lang } from '@locales/schedulerLanguages'
import { IEvent } from '@modules/Schedule/SchedulePage'
import { customEventPropGetter } from '@modules/Schedule/Scheduler/Scheduler'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { Calendar, EventProps, Views, momentLocalizer } from 'react-big-calendar'
import { generatePath, useNavigate } from 'react-router-dom'

const localizer = momentLocalizer(moment)

export const TodaySchedule = observer(() => {
  const [events, setEvents] = useState<IEvent[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const logic = useLogic()
  const navigate = useNavigate()

  const {
    auth: { selectedPark },
    applications: { scheduleApplications },
  } = useStore()

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0]
    logic.loadScheduleApplications(today, today).finally(() => setIsLoading(false))
  }, [selectedPark])

  useEffect(() => {
    const mappedApplications = scheduleApplications.map(({ order, ...rest }) => ({
      ...order,
      title: order.holiday?.name ?? 'Услуги',
      start: new Date(rest.starts_at),
      end: new Date(rest.ends_at),
      order_id: rest.order_id,
      id: rest.id,
    }))
    setEvents(mappedApplications)
  }, [scheduleApplications])

  function MyEvent(event: EventProps<IEvent>) {
    return (
      <div className='flex flex-col h-full gap-1 relative'>
        <UiText weight='bold' className='!text-2base'>
          {event.title}
        </UiText>
        {event.event?.holiday && <UiText className='!text-2base'>{event.event?.holiday.room?.name}</UiText>}
        <UiText className='!text-2base'>{event.event?.user.name}</UiText>
        <UiText className='!text-2base'>№ {event.event?.order_id}</UiText>
      </div>
    )
  }

  const onChangeEventHandler = useCallback((event: IEvent) => {
    const path = generatePath(AppRoutesPaths.ConstructorEdit, {
      id: String(event.order_id),
    })

    navigate(path)
  }, [])

  return (
    <section className='h-full overflow-hidden relative'>
      {isLoading && <Loader />}
      <Calendar
        localizer={localizer}
        events={events}
        defaultView={Views.DAY}
        culture={'ru'}
        messages={lang.ru}
        timeslots={1}
        min={new Date(2024, 0, 1, 10, 0, 0, 0)}
        max={new Date(2024, 3, 1, 22, 30, 0, 0)}
        dayPropGetter={customSlotPropGetter}
        eventPropGetter={customEventPropGetter}
        components={{
          event: MyEvent,
        }}
        toolbar={false}
        dayLayoutAlgorithm='no-overlap'
        views={{ month: false, day: true, agenda: false, week: false }}
        defaultDate={new Date()}
        onDoubleClickEvent={(event) => onChangeEventHandler(event)}
      />
    </section>
  )
})

const customSlotPropGetter = () => {
  return {
    className: '!bg-white',
  }
}
