import { kanbanColors } from '@config/theme'
import { useDroppable } from '@dnd-kit/core'
import { FC, ReactNode } from 'react'

export const enum ButtonTypes {
  DELETE = 'DELETE',
  NOT_REALISED = 'NOT_REALISED',
  SUCCESFULL = 'SUCCESSFULL',
  TO_NEW = 'TO_NEW',
}

interface IKanbanButton {
  type: ButtonTypes
  children: ReactNode
  color?: (typeof kanbanColors)[keyof typeof kanbanColors]
}

const KanbanButton: FC<IKanbanButton> = ({ children, color, type }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: type,
    data: {
      type: 'Button',
    },
  })

  return (
    <div
      className={`min-h-full border-0 border-t-4 border-solid w-[236px] flex justify-center items-center bg-transparent font-bold cursor-pointer text-2base transition-colors`}
      style={{
        borderColor: color?.primary,
        color: color?.primary,
        backgroundColor: isOver ? color?.secondary : undefined,
      }}
      ref={setNodeRef}
    >
      {children}
    </div>
  )
}

export default KanbanButton
