import { FC, ReactNode } from 'react'

type KanbanBoardProps = {
  children: ReactNode[]
}

const KanbanBoard: FC<KanbanBoardProps> = ({ children }) => {
  return (
    <section className='w-full overflow-auto flex justify-center'>
      <div
        className={`w-full grid justify-between gap-[16px] max-w-[1440px]`}
        style={{
          gridTemplateColumns: `repeat(${children.length}, minmax(0, 1fr))`,
        }}
      >
        {children}
      </div>
    </section>
  )
}

export default KanbanBoard
