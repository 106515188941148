import { ApiCreateApplicationForm } from '@@types/apiLogicTypes'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { IDisabled } from '@modules/TicketSale/Form/Form'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import AnimatorSelector from './AnimatorSelector'

const AnimatorPart: FC<IDisabled> = observer(({ disable }) => {
  const methods = useFormContext<ApiCreateApplicationForm>()
  const [activeAnimators, setActiveAnimators] = useState(
    methods.getValues('animators')?.length === 2 ? [1, 2] : methods.getValues('animators')?.length === 1 ? [1] : [0]
  )

  /**
   * A function that adds animator handlers based on certain conditions.
   *
   * @return {void} This function does not return anything.
   */
  const addAnimatorHandler = () => {
    activeAnimators.includes(0) && setActiveAnimators((prev) => [...prev, 1])
    activeAnimators.includes(1) && setActiveAnimators((prev) => [...prev, 2])
    activeAnimators.includes(2) && setActiveAnimators((prev) => [1, ...prev])
  }

  /**
   * Remove an animator handler at the specified index.
   *
   * @param {number} index - The index of the animator handler to be removed
   * @return {void} This function does not return anything.
   */
  const removeAnimatorHandler = (index: number) => {
    methods.setValue(
      `animators.${index}`,
      {
        starts_at: '',
        ends_at: '',
        //@ts-ignore
        entity: null,
      },
      { shouldDirty: true }
    )
    setActiveAnimators((prev) => prev.filter((e) => e !== index + 1))
  }

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex gap-4 items-center'>
        <UiText size='lg' weight='bold'>
          Аниматор
        </UiText>
        <UiButton
          onClick={addAnimatorHandler}
          type='button'
          disabled={activeAnimators.includes(2) || disable}
          color={activeAnimators.includes(2) ? 'grey' : 'green'}
        >
          <i className='pi pi-plus text-sm'></i>
        </UiButton>
      </div>
      {activeAnimators.includes(1) && <AnimatorSelector index={0} removeHandler={removeAnimatorHandler} />}
      {activeAnimators.includes(2) && <AnimatorSelector index={1} removeHandler={removeAnimatorHandler} />}
    </div>
  )
})

export default AnimatorPart
