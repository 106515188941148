import { appColors } from '@config/theme'
import { useBoolState } from '@hooks/commonHooks'
import { useState } from 'react'
import { UiText } from '../UiText/UiText'
import { UiTextField } from '../UiTextField/UiTextField'
import { DropDownArrow } from './DropDownArrow'
import { SortArrowDown } from './SortArrowDown'
import { SortArrowUp } from './SortArrowUp'

export enum SortTypes {
  sorting = 'Сортировка',
  filter = 'Фильтр',
}

type SelectedItem = { type: SortTypes; value: string }

export const TableFilter = ({ placeholder }: { placeholder?: string }) => {
  const [selected, setSelected] = useState<SelectedItem>()
  const [opened, open, close] = useBoolState(false)
  const sortObject = [
    {
      type: SortTypes.sorting,
      name: 'Сортировать по возрастанию',
      icon: SortArrowUp,
    },
    {
      type: SortTypes.sorting,
      name: 'Сортировать по убыванию',
      icon: SortArrowDown,
    },
  ]

  const onOpen = () => (opened ? close() : open())

  const onSelectSorting = (value: (typeof sortObject)[0]) => {
    setSelected({ type: value.type, value: value.name })
    close()
  }

  return (
    <th className='min-w-[160px]  border-solid border-[1px] border-grey-secondary relative !text-sm !font-bold '>
      <div className='relative'>
        <div onClick={onOpen} className=' pl-[8px]  cursor-pointer w-full flex flex-row  justify-between items-center'>
          {placeholder}
          {selected && (
            <UiText size='sm' className='text-green font-bold'>
              {selected.type}
            </UiText>
          )}
          <DropDownArrow color={selected ? appColors.green : appColors.grey} />
        </div>
      </div>
      {opened && (
        <div className='absolute flex flex-col left-[-1px] w-[194px] min-h-[36px] p-[8px] bg-white top-[38px]  z-dropdown border-solid border-[1px] border-grey-secondary gap-[16px] '>
          <UiTextField placeholder='Фильтр по названию'></UiTextField>
          {sortObject.map((item) => {
            const isSelected = item.name === selected?.value
            const Icon = item.icon
            return (
              <div
                key={item.name}
                onClick={onSelectSorting.bind(this, item)}
                className=' cursor-pointer w-full flex flex-row justify-between items-center'
              >
                <UiText
                  style={{
                    color: isSelected ? appColors.green : appColors.black,
                  }}
                  className='!font-normal text-left max-w-[142px]'
                  size='sm'
                >
                  {item.name}
                </UiText>
                <Icon color={isSelected ? appColors.green : appColors.grey} />
              </div>
            )
          })}
        </div>
      )}
    </th>
  )
}
