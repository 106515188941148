import { ApiFormCreateHoliday, ApiHoliday, ApiProduct, ApiRoom, ApiShowsServices } from '@@types/apiLogicTypes'
import { useBoolState } from '@hooks/commonHooks'
import { useInput } from '@hooks/useInput'
import { getProducts } from '@utils/getProducts'
import { getShows } from '@utils/getShows'
import { holidayDurations } from '@utils/times'
import { toJS } from 'mobx'
import { useEffect, useState } from 'react'
import { PartyForm } from '../PartyForm/PartyForm'

export type PartyContainerProps = {
  initialValue?: ApiHoliday
  title: string
  callbackForm?: (form: ApiFormCreateHoliday) => void
  partyRooms: ApiRoom[]
  products: ApiProduct[]
  shows: ApiShowsServices[]
  entertaimentPrograms: ApiShowsServices[]
  isAdmin?: boolean
}

export type TDuration = {
  title: string
  minutes: number
}

export const PartyContainer = (props: PartyContainerProps) => {
  const isAdmin = props.isAdmin ?? false

  const name = useInput(String(props?.initialValue?.name ?? ''))
  const weekdayPrice = useInput(String(props?.initialValue?.weekday_price ?? ''))
  const weekendPrice = useInput(String(props?.initialValue?.weekend_price ?? ''))
  const weekdayAddPrice = useInput(String(props?.initialValue?.weekday_additional_price ?? ''))
  const weekendAddPrice = useInput(String(props?.initialValue?.weekend_additional_price ?? ''))

  const count = useInput(String(props.initialValue?.count ?? ''))
  const useBoolStateCheckbox = useBoolState(Boolean(props.initialValue?.active ?? false))
  const useBoolStateShowInCrm = useBoolState(Boolean(props.initialValue?.show_in_crm ?? false))
  const useBoolStateAnimator = useBoolState(Boolean(props.initialValue?.has_animator ?? false))

  const [selectedRoom, setSelectedRoom] = useState<ApiRoom | null>(toJS(props.initialValue?.room) ?? null)

  const [selectedDuration, setDuration] = useState<TDuration | null>(
    holidayDurations.find((e) => e.minutes === props.initialValue?.duration) ?? null
  )

  const [productSelected, setProductSelected] = useState<ApiProduct[] | []>([])
  const [showsSelected, setShowsSelected] = useState<ApiShowsServices[] | []>(props.initialValue?.shows ?? [])

  const [programsSelected, setProgramsSelected] = useState<ApiShowsServices[] | []>(props.initialValue?.programs ?? [])

  function sendForm() {
    if (props.callbackForm) {
      const id = String(props.initialValue?.id ?? -1)
      props.callbackForm({
        name: name.value,

        count: count.value,
        weekday_price: weekdayPrice.value,
        weekday_additional_price: weekdayAddPrice.value,
        weekend_price: weekendPrice.value,
        weekend_additional_price: weekendAddPrice.value,
        active: String(Number(useBoolStateCheckbox[0])),
        show_in_crm: String(Number(useBoolStateShowInCrm[0])),
        has_animator: String(Number(useBoolStateAnimator[0])),
        shows: getShows(showsSelected),
        programs: getShows(programsSelected),
        products: getProducts(productSelected),
        room_id: String(selectedRoom?.id ?? ''),
        id: id,
        ...(selectedDuration ? { duration: selectedDuration?.minutes } : {}),
      })
    }
  }

  function onSelectedRoom(room: ApiRoom) {
    setSelectedRoom(room)
  }

  function onSelectDuraionHandler(duration: TDuration | null) {
    setDuration(duration)
  }

  function incrementCounter(selected: ApiProduct) {
    const indexItem = productSelected.findIndex((item) => item.id === selected.id)
    if (indexItem >= 0) {
      const obj = productSelected[indexItem] as ApiProduct

      if (obj.count) {
        obj.count++
      } else {
        obj.count = 1
      }

      setProductSelected((prev) => [...prev.map((item) => (item.id === obj.id ? obj : item))])
    }
  }

  function decrementCounter(selected: ApiProduct) {
    const indexItem = productSelected.findIndex((item) => item.id === selected.id)

    if (indexItem >= 0) {
      const obj = productSelected[indexItem] as ApiProduct

      if (obj.count && obj.count > 0) {
        obj.count--
      } else {
        obj.count = 0
      }

      setProductSelected((prev) => [...prev.map((item) => (item.id === obj.id ? obj : item))])
    }
  }

  function deleteSelectedProduct(id: number) {
    setProductSelected((prev) => {
      if (prev && prev?.length > 0) {
        return prev.filter((item) => item.id !== id)
      }
      return []
    })
  }

  function deleteSelectedShows(id: number) {
    setShowsSelected((prev) => {
      if (prev && prev?.length > 0) {
        return prev.filter((item) => item.id !== id)
      }
      return []
    })
  }

  function deleteSelectedPrograms(id: number) {
    setProgramsSelected((prev) => {
      if (prev && prev?.length > 0) {
        return prev.filter((item) => item.id !== id)
      }
      return []
    })
  }

  function onSelectedShows(items: ApiShowsServices[]) {
    setShowsSelected([...items])
  }

  function onSelectedPrograms(items: ApiShowsServices[]) {
    setProgramsSelected([...items])
  }

  function onSelectedProducts(items: ApiProduct[]) {
    setProductSelected([...items])
  }

  useEffect(() => {
    // этот костыль нужно переделать, проблема возникает из за того что два разных обьекта приходят с бэка и сам комопнент не видит что одинаковые, обьекты находятся в разной памяти и при проверке false
    if (props.initialValue && props.initialValue.products) {
      const array: ApiProduct[] = []

      props.products.forEach((mainItem) => {
        if (mainItem.id) {
          props.initialValue?.products.forEach((item) => {
            if (item.id === mainItem.id) {
              mainItem.count = item.pivot?.count ?? 0
              array.push(mainItem)
            }
          })
        }
      })

      setProductSelected(array)
    }
  }, [props.initialValue, props.products])
  useEffect(() => {
    // этот костыль нужно переделать, проблема возникает из за того что два разных обьекта приходят с бэка и сам комопнент не видит что одинаковые, обьекты находятся в разной памяти и при проверке false
    if (props.initialValue) {
      const array: ApiShowsServices[] = []
      const arrayId: number[] = props.initialValue.shows.map((item) => item.id)

      props.shows.forEach((mainItem) => {
        if (mainItem.id) {
          arrayId.some((item) => {
            if (item === mainItem.id) {
              array.push(mainItem)
            }
          })
        }
      })

      setShowsSelected(array)
    }
  }, [props.initialValue, props.shows])

  useEffect(() => {
    if (props.initialValue?.room) {
      const room = props.initialValue.room

      if (!isAdmin) {
        const selectedRoomIndex = props.partyRooms.findIndex((item) => item.id === room.id)
        if (selectedRoomIndex >= 0) {
          setSelectedRoom(props.partyRooms[selectedRoomIndex])
        }
      }
    }
  }, [props.initialValue, props.initialValue?.room])
  return (
    <>
      <PartyForm
        deleteSelectedShows={deleteSelectedShows}
        deleteSelectedPrograms={deleteSelectedPrograms}
        deleteSelectedProduct={deleteSelectedProduct}
        decrementCounter={decrementCounter}
        incrementCounter={incrementCounter}
        onSelectedShows={onSelectedShows}
        onSelectedPrograms={onSelectedPrograms}
        selectedRoom={selectedRoom}
        setSelectedRoom={onSelectedRoom}
        name={name}
        count={count}
        weekdayPrice={weekdayPrice}
        weekendPrice={weekendPrice}
        weekdayAddPrice={weekdayAddPrice}
        weekendAddPrice={weekendAddPrice}
        title={props.title}
        partyArray={props.partyRooms}
        sendForm={sendForm}
        useBoolStateCheckbox={useBoolStateCheckbox}
        useBoolStateAnimator={useBoolStateAnimator}
        useBoolStateShowInCrm={useBoolStateShowInCrm}
        itemsProduct={props.products}
        itemsShows={props.shows}
        itemsPrograms={props.entertaimentPrograms}
        productsSelected={productSelected}
        showsSelected={showsSelected}
        programsSelected={programsSelected}
        setProductSelected={onSelectedProducts}
        isAdmin={isAdmin}
        selectedDuration={selectedDuration}
        onSelectDuration={onSelectDuraionHandler}
      />
    </>
  )
}
