import { AppRoutes, AppRoutesType } from '@config/navigation'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { RootNavigation } from './RootNavigation'

export const RootPage = () => {
  const location = useLocation()

  const findElementByName = (tree: AppRoutesType, value: string): any => {
    for (const node of tree) {
      if (node.path === value) {
        return node
      }
      if (node.childs) {
        const foundElement = findElementByName(node.childs, value)
        if (foundElement) {
          return foundElement
        }
      }
    }
    return null
  }

  useEffect(() => {
    const element = findElementByName(AppRoutes, location.pathname)
    if (element?.name) {
      document.title = 'Скаласофт: ' + element.name
    }
  }, [location])

  return (
    <div className='flex flex-row p-[16px] max-sm:p-0 '>
      <RootNavigation></RootNavigation>
      <Outlet />
    </div>
  )
}
