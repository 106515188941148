import { City } from '@@types/apiLogicTypes'
import { Checkbox } from '@components/ui/Checkbox/Checkbox'
import { brands } from '@components/ui/EditPark/EditPark'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useBoolState } from '@hooks/commonHooks'

import { useLogic, useStore } from '@hooks/storeHook'
import { useInput } from '@hooks/useInput'
import Layout from '@layouts/NonScrollableLayout'
import { observer } from 'mobx-react-lite'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const ParkPageAdd = observer(() => {
  const name = useInput()
  const slug = useInput()
  const address = useInput()
  const location = useInput()
  const email = useInput()

  const logic = useLogic()
  const navigate = useNavigate()
  const context = useContext(ToastContext)

  const [selectedCity, setSelectedCity] = useState<City | null>(null)
  const [selectedBrandId, setSelectedBrandId] = useState<(typeof brands)[number]>(brands[0])

  const {
    park: { cities },
  } = useStore()
  const isActive = useBoolState(Boolean(0))

  async function onAddPark() {
    if (
      selectedCity &&
      name.value.length > 3 &&
      slug.value.length > 3 &&
      address.value.length > 3 &&
      location.value.length > 3 &&
      email.value.length > 3
    ) {
      const response = await logic.addPark({
        name: name.value,
        slug: slug.value,
        address: address.value,
        location: typeof location.value === 'string' ? location.value.split(',') : location.value,
        city_id: selectedCity.id,
        emails: [email.value],
        brand_id: selectedBrandId.id,
        active: isActive[0] ? 1 : 0,
      })

      if (response) {
        navigate(AppRoutesPaths.AdminPanelPark)
      }
    } else {
      context?.toastShowHandler({ status: false, errors: 'Заполните все поля' })
    }
  }

  useEffect(() => {
    logic.loadСities()
  }, [])
  return (
    <Layout title='Добавление центра' scrollable={true}>
      <div className='flex flex-col h-full rounded-[3px] gap-[16px]'>
        <div className='flex gap-[16px] flex-col'>
          <div>
            <UiText size='lg'>Название Центра</UiText>
          </div>
          <div className='p-inputgroup flex-1'>
            <InputText {...name} placeholder='name' />
          </div>
          <div>
            <UiText size='lg'>Слаг Центра</UiText>
          </div>
          <div className='p-inputgroup flex-1'>
            <InputText {...slug} placeholder='slug' />
          </div>
          <div>
            <UiText size='lg'>Адрес Центра</UiText>
          </div>
          <div className='p-inputgroup flex-1'>
            <InputText {...address} placeholder='address' />
          </div>
          <div>
            <UiText size='lg'>Локация Центра</UiText>
          </div>
          <div className='p-inputgroup flex-1'>
            <InputText {...location} placeholder='location' />
          </div>
          <div className='gap-[16px] flex flex-col'>
            <div>
              <UiText size='lg'>Город Центра</UiText>
            </div>
            {cities && (
              <Dropdown
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.value)}
                options={cities}
                optionLabel='name'
                placeholder='Select a City'
                className='w-full md:w-14rem'
              />
            )}
          </div>
          <div>
            <UiText size='lg'>Почта для заявок</UiText>
          </div>
          <div className='p-inputgroup flex-1'>
            <InputText {...email} placeholder='email' />
          </div>
        </div>

        <div className='flex gap-4'>
          <div className='gap-4 flex flex-col max-w-[233px] w-[100%]'>
            <UiText size='lg'>Бренд</UiText>

            <Dropdown
              value={selectedBrandId}
              onChange={(e) => setSelectedBrandId(e.value)}
              options={brands}
              optionLabel='name'
              placeholder='Бренд'
              className='w-full md:w-14rem'
            />
          </div>

          <div className='flex items-center gap-[8px] mt-auto'>
            <Checkbox useBoolState={isActive}></Checkbox>
            <UiText className='text-grey mb-[3px]'>Доступно на сайте</UiText>
          </div>
        </div>

        <Button
          onClick={onAddPark}
          severity='success'
          className='hover:bg-green-[#16A34A] !bg-green w-[100%] !font-black  justify-center max-h-[36px] uppercase !text-sm mt-auto'
        >
          Добавить центр
        </Button>
      </div>
    </Layout>
  )
})
