import { ApiBirthdayGame, ApiGame, ApiGameZone, ApiPC } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { action, observable } from 'mobx'

export class GameStore {
  @observable
  loading = LoadState.Initial

  @observable
  computers: ApiPC[] = []

  @observable
  gameZones: ApiGameZone[] = []

  @observable
  games: ApiGame[] = []

  @observable
  holidays: ApiBirthdayGame[] = []

  @action
  removeHolidayFromState = (id: number) => {
    this.holidays = this.holidays.filter((e) => e.id != id)
  }

  @action
  editHoliday = (holiday: ApiBirthdayGame) => {
    this.holidays = this.holidays.map((e) => (e.id === holiday.id ? holiday : e))
  }
}
