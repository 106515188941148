import { Park } from '@@types/apiLogicTypes'
import { itemPhotoFile } from '@components/Settings/ServicePhotoItem'
import { Checkbox } from '@components/ui/Checkbox/Checkbox'
import { ToastContext } from '@context/toastContext'
import { useBoolStateTypeReturn } from '@hooks/commonHooks'
import { UseInputType } from '@hooks/useInput'
import Layout from '@layouts/NonScrollableLayout'
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload'
import { RefObject, useContext, useRef } from 'react'
import { LoadPhoto } from '../LoadPhoto/LoadPhoto'
import { UiButton } from '../UiButton/UiButton'
import { UiText } from '../UiText/UiText'
import { UiTextArea } from '../UiTextArea/UiTextArea'
import { UiTextField } from '../UiTextField/UiTextField'

export type EditCenterProps = {
  name: UseInputType
  phone: UseInputType
  worktime: UseInputType
  vk: UseInputType
  abbreviation: UseInputType
  id: UseInputType
  centerKey: UseInputType
  title: UseInputType
  description: UseInputType
  isShowTicketPayment: useBoolStateTypeReturn
  isShowOnlinePayment: useBoolStateTypeReturn
  isShowDr: useBoolStateTypeReturn
  isShowKitchen: useBoolStateTypeReturn
  form1Title: UseInputType
  form3Title: UseInputType
  whatsapp: UseInputType
  whatsappId: UseInputType
  whatsappToken: UseInputType
  yandexId: UseInputType

  selectedPark: Park

  privacyFile: File | null
  setPrivacyFile: (file: File | null) => void
  termsOfUseFile: File | null
  setTermsOfUseFile: (file: File | null) => void
  bonusProgramFile: File | null
  setBonusProgramFile: (file: File | null) => void
  safetyPrecautionsFile: File | null
  setSafetyPrecautionsFile: (file: File | null) => void
  requisitesFile: File | null
  setRequisitesFile: (file: File | null) => void

  setDisabledFile: (isDisabledFile: boolean) => void
  editPark(): void
  isDisabledFile: boolean

  files: itemPhotoFile[]

  setUploadFile(item: itemPhotoFile): void
  onClickDeleteFile(index: number, id?: number): void
}

export const EditCenter = (props: EditCenterProps) => {
  const privacyFileRef = useRef<FileUpload>(null)
  const termsOfUseFileRef = useRef<FileUpload>(null)
  const bonusProgramFileRef = useRef<FileUpload>(null)
  const safetyPrecautionsFileRef = useRef<FileUpload>(null)
  const requisitesFileRef = useRef<FileUpload>(null)
  const context = useContext(ToastContext)

  function onUpload() {
    context?.toastShowHandler({ status: true })
  }

  const customBaseUploader = async (
    event: FileUploadHandlerEvent,
    ref: RefObject<FileUpload>,
    dispatch: (file: File | null) => void
  ) => {
    const file = event.files[0]

    const isSuccess = true

    const reader = new FileReader()
    const nameFile = file.name.replace(/ /g, '')

    reader.readAsDataURL(file)
    reader.onloadend = function () {
      const base64String = reader?.result?.toString().replace('data:', '').replace(/^.+,/, '').replace(/ /g, '') // Убираем пробелы из base64 строки

      if (base64String && nameFile) {
        dispatch(file)
      }
    }

    if (isSuccess) {
      onUpload()
    } else {
      onFailedUpload()
    }

    if (privacyFileRef.current) {
      const fileUpload = ref.current as FileUpload
      fileUpload.clear()
    }
  }

  function onFailedUpload() {
    context?.toastShowHandler({ status: false, errors: 'Не удалось загрузить файл' })
  }

  return (
    <Layout title='Изменить сайт' scrollable={true}>
      <div className='flex flex-col h-full overflow-x-auto rounded-[3px] gap-[16px]'>
        <div className='flex gap-8 flex-col'>
          <div className='flex flex-col gap-4 pb-8 border-0 border-b border-grey-secondary border-solid'>
            <div className='flex  gap-4'>
              <UiTextField className='!w-[233px]' name='Название Центра' {...props.name} />
              <UiTextField
                className='!w-[233px]'
                name='Телефон Центра'
                placeholder='Введите номер телефона'
                {...props.phone}
              />
              <UiTextField className='!w-[233px]' name='Юр. информация' {...props.abbreviation} />
            </div>
            <div className='flex  gap-4'>
              <UiTextField className='!w-[233px]' name='Время работы Центра' {...props.worktime} />
              <UiTextField
                className='!w-[233px]'
                name='ВК Центра'
                placeholder='Например, https://vk.com/skalalend'
                {...props.vk}
              />
              <UiTextField
                className='!w-[233px]'
                name='Yandex-ID центра'
                placeholder='Введите Yandex-ID для отзывов'
                {...props.yandexId}
              />
            </div>
            <div className='flex  gap-4'>
              <UiTextField className='!w-[233px]' name='ID Центра' {...props.id} />
              <UiTextField className='!w-[233px]' name='Ключ Центра' {...props.centerKey} />
            </div>
          </div>
          <div className='flex gap-4 pb-8 border-0 border-b border-grey-secondary border-solid'>
            <UiTextField
              className='!w-[233px]'
              name='WhatsApp Центра'
              placeholder='Введите номер WhatsApp'
              {...props.whatsapp}
            />
            <UiTextField
              className='!w-[233px]'
              name='WhatsApp ID'
              placeholder='Введите WhatsApp ID'
              {...props.whatsappId}
            />
            <UiTextField
              className='!w-[233px]'
              name='WhatsApp токен'
              placeholder='Введите WhatsApp токен'
              {...props.whatsappToken}
            />
          </div>
          <div className='flex flex-col gap-4 pb-8 border-0 border-b border-grey-secondary border-solid'>
            <UiTextField className='!w-[713px]' name='Заголовок Центра' {...props.title} />
            <div className='flex gap-4'>
              <UiTextField className='!w-[349px]' name='Заголовок формы 1' {...props.form1Title} />
              <UiTextField className='!w-[349px]' name='Заголовок формы 3' {...props.form3Title} />
            </div>
            <UiTextArea className='!w-[713px]' title='Описание Центра' {...props.description} />
            <section className='flex gap-6'>
              <div className='flex items-center gap-[8px]'>
                <Checkbox useBoolState={props.isShowKitchen}></Checkbox>
                <UiText className='text-grey mb-[3px]'>Кухня</UiText>
              </div>
              <div className='flex items-center gap-[8px]'>
                <Checkbox useBoolState={props.isShowTicketPayment}></Checkbox>
                <UiText className='text-grey mb-[3px]'>Оплата билетов</UiText>
              </div>
              <div className='flex items-center gap-[8px]'>
                <Checkbox useBoolState={props.isShowOnlinePayment}></Checkbox>
                <UiText className='text-grey mb-[3px]'>Онлайн оплата</UiText>
              </div>
              <div className='flex items-center gap-[8px]'>
                <Checkbox useBoolState={props.isShowDr}></Checkbox>
                <UiText className='text-grey mb-[3px]'>Раздел ДР</UiText>
              </div>
            </section>
          </div>
          <div className='flex flex-col gap-4 pb-8 border-0 border-b border-grey-secondary border-solid'>
            <div className='grid gap-4 grid-cols-2 w-fit'>
              <div className={`flex flex-col gap-[4px]`}>
                <UiText className='text-grey'>Политика конфиденциальности</UiText>
                <FileUpload
                  className=' overflow-hidden h-9 w-[233px] max-w-[233px] whitespace-nowrap'
                  customUpload
                  mode='basic'
                  name='demo[]'
                  onUpload={onUpload}
                  auto
                  accept='.pdf'
                  uploadHandler={(e) => customBaseUploader(e, privacyFileRef, props.setPrivacyFile)}
                  ref={privacyFileRef}
                  chooseLabel={
                    props.privacyFile?.name ?? props.selectedPark?.privacy_policy?.split('/')?.pop() ?? 'Выбрать'
                  }
                />
              </div>
              <div className={`flex flex-col gap-[4px]`}>
                <UiText className='text-grey'>Пользовательское соглашение</UiText>
                <FileUpload
                  className=' overflow-hidden h-9 w-[233px] max-w-[233px] whitespace-nowrap'
                  customUpload
                  mode='basic'
                  name='demo[]'
                  onUpload={onUpload}
                  auto
                  accept='.pdf'
                  uploadHandler={(e) => customBaseUploader(e, termsOfUseFileRef, props.setTermsOfUseFile)}
                  ref={termsOfUseFileRef}
                  chooseLabel={
                    props.termsOfUseFile?.name ?? props.selectedPark?.terms_of_use?.split('/')?.pop() ?? 'Выбрать'
                  }
                />
              </div>
              <div className={`flex flex-col gap-[4px]`}>
                <UiText className='text-grey'>Бонусная программа</UiText>
                <FileUpload
                  className=' overflow-hidden h-9 w-[233px] max-w-[233px] whitespace-nowrap'
                  customUpload
                  mode='basic'
                  name='demo[]'
                  onUpload={onUpload}
                  auto
                  accept='.pdf'
                  uploadHandler={(e) => customBaseUploader(e, bonusProgramFileRef, props.setBonusProgramFile)}
                  ref={bonusProgramFileRef}
                  chooseLabel={
                    props.bonusProgramFile?.name ?? props.selectedPark?.bonus_program?.split('/')?.pop() ?? 'Выбрать'
                  }
                />
              </div>
              <div className={`flex flex-col gap-[4px]`}>
                <UiText className='text-grey'>Техника безопасности</UiText>
                <FileUpload
                  className=' overflow-hidden h-9 w-[233px] max-w-[233px] whitespace-nowrap'
                  customUpload
                  mode='basic'
                  name='demo[]'
                  onUpload={onUpload}
                  auto
                  accept='.pdf'
                  uploadHandler={(e) => customBaseUploader(e, safetyPrecautionsFileRef, props.setSafetyPrecautionsFile)}
                  ref={safetyPrecautionsFileRef}
                  chooseLabel={
                    props.safetyPrecautionsFile?.name ??
                    props.selectedPark?.safety_precautions?.split('/')?.pop() ??
                    'Выбрать'
                  }
                />
              </div>
              <div className={`flex flex-col gap-[4px]`}>
                <UiText className='text-grey'>Реквизиты</UiText>
                <FileUpload
                  className=' overflow-hidden h-9 w-[233px] max-w-[233px] whitespace-nowrap'
                  customUpload
                  mode='basic'
                  name='demo[]'
                  onUpload={onUpload}
                  auto
                  accept='.pdf'
                  uploadHandler={(e) => customBaseUploader(e, requisitesFileRef, props.setRequisitesFile)}
                  ref={requisitesFileRef}
                  chooseLabel={
                    props.requisitesFile?.name ?? props.selectedPark?.requisites?.split('/')?.pop() ?? 'Выбрать'
                  }
                />
              </div>
            </div>
          </div>
          <div className='flex flex-row gap-[33px]  '>
            <LoadPhoto
              isDisabledFile={props.isDisabledFile}
              setDisabledFile={(isDisabledFile) => props.setDisabledFile(isDisabledFile)}
              arrayFile={props.files}
              setUploadFile={props.setUploadFile}
              onClickDelete={props.onClickDeleteFile}
              title='Галерея Центра:'
              small
            />
          </div>
        </div>
        <div className='border-0 border-t-[1px] border-grey-secondary border-solid pt-4'>
          <UiButton
            onClick={props.editPark}
            className='hover:bg-green-[#16A34A] w-[100%] !font-black  justify-center max-h-[36px] max-w-[233px] uppercase !text-sm'
          >
            Сохранить
          </UiButton>
        </div>
      </div>
    </Layout>
  )
}
