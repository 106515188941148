import { ApiEmployeeTrackingReports, Role } from '@@types/apiLogicTypes'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { createFormData } from '@utils/createDataForm'
import times from '@utils/times'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { useContext } from 'react'
import { buttonsTemplate, datePicketBodyTemplate, employeeBodyTemplate } from '../shared/TableTemplates'

export const WorkTimeTrack = observer(() => {
  const logic = useLogic()
  const context = useContext(ToastContext)

  const {
    users: {
      employeeTrackingReports,
      employees,
      roles,
      deleteEmployeeTrackingReport,
      addEmptyTrackingReport: addEmptyReport,
      editEmployeeTrackingReport,
    },
    auth: { selectedPark },
  } = useStore()

  const addRow = () => {
    addEmptyReport(selectedPark!.id)
  }

  const deleteReport = async (e: ApiEmployeeTrackingReports, rowIndex?: number) => {
    if (!e.id) {
      deleteEmployeeTrackingReport(e.id, rowIndex)
      return
    }

    const { status, errors } = await logic.deleteEmployeeTrackingById(e.id)
    if (status) deleteEmployeeTrackingReport(e.id)
    else context?.toastShowHandler({ status: false, errors: errors })
  }

  const addReport = async (e: ApiEmployeeTrackingReports, rowIndex: number) => {
    if (!e.user_id || !e.date || !e.from || !e.to) {
      context?.toastShowHandler({ status: false, errors: 'Заполните все поля' })
      return
    }

    const formData = createFormData({
      park_id: selectedPark!.id,
      user_id: e.user_id,
      type: 1,
      date: e.date,
      from: e.from,
      to: e.to,
      format: 1,
    })
    const { status, errors } = await logic.createEmployeeTrackingById(formData, rowIndex)
    if (!status) context?.toastShowHandler({ status: false, errors: errors })
  }

  const editReport = async (id: number, query: string) => {
    const { status, errors } = await logic.editEmployeeTrackingById(id, query)
    if (!status) context?.toastShowHandler({ status: false, errors: errors })
  }

  return (
    <section className='bg-white h-full w-full px-4 py-5 flex flex-col gap-8 overflow-auto'>
      <header className='flex flex-row gap-6 items-center'>
        <UiText className='text-lg font-bold'>Учёт времени работы</UiText>
        <UiButton onClick={addRow}>
          <i className='pi pi-plus'></i>
        </UiButton>
      </header>

      <DataTable
        value={employeeTrackingReports}
        showGridlines
        size={'small'}
        filterLocale='ru'
        className={'bg-white text-base ui-paginator-table h-fit'}
        style={{ fontSize: '12px' }}
        removableSort
        emptyMessage='Данные не найдены'
        scrollable
        scrollHeight='flex'
        paginator
        rows={15}
        columnResizeMode='expand'
        sortField='date'
      >
        <Column
          filterField='created_at'
          header='Дата'
          dataType='date'
          body={(e, options) => datePicketBodyTemplate(e, editReport, editEmployeeTrackingReport, options.rowIndex)}
        />
        <Column
          field='time'
          header='Время'
          body={(e, options) => timePicker(e, editReport, editEmployeeTrackingReport, options.rowIndex)}
        />
        <Column
          field='employee'
          header='Сотрудник'
          body={(e: ApiEmployeeTrackingReports, options) =>
            employeeBodyTemplate(e, employees, editReport, editEmployeeTrackingReport, options.rowIndex)
          }
        />
        <Column field='children' header='Должность' body={(e) => roleBodyTemplate(e, roles)} />
        <Column
          field='delete'
          body={(e, options) =>
            buttonsTemplate(
              () => deleteReport(e, options.rowIndex),
              !e.id ? () => addReport(e, options.rowIndex) : undefined
            )
          }
        />
      </DataTable>
    </section>
  )
})

const roleBodyTemplate = (report: ApiEmployeeTrackingReports, roles: Role[]) => {
  return (
    <Dropdown
      value={report.user ? roles.find((e) => e.id === report.user!.roles[0].id) : undefined}
      options={roles}
      optionLabel='ru'
      panelClassName='worker-panel'
      color='green'
      placeholder='Выберите должность'
      className='worker h-[36px] w-full'
      disabled={true}
    />
  )
}

const timePicker = (
  report: ApiEmployeeTrackingReports,
  editCallback: (id: number, query: string) => Promise<void>,
  editLocalStateCallback: (report: ApiEmployeeTrackingReports, rowIndex?: number) => void,
  rowIndex: number
) => {
  const startTime = new Date(String(report.from)).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })

  const endTime = new Date(String(report.to)).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })

  const onChangeStartTimeHandler = async (e: DropdownChangeEvent) => {
    const from = new Date(report.date).toLocaleDateString('en-CA') + ' ' + e.value + ':00'

    if (!report.id) {
      editLocalStateCallback({ ...report, from: from }, rowIndex)
      return
    }

    const query = `from=${from}`
    await editCallback(report.id, query)
  }

  const onChangeEndTimeHandler = async (e: DropdownChangeEvent) => {
    const to = new Date(report.date).toLocaleDateString('en-CA') + ' ' + e.value + ':00'

    if (!report.id) {
      editLocalStateCallback({ ...report, to: to }, rowIndex)
      return
    }

    const query = `to=${to}`
    await editCallback(report.id, query)
  }

  return (
    <div className='flex gap-1 items-center'>
      <Dropdown
        value={startTime}
        className=' border-green worker text-red timeInput h-[36px]'
        panelClassName='worker-panel'
        color='green'
        options={times}
        onChange={onChangeStartTimeHandler}
      />
      <p className='m-0'>—</p>
      <Dropdown
        value={endTime}
        className={`border-green worker timeInput h-[36px]`}
        panelClassName='worker-panel'
        color='green'
        options={times.filter((e) => e > startTime)}
        onChange={onChangeEndTimeHandler}
      />
    </div>
  )
}
