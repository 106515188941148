import { ApiFormCreateHoliday } from '@@types/apiLogicTypes'
import { PartyContainer } from '@components/ui/PartyContainer/PartyContainer'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'

export const AdminAddParty = observer(() => {
  const title = 'Создание праздника '
  const logic = useLogic()
  const context = useContext(ToastContext)

  const {
    rooms: { rooms },
    auth: { selectedPark },
    services: { adminShows, entertaimentPrograms },
    product: { products },
  } = useStore()

  useEffect(() => {
    logic.loadAdminShowsServices()
  }, [])

  async function sendForm(form: ApiFormCreateHoliday) {
    if (selectedPark) {
      form.park_id = String(selectedPark.id)

      const ticketsForm: ApiFormCreateHoliday = {
        name: form.name ?? '',
        has_animator: form.has_animator,
        shows: form.shows,
      }
      const { status, errors } = await logic.createAdminHoliday(ticketsForm)

      if (status) {
        navigateTo(AppRoutesPaths.AdminPanelParty)
      } else {
        context?.toastShowHandler({ status, errors })
      }
    }
  }

  return (
    <>
      <PartyContainer
        shows={adminShows}
        entertaimentPrograms={entertaimentPrograms}
        products={products}
        callbackForm={sendForm}
        partyRooms={rooms ?? []}
        title={title}
        isAdmin={true}
      />
    </>
  )
})
