import { ApiCategoryType, ApiCreateCategoryResponse } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { TreeNodePrimereact, createNestedArray } from '@utils/createDataTreePrimereact'

import { action, observable } from 'mobx'

export class CategoryStore {
  @observable
  mainLoading = LoadState.Initial

  @observable
  loadingDatathree = LoadState.Initial

  @observable
  types: ApiCategoryType | null = null

  @observable
  categories: ApiCreateCategoryResponse[] | null = null

  @observable
  dataThree: TreeNodePrimereact[] | null = null

  @action
  handleCategoriesAndTypesChange = ({
    categories,
    types,
  }: {
    categories: ApiCreateCategoryResponse[] | null
    types: ApiCategoryType | null
  }) => {
    if (types) {
      const sortedCategoriest = categories?.sort((a, b) => a.type - b.type) ?? []
      this.dataThree = createNestedArray(types, sortedCategoriest)
    }
  }

  addNewCategory(response: ApiCreateCategoryResponse) {
    if (this.categories) {
      this.categories.push(response)
    } else {
      this.categories = [response]
    }

    this.handleCategoriesAndTypesChange({
      categories: this.categories,
      types: this.types,
    })
  }
  removeCategory(id: number) {
    if (this.categories) {
      const filteredCategories = this.categories.filter((item) => item.id !== id)
      this.categories = [...filteredCategories]
    }
  }

  updateCategory(response: ApiCreateCategoryResponse) {
    if (this.categories) {
      const newCategories = this.categories.filter((item) => item.id !== response.id)

      this.categories = [...newCategories, response]
    }

    this.handleCategoriesAndTypesChange({
      categories: this.categories,
      types: this.types,
    })
  }
}
