import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useStore } from '@hooks/storeHook'
import { CloseShift, OpenShift, Settlement, TerminalReport, XReport } from '@logic/kkmLogic'
import { formatFullName } from '@utils/textUtils'
import { FC, useContext } from 'react'

export const KKMPanel: FC = () => {
  const context = useContext(ToastContext)
  const {
    auth: { user },
  } = useStore()
  const operatorName = formatFullName(user!.name, user?.surname, user?.patronymic)

  const openHandler = async () => {
    await OpenShift(operatorName, context!)
  }

  const closeHandler = async () => {
    await CloseShift(operatorName, context!)
  }

  const xReportHandler = async () => {
    await XReport(context!)
  }

  const terminalReportHandler = async () => {
    await TerminalReport(context!)
  }

  const settlementHandler = async () => {
    await Settlement(context!)
  }

  return (
    <section className='bg-white p-4 w-full h-full flex flex-col gap-6'>
      <UiText className='text-lg font-bold'>Отчеты и смены</UiText>

      <section className='flex flex-col gap-2'>
        <UiText className='text-md'>Управление сменами</UiText>

        <div className='flex gap-4'>
          <UiButton className='w-[150px]' color='blue' onClick={openHandler}>
            Открыть смены
          </UiButton>
          <UiButton className='w-[150px]' color='red' onClick={closeHandler}>
            Закрыть смены (z-отчет)
          </UiButton>
        </div>
      </section>

      <section className='flex flex-col gap-2'>
        <UiText className='text-md'>Управление фискализатором</UiText>
        <UiButton className='w-[150px]' color='blue' onClick={xReportHandler}>
          Отчет без гашения (X-отчет)
        </UiButton>
      </section>

      <section className='flex flex-col gap-2'>
        <UiText className='text-md'>Управление экваерингом</UiText>

        <div className='flex gap-4'>
          <UiButton className='w-[150px]' color='blue' onClick={terminalReportHandler}>
            Отчет без гашения (по картам)
          </UiButton>
          <UiButton className='w-[150px]' color='red' onClick={settlementHandler}>
            сверка итогов (обнуление)
          </UiButton>
        </div>
      </section>
    </section>
  )
}
