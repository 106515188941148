export function formatPhoneNumber(phoneNumber: string) {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')

  const short = cleaned.match(/^(\d{10})$/)
  const long = cleaned.match(/^(\d{11})$/)
  if (!short && !long) {
    return phoneNumber
  }

  const formatted = short
    ? '+7(' +
      short[1].substring(0, 3) +
      ')' +
      short[1].substring(3, 6) +
      '-' +
      short[1].substring(6, 8) +
      '-' +
      short[1].substring(8, 10)
    : long
      ? '+7(' +
        long[1].substring(1, 4) +
        ')' +
        long[1].substring(4, 7) +
        '-' +
        long[1].substring(7, 9) +
        '-' +
        long[1].substring(9, 11)
      : phoneNumber
  return formatted
}

export const formatPhoneHandler = (input: string) => {
  if (input === '' || input === '+7(') {
    return ''
  }

  const cleaned = ('' + input).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{0,11})$/)

  if (!match) {
    return input
  }

  let formatted = '+7('

  if (cleaned.length <= 1) {
    return cleaned
  }

  formatted += cleaned.substring(1, 4)

  if (cleaned.length > 4) {
    formatted += ')' + cleaned.substring(4, 7)
  }

  if (cleaned.length > 7) {
    formatted += '-' + cleaned.substring(7, 9)
  }

  if (cleaned.length > 9) {
    formatted += '-' + cleaned.substring(9)
  }

  if (cleaned.length > 10) {
    return input
  }

  return formatted
}
