import { appColors } from '@config/theme'

export const DropDownArrow = ({ color = appColors.grey, selected }: { color?: string; selected?: boolean }) =>
  selected ? (
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36' fill='none'>
      <path
        d='M23.5833 21L17.5833 15L11.5833 21'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ) : (
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36' fill='none'>
      <path
        d='M11.5833 15L17.5833 21L23.5833 15'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
