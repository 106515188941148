import { ApiApplication, ApiPaymentOrder, ApiStories, ApiValidationResponse } from '@@types/apiLogicTypes'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { generatePath } from 'react-router-dom'
import { createFormData } from './createDataForm'

export interface ITableItems extends ApiStories {}

export const onDelete = async (
  item: ITableItems,
  deleteRequestCallback: (id: number) => Promise<ApiValidationResponse>,
  deleteItemCallback: (id: number) => void
) => {
  const { status } = await deleteRequestCallback(item.id)
  status && deleteItemCallback(item.id)
}

export const onAdd = (path: AppRoutesPaths) => {
  navigateTo(path)
}

export const onEdit = (path: AppRoutesPaths, item: ITableItems) => {
  const route = generatePath(path, {
    id: String(item.id),
  })
  navigateTo(route)
}

export const onToggleActive = async (
  item: ITableItems,
  editRequestCallback: (form: FormData, id: number) => Promise<ApiValidationResponse>
) => {
  const form = createFormData({
    active: String(Number(!Boolean(Number(item.active)))),
  })
  await editRequestCallback(form, item.id)
}

export const statusSwitcher = (e: ApiPaymentOrder | ApiApplication) => {
  switch (e.status) {
    case 1:
      return { color: 'bg-bg-bg', name: 'Новая' }
    case 2:
      return { color: 'bg-bg-blue', name: 'Перезвонить' }
    case 3:
      return { color: 'bg-bg-yellow', name: 'Ждем оплату' }
    case 4:
      return { color: 'bg-bg-green', name: 'Оплачена' }
    case 5:
      return { color: 'bg-bg-violet', name: 'Предоплачена' }
    case 0:
      return { color: 'bg-bg-bg', name: 'Не реализована' }
    case 100:
      return { color: 'bg-bg-green', name: 'Завершена' }
    case 1000:
      return { color: 'bg-bg-red', name: 'Удалена' }
    default:
      return { color: 'bg-bg-bg', name: '-' }
  }
}
