import { ApiProduct } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'

import { observable } from 'mobx'

export class ProductStore {
  @observable
  mainLoading = LoadState.Initial

  @observable
  products: ApiProduct[] | [] = []

  @observable
  addProducts = (product: ApiProduct) => {
    this.products = [...this.products, product]
  }
}
