import { ApiCreateApplicationForm } from '@@types/apiLogicTypes'
import FormDropdown from '@components/ui/Form/FormDropdown'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { ToastContext } from '@context/toastContext'
import { useStore } from '@hooks/storeHook'
import { IDisabled } from '@modules/TicketSale/Form/Form'
import times from '@utils/times'
import classNames from 'classnames'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { FC, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface IAnimatorSelector {
  index: number
  removeHandler: (index: number) => void
}

const AnimatorSelector: FC<IAnimatorSelector & IDisabled> = ({ index, removeHandler, disable }) => {
  const {
    users: { animators },
  } = useStore()
  const methods = useFormContext<ApiCreateApplicationForm>()
  const context = useContext(ToastContext)

  const onChange = (e: DropdownChangeEvent, index: number) => {
    if (index === 0) {
      e.value.id !== methods.getValues('animators.1.entity.id')
        ? methods.setValue(`animators.0.entity`, e.value, { shouldDirty: true })
        : context?.toastShowHandler({ status: false, errors: 'Этот аниматор уже выбран' })
    } else {
      e.value.id !== methods.getValues('animators.0.entity.id')
        ? methods.setValue(`animators.1.entity`, e.value, { shouldDirty: true })
        : context?.toastShowHandler({ status: false, errors: 'Этот аниматор уже выбран' })
    }
  }

  return (
    <div className='flex gap-4'>
      <div className='w-[180px]'>
        <FormDropdown
          id={String(index)}
          name={`animators.${index}.entity`}
          placeholder='Выберите аниматора'
          options={animators.map((e) => ({ ...e, pivot: undefined })) ?? []}
          className='h-[38px] items-center'
          size='full'
          onChange={(e) => onChange(e, index)}
          required={true}
          disabled={disable}
        />
      </div>
      <div className='flex gap-1 items-center h-[36px]'>
        <Controller
          control={methods.control}
          name={`animators.${index}.starts_at`}
          rules={{ required: 'Date is required.' }}
          render={({ field, fieldState }) => (
            <Dropdown
              value={field.value}
              className={classNames(
                `!w-[57px] max-w-[120px] worker shadow-none timeInput`,
                fieldState.error && '!border-red rounded-sm border-solid border'
              )}
              panelClassName='worker-panel'
              color='green'
              options={times}
              onChange={field.onChange}
              disabled={disable}
            />
          )}
        />
        <p>—</p>
        <Controller
          control={methods.control}
          name={`animators.${index}.ends_at`}
          rules={{ required: 'Date is required.' }}
          render={({ field, fieldState }) => (
            <Dropdown
              value={field.value}
              className={classNames(
                `!w-[57px] max-w-[120px] worker shadow-none timeInput`,
                fieldState.error && '!border-red rounded-sm border-solid border'
              )}
              panelClassName='worker-panel'
              color='green'
              options={times.filter((e) => e > methods.watch(`animators.${index}.starts_at`))}
              onChange={field.onChange}
              disabled={disable}
            />
          )}
        />
      </div>
      <UiButton onClick={() => removeHandler(index)} type='button' color='red' disabled={disable}>
        <i className='pi pi-times text-sm'></i>
      </UiButton>
    </div>
  )
}

export default AnimatorSelector
