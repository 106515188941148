import { ApiLog, ApiNote } from '@@types/apiLogicTypes'
import noteLogo from '@assets/images/note.svg'
import { UiText } from '@components/ui/UiText/UiText'
import moment from 'moment'
import { FC, useEffect, useRef } from 'react'
import { INotesForm } from './NotesForm'
import './styles.css'

interface IMonthBlock extends Pick<INotesForm, 'logOnClick'> {
  month: string
  groupedLogs: {
    [month: string]: (ApiLog | ApiNote)[]
  }
}

const MonthBlock: FC<IMonthBlock> = ({ month, groupedLogs, logOnClick }) => {
  return (
    <div key={month} className='w-full flex flex-col'>
      <div className='flex items-center'>
        <div className='border-grey border-solid w-full border-[0.5px]' />
        <UiText className='flex self-center border border-grey border-solid py-2 px-8 rounded-[50px] min-w-fit'>
          {month}
        </UiText>
        <div className='border-grey border-solid w-full border-[0.5px]' />
      </div>
      <div className='flex flex-col gap-2 my-4'>
        {groupedLogs[month].map((note) => (
          <div key={note.id} className='flex gap-2'>
            {'message' in note && <Log log={note} onClick={logOnClick} />}
            {'text' in note && <Note {...note} />}
          </div>
        ))}
      </div>
    </div>
  )
}

interface ILog {
  log: ApiLog
  onClick?: (fiskal: number) => void
}

const Log: FC<ILog> = ({ log, onClick }) => {
  const logRef = useRef(null)

  useEffect(() => {
    if (!onClick) return
    if (logRef.current) {
      //@ts-ignore
      const fiskalElement = logRef.current.querySelector('[data-fiskal]')
      if (fiskalElement) {
        fiskalElement.addEventListener('click', (e: any) => onClick(e.target.dataset.fiskal))

        return () => {
          fiskalElement.removeEventListener('click', onClick)
        }
      }
    }
  }, [log.message])

  return (
    <UiText className='li leading-4'>
      <span className='font-bold'>{moment(log.created_at).format('DD.MM.YY HH:mm')}</span>{' '}
      <span ref={logRef} dangerouslySetInnerHTML={{ __html: log.message }} />
    </UiText>
  )
}

const Note: FC<ApiNote> = (note) => {
  return (
    <div className='bg-grey-hover p-2 w-full'>
      <div className='flex gap-2 items-center'>
        <img src={noteLogo} alt='' />
        <div className=' flex flex-col gap-[3px]'>
          <UiText className='text-grey' size='sm'>
            {moment(note.created_at).format('DD.MM.YY HH:mm')} {note.user.name}
          </UiText>
          <UiText>{note.text}</UiText>
        </div>
      </div>
    </div>
  )
}

export default MonthBlock
