import { ApiDiscount } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { buttonBodyTemplate, nameBodyTemplateWithIcon } from '@components/ui/ColumnContents/ColumnContents'
import { SettingsHeaderBtn } from '@components/ui/SettingsHeaderBtn/SettingsHeaderBtn'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ArchiveContext } from '@context/archiveContext'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { onAdd } from '@utils/tableUtils'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useContext, useEffect } from 'react'
import { typeBodyTemplate } from '../Loyalty/LoyaltyPage'

interface IDiscountPage {
  isAdmin?: boolean
  inArchive?: boolean
}

export const DiscountPage = observer(({ inArchive = false, isAdmin }: IDiscountPage) => {
  const logic = useLogic()
  const context = useContext(ToastContext)

  const {
    advertising: { loading, discounts },
    auth: { selectedPark },
  } = useStore()

  useEffect(() => {
    if (!selectedPark) return
    isAdmin ? logic.loadAdminDiscounts() : logic.loadDiscounts(selectedPark.id)
  }, [selectedPark])

  const onDelete = async (id: number) => {
    const { status, errors } = await logic.deleteDiscount(id)
    if (!status) context?.toastShowHandler({ status: false, errors: errors })
  }

  const onArchive = async (item: ApiDiscount) => {
    const { status, errors } = await logic.updateDiscount(`?deleted=${Number(!Boolean(item.deleted))}`, item.id)
    if (!status) context?.toastShowHandler({ status: false, errors: errors })
  }

  const toArchive = () => {
    navigateTo(AppRoutesPaths.AdvertisingDiscountArchive)
  }

  return (
    <Layout title='Скидки'>
      <ArchiveContext.Provider value={{ inArchive, onArchive: toArchive }}>
        <SettingsHeaderBtn
          textAdd={'Скидку'}
          onClickAdd={() =>
            onAdd(isAdmin ? AppRoutesPaths.AdminPanelAddDiscount : AppRoutesPaths.AdvertisingAddDiscount)
          }
          textArchive={'Рассылки'}
          isAdmin={isAdmin}
        />
        <DataTable
          value={isAdmin ? discounts : discounts.filter((e) => e.deleted === (inArchive ? 1 : 0))}
          showGridlines
          className='bg-white text-base'
          style={{ fontSize: '12px' }}
          removableSort
          sortOrder={1}
          emptyMessage='Нет данных'
          loading={loading === LoadState.Loading}
        >
          <Column
            field='name'
            header='Название'
            sortable
            body={(item) =>
              nameBodyTemplateWithIcon(
                item,
                isAdmin ? AppRoutesPaths.AdminPanelEditDiscount : AppRoutesPaths.AdvertisingEditDiscount
              )
            }
          />
          <Column field='type' header='Тип' sortable body={(e: ApiDiscount) => typeBodyTemplate(e.type)} />
          <Column field='value' header='Размер' sortable />
          <Column
            field=''
            header=''
            body={(item) =>
              buttonBodyTemplate({
                item: item,
                path: isAdmin ? AppRoutesPaths.AdminPanelEditDiscount : AppRoutesPaths.AdvertisingEditDiscount,
                onArchive: !isAdmin ? () => onArchive(item) : undefined,
                onDelete: !isAdmin ? () => onDelete(item.id) : undefined,
              })
            }
          />
        </DataTable>
      </ArchiveContext.Provider>
    </Layout>
  )
})
