import { ApiTicket } from '@@types/apiLogicTypes'
import logo from '@assets/images/skala.png'
import { TicketTableItem } from '@components/ui/ContainerTable/TicketTableItem'
import { TablePageContainer } from '@components/ui/TablePageContainer/TablePageContainer'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ArchiveContext } from '@context/archiveContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { tableNameTicket } from '@utils/variableApp'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useContext, useEffect } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'

import {
  checkBoxBodyTemplate,
  measureBodyTemplate,
  priceBodyTemplate,
} from '@components/ui/ColumnContents/ColumnContents'
import { TableButtons } from '@components/ui/ContainerTable/TableButtons'
import { ToastContext } from '@context/toastContext'

export const TicketScreen = observer(() => {
  const logic = useLogic()
  const {
    ticket: { tickets },
    services: { units },
    auth: { selectedPark },
  } = useStore()

  const location = useLocation()
  const inArchive = location && location.pathname === AppRoutesPaths.SettingTicketArchive
  const context = useContext(ToastContext)
  const navigate = useNavigate()

  const onAdd = () => {
    navigateTo(AppRoutesPaths.SettingTicketAdd)
  }

  const onArchive = () => {
    navigateTo(AppRoutesPaths.SettingTicketArchive)
  }

  useEffect(() => {
    if (inArchive) logic.loadTicket(true)
    else logic.loadTicket()
  }, [location, selectedPark])

  function onClickEdit(ticket: ApiTicket) {
    let path: string = ''
    path = generatePath(AppRoutesPaths.SettingTicketUpdate, {
      id: String(ticket.id),
    })

    navigate(path)
  }

  async function onChangeActive(ticket: { active: number | boolean; [key: string]: any }) {
    await logic.updateActiveTicket({
      ...ticket,
      active: Number(!Number(ticket.active)),
    })
  }

  const nameBodyTemplateWithIcon = (rowData: ApiTicket) => {
    return (
      <div className='flex justify-between items-center'>
        <UiText onClick={() => onClickEdit(rowData)}>{rowData.name}</UiText>
        {rowData?.group_id && <img src={logo} alt='Создано администратором' className='w-3 h-3 ml-2' />}
      </div>
    )
  }

  async function onDelete(ticket: ApiTicket) {
    if (ticket.deleted === 0) {
      const { status, errors } = await logic.deleteTicket(ticket.id)
      if (!status) {
        context?.toastShowHandler({ status, errors })
      } else logic.loadTicket()
    } else {
      const { status, errors } = await logic.updateTicket({
        id: ticket.id,
        deleted: 0,
      })

      if (status) {
        logic.loadTicket(true)
      } else {
        context?.toastShowHandler({ status, errors })
      }
    }
  }

  const buttonBodyTemplate = (item: ApiTicket) => {
    return (
      <TableButtons onClickEdit={!inArchive ? () => onClickEdit(item) : undefined} onClickBox={() => onDelete(item)} />
    )
  }

  return (
    <ArchiveContext.Provider value={{ inArchive, onArchive }}>
      <TablePageContainer
        tableProps={{
          arrayHeadName: tableNameTicket,
          children: <TableList items={tickets ?? []} />,
          isDeletePhoto: true,
        }}
        mainTitle='Билеты'
        textAdd={'Билет'}
        onClickAdd={onAdd}
        textArchive={'Билеты'}
      >
        <DataTable
          value={tickets ?? []}
          showGridlines
          className={classnames('bg-white text-base')}
          style={{ fontSize: '12px' }}
          removableSort
          sortField='name'
          sortOrder={1}
          emptyMessage='Нет данных'
          // filters={filters}
        >
          <Column
            field='name'
            header='Название'
            sortable
            body={nameBodyTemplateWithIcon}
            // filter
            // filterPlaceholder='Поиск по названию'
            // showAddButton={false}
          ></Column>
          <Column field='category.name' header='Категория' sortable></Column>
          <Column
            field='weekday_price'
            header='Цена по будням'
            sortable
            body={(item: ApiTicket) => priceBodyTemplate(item.weekday_price)}
          ></Column>
          <Column
            field='weekend_price'
            header='Цена в выходные'
            sortable
            body={(item: ApiTicket) => priceBodyTemplate(item.weekend_price)}
          ></Column>
          <Column
            field='unit_id'
            header='Измерение'
            body={(rowData: ApiTicket) => measureBodyTemplate(rowData, units ?? null)}
          ></Column>
          <Column field='sort' header='Сортировка' sortable></Column>
          {!inArchive && (
            <Column field='active' header='Сайт' body={(item) => checkBoxBodyTemplate(item, onChangeActive)}></Column>
          )}
          <Column field='' header='' body={buttonBodyTemplate}></Column>
        </DataTable>
      </TablePageContainer>
    </ArchiveContext.Provider>
  )
})

export const TableList = (props: { items: ApiTicket[] }) =>
  props.items.map((item) => <TicketTableItem key={item.id} ticket={item} />)
