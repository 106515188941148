import moment from 'moment'
import 'moment/locale/ru'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
moment.locale('ru')
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)

reportWebVitals()
