import { ApiServicesTypes, ApiTicket } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { computed, observable } from 'mobx'

export class TicketStore {
  @observable
  mainLoading = LoadState.Initial

  @observable
  types: ApiServicesTypes[] | null = null

  @observable
  loadById = LoadState.Initial

  @observable
  selectedUpdateTicket: ApiTicket | null = null

  @observable
  tickets: ApiTicket[] = []

  @observable
  ticketsInOrder: ApiTicket[] = []

  @computed
  get activeTickets() {
    return this.tickets?.filter((e) => e.active === 1)
  }

  @observable
  adminTickets: ApiTicket[] = []

  resetUpdateTicket = () => {
    this.selectedUpdateTicket = null
  }

  addTicket = (ticket: ApiTicket) => {
    if (this.tickets) {
      this.tickets = [...this.tickets, ticket]
    } else {
      this.tickets = [ticket]
    }
  }

  updateTicket(response: ApiTicket) {
    this.updateTickets(response, this.tickets)
  }

  updateAdminTicket(response: ApiTicket) {
    this.updateTickets(response, this.adminTickets)
  }

  updateTickets(response: ApiTicket, store: ApiTicket[] | null) {
    if (store) {
      const index = store.findIndex((item) => item.id !== response.id)
      if (index >= 0) {
        store = [...store.map((item) => (item.id === response.id ? response : item))]
      }
    } else {
      store = [response]
    }
  }

  updateActiveTickets(response: ApiTicket) {
    if (this.tickets) {
      const index = this.tickets.findIndex((item) => item.id === response.id)
      if (index !== -1) {
        this.tickets = [...this.tickets.map((item) => (item.id === response.id ? response : item))]
      }
    } else {
      this.tickets = [response]
    }
  }
}
