import { IconElement } from '../IconElement/IconElement'
import './styles.css'
export const SearchField = ({
  placeholder = 'Поиск',
  classNameContainer = '',
}: {
  placeholder?: string
  classNameContainer?: string
}) => {
  return (
    <div
      className={`flex flex-row items-center py-[6px] px-[8px] h-[36px] w-full gap-[8px] bg-bg rounded-[3px] ${classNameContainer}`}
    >
      <IconElement icon={'/icons/SearchIcon.svg'} />
      <input className='search-field bg-bg text-base text-black' type='text' placeholder={placeholder}></input>
    </div>
  )
}
