import { FC, ReactNode } from 'react'

interface IUiText extends React.HTMLAttributes<HTMLSpanElement> {
  children: ReactNode
  isShowTooltip?: boolean
  size?: 'xs' | 'sm' | 'lg' | 'md' | '2lg' | 'xl' | 'base'
  weight?: 'bold' | 'semibold'
}

export const UiText: FC<IUiText> = ({ children, isShowTooltip, size = 'base', weight, ...props }) => {
  return (
    <span
      {...props}
      className={`text-black text-${size} ${weight ? `font-${weight}` : ''} ${props.className}`}
      title={isShowTooltip ? String(children) : undefined}
    >
      {children}
    </span>
  )
}
