import { ApiBirthdayGame } from '@@types/apiLogicTypes'
import FormInput from '@components/ui/Form/FormInput'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { GameTimes } from '@modules/Settings/Games/Form/GameZone/GameZone'
import { createFormData } from '@utils/createDataForm'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { Dropdown } from 'primereact/dropdown'
import { FC, useContext } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { THoliday } from '../types'

interface IForm {
  id: string
  removeFormHandler: () => void
  initialValue?: ApiBirthdayGame
}

export const Form: FC<IForm> = observer(({ id, removeFormHandler, initialValue }) => {
  const {
    game: { gameZones, games, removeHolidayFromState },
  } = useStore()

  const methods = useForm<THoliday>({
    defaultValues: {
      name: initialValue?.name ?? '',
      game: initialValue ? games.find((game) => game.id === initialValue.game_id) : null,
      gamezone: initialValue ? gameZones.find((gameZone) => gameZone.id === initialValue.zone_id) : null,
      time: initialValue ? GameTimes.find((time) => time.duration === +initialValue.duration) : null,
      puzzle1: { file: null, path: initialValue?.images[0].path ?? '' },
      puzzle2: { file: null, path: initialValue?.images[1].path ?? '' },
      puzzle3: { file: null, path: initialValue?.images[2].path ?? '' },
      puzzle4: { file: null, path: initialValue?.images[3].path ?? '' },
    },
  })

  const logic = useLogic()
  const context = useContext(ToastContext)

  const onSumbit = async (data: THoliday) => {
    if (!initialValue) {
      if (!data.puzzle1.file || !data.puzzle2.file || !data.puzzle3.file || !data.puzzle4.file) {
        context?.toastShowHandler({ status: false, errors: 'Пожалуйста, загрузите 4 фотографии' })
        return
      }

      const formData = createFormData({
        name: data.name,
        zone_id: data.gamezone?.id,
        game_id: data.game?.id,
        duration: data.time?.duration,
      })

      formData.append('images[0][image]', data.puzzle1.file)
      formData.append('images[0][type]', 'puzzle1')

      formData.append('images[1][image]', data.puzzle2.file)
      formData.append('images[1][type]', 'puzzle1_res')

      formData.append('images[2][image]', data.puzzle3.file)
      formData.append('images[2][type]', 'puzzle2')

      formData.append('images[3][image]', data.puzzle4.file)
      formData.append('images[3][type]', 'puzzle2_res')

      const { status } = await logic.createIntegrationBirthday(formData)
      if (!status) context?.toastShowHandler({ status: false })
    } else {
      const formData = new FormData()

      methods.getFieldState('name').isDirty && formData.append('name', data.name)
      methods.getFieldState('game').isDirty && formData.append('game_id', String(data.game?.id))
      methods.getFieldState('gamezone').isDirty && formData.append('zone_id', String(data.gamezone?.id))
      methods.getFieldState('time').isDirty && formData.append('duration', String(data.time?.duration))
      if (methods.getFieldState('puzzle1').isDirty && data.puzzle1.file) {
        formData.append('images[0][image]', data.puzzle1.file)
        formData.append('images[0][type]', 'puzzle1')
      }
      if (methods.getFieldState('puzzle2').isDirty && data.puzzle2.file) {
        formData.append('images[1][image]', data.puzzle2.file)
        formData.append('images[1][type]', 'puzzle1_res')
      }
      if (methods.getFieldState('puzzle3').isDirty && data.puzzle3.file) {
        formData.append('images[2][image]', data.puzzle3.file)
        formData.append('images[2][type]', 'puzzle2')
      }
      if (methods.getFieldState('puzzle4').isDirty && data.puzzle4.file) {
        formData.append('images[3][image]', data.puzzle4.file)
        formData.append('images[3][type]', 'puzzle2_res')
      }

      const { status, errors } = await logic.editIntegrationBirthday(initialValue!.id, formData)
      if (status) context?.toastShowHandler({ status: true })
      else context?.toastShowHandler({ status: false, errors: errors })
    }
  }

  const startQuestHandler = async () => {
    const { status, message } = await logic.startIntegrationBirthday(initialValue!.id)
    if (status) context?.toastShowHandler({ status: true, summary: message })
    else context?.toastShowHandler({ status: false, errors: message })
  }

  const stopQuestHandler = async () => {
    const { status, message } = await logic.stopIntegrationBirthday(initialValue!.id)
    if (status) context?.toastShowHandler({ status: true, summary: message })
    else context?.toastShowHandler({ status: false, errors: message })
  }

  const deleteBirthdayHandler = async () => {
    const { status, errors } = await logic.deleteIntegrationBirthday(initialValue!.id)
    if (status) {
      context?.toastShowHandler({ status: true, summary: 'Удалено' })
      removeHolidayFromState(initialValue!.id)
      removeFormHandler()
    } else context?.toastShowHandler({ status: false, errors: errors })
  }

  const handleImageChange = (file: File | null, puzzleIndex: 1 | 2 | 3 | 4) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        methods.setValue(`puzzle${puzzleIndex}.path`, reader.result as string)
      }
      reader.readAsDataURL(file)
      methods.setValue(`puzzle${puzzleIndex}.file`, file)
    } else {
      methods.setValue(`puzzle${puzzleIndex}.path`, '')
      methods.setValue(`puzzle${puzzleIndex}.file`, null)
    }
  }

  return (
    <FormProvider {...methods}>
      <form className='flex flex-col h-full not-last-form' onSubmit={methods.handleSubmit(onSumbit)}>
        <div className='py-5 w-fit grid grid-rows-3 grid-cols-[182px_82px_82px_82px_82px_151px_36px_36px] gap-x-4 gap-y-4  border-0 border-b-2 border-grey-200 border-solid'>
          <FormInput className='w-full' name={`name`} placeholder='Имя именника' required />
          <Controller
            control={methods.control}
            name={`game`}
            rules={{ required: 'Game is required.' }}
            render={({ field, fieldState }) => (
              <Dropdown
                value={field.value}
                optionLabel='name'
                className={`w-full worker shadow-none h-[36px] row-start-2 ${
                  fieldState.error ? '!border-red rounded-sm border-solid border' : ''
                }`}
                panelClassName='worker-panel'
                placeholder='Выберите квест'
                color='green'
                options={games.filter((e) => e.type_id === 3)}
                onChange={(e) => field.onChange(e.value)}
              />
            )}
          />
          <Controller
            control={methods.control}
            name={`gamezone`}
            rules={{ required: 'Gamezome is required.' }}
            render={({ field, fieldState }) => (
              <Dropdown
                value={field.value}
                optionLabel='name'
                className={`w-full worker shadow-none h-[36px] row-start-3  ${
                  fieldState.error ? '!border-red rounded-sm border-solid border' : ''
                }`}
                panelClassName='worker-panel'
                placeholder='Выберите зону'
                color='green'
                options={gameZones}
                onChange={(e) => field.onChange(e.value)}
              />
            )}
          />
          {
            new Array(
              [1, 2, 3, 4].map((index) => {
                const puzzleIndex: 1 | 2 | 3 | 4 = index as 1 | 2 | 3 | 4

                return (
                  <Controller
                    key={puzzleIndex}
                    name={`puzzle${puzzleIndex}`}
                    control={methods.control}
                    render={({ field }) => (
                      <div className='row-start-1 row-end-3 flex flex-col gap-2 items-center'>
                        <UiText className='text-sm font-bold block'>{puzzleNameSwitcher(puzzleIndex)}</UiText>
                        {methods.watch(`puzzle${puzzleIndex}.path`) && (
                          <img src={field.value.path} alt='Preview' className='w-[50px] h-[50px]' />
                        )}
                        <label htmlFor={`fileInput${id + puzzleIndex}`}>
                          <UiText className='text-blue text-sm font-bold block cursor-pointer'>
                            {methods.watch(`puzzle${puzzleIndex}.path`) ? 'Заменить' : 'Добавить'}
                          </UiText>
                        </label>

                        <input
                          id={`fileInput${id + puzzleIndex}`}
                          type='file'
                          accept='image/*'
                          style={{ display: 'none' }} // Скрыть input
                          onChange={(e) => {
                            const file = e.target.files ? e.target.files[0] : null
                            field.onChange(file)
                            //@ts-ignore
                            handleImageChange(file, puzzleIndex)
                          }}
                        />
                      </div>
                    )}
                  />
                )
              })
            )
          }
          <Controller
            control={methods.control}
            name={`time`}
            rules={{ required: 'Time is required.' }}
            render={({ field, fieldState }) => (
              <Dropdown
                value={field.value}
                optionLabel='name'
                className={`w-full worker shadow-none h-[36px]  ${
                  fieldState.error ? '!border-red rounded-sm border-solid border' : ''
                }`}
                panelClassName='worker-panel'
                placeholder='Длительность'
                color='green'
                options={GameTimes}
                onChange={(e) => field.onChange(e.value)}
              />
            )}
          />
          <UiButton className='row-start-2'>{initialValue ? 'Изменить' : 'Сохранить'}</UiButton>
          {initialValue && !(!!initialValue.active && !isQuestPast(initialValue)) && (
            <UiButton className='!bg-blue' type='button' onClick={startQuestHandler}>
              <i className={!isQuestPast(initialValue) ? 'pi pi-play' : 'pi pi-refresh'}></i>
            </UiButton>
          )}
          {initialValue && !!initialValue.active && !isQuestPast(initialValue) && (
            <UiButton className='!bg-blue' type='button' onClick={stopQuestHandler}>
              <i className='pi pi-pause'></i>
            </UiButton>
          )}
          <UiButton color='red' type='button' onClick={initialValue ? deleteBirthdayHandler : removeFormHandler}>
            <i className='pi pi-trash'></i>
          </UiButton>
        </div>
      </form>
    </FormProvider>
  )
})

const isQuestPast = (quest: ApiBirthdayGame) => {
  const serverDateStr = quest.ends_at
  const serverTimezone = 'Europe/Moscow'
  const serverMoment = moment.tz(serverDateStr, serverTimezone)

  const currentMoment = moment()
  const diffInSeconds = serverMoment.diff(currentMoment, 'seconds')

  return Boolean(diffInSeconds < 0)
}

const puzzleNameSwitcher = (index: number) => {
  switch (index) {
    case 1:
      return 'ПАЗЛ 1'
    case 2:
      return 'ПАЗЛ 1 ИТОГ'
    case 3:
      return 'ПАЗЛ 2'
    case 4:
      return 'ПАЗЛ 2 ИТОГ'
  }
}
