import { ToastContextType } from '@context/toastContext'
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload'
import { RefObject } from 'react'

export function onUpload(context: ToastContextType) {
  context?.toastShowHandler({ status: true })
}

function onFailedUpload(context: ToastContextType) {
  context?.toastShowHandler({ status: false, errors: 'Не удалось загрузить файл' })
}

export const customBaseUploader = async (
  event: FileUploadHandlerEvent,
  ref: RefObject<FileUpload>,
  dispatch: (file: File | null) => void,
  context: ToastContextType
) => {
  const file = event.files[0]

  const isSuccess = true

  const reader = new FileReader()
  const nameFile = file.name.replace(/ /g, '')

  reader.readAsDataURL(file)
  reader.onloadend = function () {
    const base64String = reader?.result?.toString().replace('data:', '').replace(/^.+,/, '').replace(/ /g, '') // Убираем пробелы из base64 строки

    if (base64String && nameFile) {
      dispatch(file)
    }
  }

  if (isSuccess) {
    onUpload(context)
  } else {
    onFailedUpload(context)
  }

  if (ref.current) {
    const fileUpload = ref.current as FileUpload
    fileUpload.clear()
  }
}
