import {
  ApiServices,
  ApiServicesMeasures,
  ApiServicesTypes,
  ApiServicesUnits,
  ApiShowsServices,
} from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'

import { computed, observable } from 'mobx'

export class ServicesStore {
  @observable
  mainLoading = LoadState.Initial

  @observable
  loadServices = LoadState.Initial

  @observable
  loadAdminServices = LoadState.Initial

  @observable
  loadById = LoadState.Initial

  @observable
  showsArray: ApiShowsServices[] | [] = []

  @observable
  adminShows: ApiShowsServices[] | [] = []

  @observable
  entertaimentPrograms: ApiShowsServices[] | [] = []

  @observable
  services: ApiServices[] | [] = []

  @observable
  adminServices: ApiServices[] | [] = []

  @observable
  selectUpdateServices: ApiServices | null = null

  @observable
  units: ApiServicesUnits[] | null = null

  @observable
  measures: ApiServicesMeasures[] | null = null

  @observable
  types: ApiServicesTypes[] | null = null

  @computed
  get entertainments() {
    return this.services.filter((e) => e.category.name === 'Развлечения' && e.active)
  }

  @computed
  get animations() {
    return this.services.filter((e) => e.category.name === 'Анимационные программы' && e.active)
  }

  @computed
  get shows() {
    return this.services.filter((e) => e.category.name === 'Шоу' && e.active)
  }

  @computed
  get crmServices() {
    return this.services.filter((e) => e.show_in_crm === 1)
  }

  @observable
  addService = (services: ApiServices) => {
    this.services = [...this.services, services]
  }

  @observable
  addAdminService = (services: ApiServices) => {
    this.adminServices = [...this.adminServices, services]
  }

  resetUpdateServices = () => {
    this.selectUpdateServices = null
  }

  updateService(response: ApiServices) {
    if (this.services) {
      const index = this.services.findIndex((item) => item.id === response.id)
      if (index !== -1) {
        this.services = [...this.services.map((item) => (item.id === response.id ? response : item))]
      }
    } else {
      this.services = [response]
    }
  }
}
