import Layout from '@layouts/NonScrollableLayout'
import FilterPanel from './FilterPanel/FilterPanel'
import Scheduler from './Scheduler/Scheduler'

import { useLogic, useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { ApiApplication } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { getNearestMondayAndSunday } from '@utils/formatDates'
import { Event } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './styles.css'

export interface IEvent extends ApiApplication, Event {}
export interface IDate {
  date: Date
  start: Date
  end: Date
}

const SchedulePage = observer(() => {
  const [events, setEvents] = useState<IEvent[] | []>([])
  const [filteredEvents, setFilteredEvents] = useState<IEvent[] | []>([])
  const logic = useLogic()
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null)
  const [date, setSelectedDate] = useState<IDate | null>(null)

  const {
    auth: { selectedPark },
    applications: { scheduleApplications, loading },
  } = useStore()

  useEffect(() => {
    const init = async () => {
      if (selectedPark) {
        await Promise.all([logic.loadHolidays(), logic.loadServices(), logic.loadAnimators()])
      }
      const { monday, sunday } = getNearestMondayAndSunday(new Date())
      await logic.loadScheduleApplications(monday.toISOString().split('T')[0], sunday.toISOString().split('T')[0])
      await logic.loadRooms()
    }
    init()
  }, [selectedPark])

  useEffect(() => {
    const mappedApplications = scheduleApplications
      .map(({ order, ...rest }) => ({
        ...order,
        title: order.holiday?.name ?? 'Услуги',
        start: new Date(rest.starts_at),
        end: new Date(rest.ends_at),
        order_id: rest.order_id,
        id: rest.id,
      }))
      .filter(({ status }) => status > 0 && status < 6)
    setEvents(mappedApplications)
    setFilteredEvents(mappedApplications)
  }, [scheduleApplications])

  return (
    <Layout title={'Расписание'}>
      <FilterPanel events={events} setEvents={setFilteredEvents} />

      <Scheduler
        events={filteredEvents}
        setSelectedEvent={setSelectedEvent}
        setSelectedDate={setSelectedDate}
        isLoading={loading === LoadState.Loading}
        defaultDate={selectedEvent?.start ?? date?.start ?? undefined}
      />
    </Layout>
  )
})

export default SchedulePage
