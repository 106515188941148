import { ToastContext } from '@context/toastContext'
import { useLogic } from '@hooks/storeHook'
import { createFormData } from '@utils/createDataForm'
import { Dialog } from 'primereact/dialog'
import { FC, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FormOtp } from '../Form/FormOtp/FormOtp'
import { UiButton } from '../UiButton/UiButton'
import { UiText } from '../UiText/UiText'

interface IPinModal {
  orderId: number
  isVisible: boolean
  onHide: () => void
  onSuccess: () => void
}

type FormType = {
  pin: string
}

export const PinModal: FC<IPinModal> = ({ orderId, isVisible, onHide, onSuccess }) => {
  const methods = useForm<FormType>({
    defaultValues: {
      pin: '',
    },
  })
  const [pinFromServer, setPinFromServer] = useState<string | undefined>('')
  const context = useContext(ToastContext)
  const logic = useLogic()

  const onSubmit = async (data: FormType) => {
    const formData = createFormData({ pin: data.pin })
    const { status, errors } = await logic.checkPin(orderId, formData)
    if (!status) {
      context?.toastShowHandler({ status: false, errors: errors })
    } else {
      methods.reset()
      setPinFromServer(undefined)
      onSuccess()
      onHide()
    }
  }

  useEffect(() => {
    const getPin = async () => {
      const { pin, errors } = await logic.sendPin(orderId)
      if (errors) context?.toastShowHandler({ status: false, errors: errors })
      if (pin) {
        context?.toastShowHandler({ status: true, summary: 'PIN отправлен пользователю' })
        setPinFromServer(pin)
      }
    }

    if (isVisible) {
      getPin()
    }
  }, [isVisible])

  return (
    <Dialog
      onHide={onHide}
      visible={isVisible}
      header={<UiText>{`Подтверждение PIN ${pinFromServer ? `(${pinFromServer})` : ''}`}</UiText>}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className='flex flex-col gap-4 items-center'>
          <FormOtp name='pin' integerOnly required />
          <UiButton className='w-full'>Подтвердить</UiButton>
        </form>
      </FormProvider>
    </Dialog>
  )
}
