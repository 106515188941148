import { ApiAttraction } from '@@types/apiLogicTypes'
import { imgBodyTemplate } from '@components/ui/ColumnContents/ColumnContents'
import { AttractionTableItem } from '@components/ui/ContainerTable/AttractionTableItem'
import { TableButtons } from '@components/ui/ContainerTable/TableButtons'
import { TablePageContainer } from '@components/ui/TablePageContainer/TablePageContainer'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { useLogic, useStore } from '@hooks/storeHook'
import { filters } from '@utils/filtersForDatatable'
import { tableNameAttraction } from '@utils/variableApp'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useEffect } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

export const AdminAttractionScreen = observer(() => {
  const navigate = useNavigate()
  const logic = useLogic()
  const {
    attraction: { adminAttraction },
  } = useStore()

  const onAdd = () => {
    navigateTo(AppRoutesPaths.AdminPanelAttractionAdd)
  }

  useEffect(() => {
    logic.loadAdminAttraction()
  }, [])

  function onClickEdit(item: ApiAttraction) {
    let path: string = ''

    path = generatePath(AppRoutesPaths.AdminPanelAttractionUpdate, {
      id: String(item.id),
    })

    navigate(path)
  }

  const buttonBodyTemplate = (item: ApiAttraction) => {
    return <TableButtons onClickEdit={() => onClickEdit(item)} />
  }

  return (
    <>
      <TablePageContainer
        tableProps={{
          arrayHeadName: tableNameAttraction,
          children: <TableList items={adminAttraction ?? []} />,
          isDeleteCheckbox: true,
        }}
        mainTitle='Аттракционы'
        textAdd={'Аттракцион'}
        onClickAdd={onAdd}
        textArchive={'Аттракционы'}
        isAdmin={true}
      >
        <DataTable
          value={adminAttraction ?? []}
          showGridlines
          className={classnames('bg-white text-base')}
          style={{ fontSize: '12px' }}
          removableSort
          sortField='name'
          sortOrder={1}
          emptyMessage='Нет данных'
          filters={filters}
        >
          <Column
            field='photos'
            body={imgBodyTemplate}
            header='Фото'
            className='max-w-[60px]'
            bodyClassName='text-center'
          ></Column>
          <Column
            field='name'
            header='Название'
            sortable
            // filter
            // filterPlaceholder='Поиск по названию'
            // showAddButton={false}
            // showFilterOperator={false}
          ></Column>
          <Column field='' header='' body={buttonBodyTemplate}></Column>
        </DataTable>
      </TablePageContainer>
    </>
  )
})

export const TableList = (props: { items: ApiAttraction[] }) =>
  props.items.map((item, index) => <AttractionTableItem isAdmin key={item.id} index={index + 1} item={item} />)
