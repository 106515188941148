import { appColors } from '@config/theme'
import { ProgressSpinner } from 'primereact/progressspinner'

export const SkalalandSpiner = () => {
  return (
    //@ts-ignore
    <div style={styles.container}>
      <ProgressSpinner />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: appColors.white,
    gap: '24px',
  },
}
