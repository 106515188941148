import { ApiAttraction } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { observable } from 'mobx'

export class AttractionStore {
  @observable
  mainLoading = LoadState.Initial

  @observable
  loadById = LoadState.Initial

  @observable
  selectedUpdateAttraction: ApiAttraction | null = null

  @observable
  attractions: ApiAttraction[] | null = null

  resetUpdateAttraction = () => {
    this.selectedUpdateAttraction = null
  }

  @observable
  adminAttraction: ApiAttraction[] | null = null

  addAttraction = (attraction: ApiAttraction) => {
    if (this.attractions) {
      this.attractions = [...this.attractions, attraction]
    } else {
      this.attractions = [attraction]
    }
  }
  addAdminAttraction = (attraction: ApiAttraction) => {
    if (this.adminAttraction) {
      this.adminAttraction = [...this.adminAttraction, attraction]
    } else {
      this.adminAttraction = [attraction]
    }
  }
  updateAttraction(response: ApiAttraction) {
    if (this.attractions) {
      const index = this.attractions.findIndex((item) => item.id === response.id)
      if (index !== -1) {
        this.attractions = [...this.attractions.map((item) => (item.id === response.id ? response : item))]
      }
    } else {
      this.attractions = [response]
    }
  }

  updateAttractionAdmin(response: ApiAttraction) {
    if (this.adminAttraction) {
      const index = this.adminAttraction.findIndex((item) => item.id !== response.id)
      if (index >= 0) {
        this.adminAttraction = [...this.adminAttraction.map((item) => (item.id === response.id ? response : item))]
      }
    } else {
      this.adminAttraction = [response]
    }
  }
}
