import { ApiDataCities } from '@@types/apiLogicTypes'

export const getCity = (city: ApiDataCities | null, idCity: number) => {
  if (city) {
    const findIndex = city.findIndex((item) => item.id === idCity)
    if (findIndex >= 0) {
      return city[findIndex]
    }
  }
}
