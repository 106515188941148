import { ApiEmployee } from '@@types/apiLogicTypes'
import { Avatar } from 'primereact/avatar'

export const UserAvatar = ({
  className,
  onClick,
  user,
}: {
  className?: string
  onClick: () => void
  user: ApiEmployee
}) => (
  <div className={`flex flex-col justify-center items-center gap-[2px] cursor-pointer ${className}`} onClick={onClick}>
    {user.image ? (
      <Avatar image={user.image} size='normal' shape='circle' className='w-[40px] h-[40px]' />
    ) : (
      <Avatar icon='pi pi-user' size='normal' shape='circle' />
    )}
    <span className='text-black text-sm text-center '> {(user.surname ? user.surname + ' ' : '') + user.name} </span>
  </div>
)
