import { ApiAnimator, ApiEmployee, ApiEmployeeTrackingReports, ApiUser, Role } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { action, observable } from 'mobx'
import moment from 'moment'

export class UsersStore {
  @observable
  loading = LoadState.Initial

  @observable
  clients: ApiUser[] = []

  @observable
  animators: ApiAnimator[] = []

  @observable
  employees: ApiEmployee[] = []

  @observable
  managers: ApiEmployee[] = []

  @observable
  roles: Role[] = []

  @observable
  employeeTrackingReports: ApiEmployeeTrackingReports[] = []

  @observable
  additionalTrackingReports: ApiEmployeeTrackingReports[] = []

  @action
  editEmployee = (employee: ApiEmployee) => {
    if (this.employees.length === 0) return
    this.employees = this.employees.map((e) => (e.id === employee.id ? employee : e))
  }

  @action
  addEmployee = (employee: ApiEmployee) => {
    if (this.employees.length === 0) return
    this.employees = [...this.employees, employee]
  }

  @action
  addManager = (employee: ApiEmployee) => {
    if (this.managers.length === 0) return
    this.managers = [...this.managers, employee]
  }

  @action
  editManager = (employee: ApiEmployee) => {
    if (this.managers.length === 0) return
    this.managers = this.managers.map((e) => (e.id === employee.id ? employee : e))
  }

  @action
  resetEmployees = () => {
    this.employees = []
    this.managers = []
  }

  @action
  deleteAdditionalTrackingReport = (id: number | undefined, index?: number) => {
    if (index !== undefined)
      this.additionalTrackingReports = this.additionalTrackingReports.filter((e, i) => i !== index)
    else this.additionalTrackingReports = this.additionalTrackingReports.filter((e) => e.id !== id)
  }

  @action
  deleteEmployeeTrackingReport = (id: number | undefined, index?: number) => {
    if (index !== undefined) this.employeeTrackingReports = this.employeeTrackingReports.filter((e, i) => i !== index)
    else this.employeeTrackingReports = this.employeeTrackingReports.filter((e) => e.id !== id)
  }

  @action
  editEmployeeTrackingReport = (report: ApiEmployeeTrackingReports, index?: number) => {
    if (index !== undefined)
      this.employeeTrackingReports = this.employeeTrackingReports.map((e, i) => (i === index ? report : e))
    else this.employeeTrackingReports = this.employeeTrackingReports.map((e) => (e.id === report.id ? report : e))
  }

  @action
  editAdditionalTrackingReport = (report: ApiEmployeeTrackingReports, index?: number) => {
    if (index !== undefined)
      this.additionalTrackingReports = this.additionalTrackingReports.map((e, i) => (i === index ? report : e))
    else
      this.additionalTrackingReports = this.additionalTrackingReports.map((e) =>
        e.id === report.id ? { ...report, sum: report.format == 2 ? 0 - report.sum! : report.sum } : e
      )
  }

  @action
  addEmptyTrackingReport = (parkId: number) => {
    this.employeeTrackingReports = [
      {
        comment: null,
        date: moment(new Date(), 'YYYY-MM-DD').toDate().toLocaleDateString('en-CA'),
        format: 1,
        id: undefined,
        park_id: parkId,
        type: 1,
        user: undefined,
        user_id: undefined,
        from: undefined,
        to: undefined,
      },
      ...this.employeeTrackingReports,
    ]
  }

  @action
  addEmptyAdditionalReport = (parkId: number) => {
    this.additionalTrackingReports = [
      {
        comment: '',
        date: moment(new Date(), 'YYYY-MM-DD').toDate().toLocaleDateString('en-CA'),
        format: 1,
        id: undefined,
        park_id: parkId,
        type: 2,
        user: undefined,
        user_id: undefined,
        sum: undefined,
      },
      ...this.additionalTrackingReports,
    ]
  }

  @action
  setAdditionalReports = (reports: ApiEmployeeTrackingReports[]) => {
    this.additionalTrackingReports = reports.map((e) => ({ ...e, sum: e.format === 2 ? 0 - e.sum! : e.sum }))
  }
}
