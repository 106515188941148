import { ApiCreateAttractionForm } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { AttractionContainer } from '@components/ui/AttractionContainer/AttractionContainer'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { appColors } from '@config/theme'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { createFormData } from '@utils/createDataForm'
import { observer } from 'mobx-react-lite'
import { ProgressSpinner } from 'primereact/progressspinner'

import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const AttractionUpdateScreen = observer(() => {
  const {
    attraction: { resetUpdateAttraction, loadById, selectedUpdateAttraction },

    auth: { selectedPark },
  } = useStore()

  const logic = useLogic()
  const title = 'Редактирование аттракциона'
  const { id } = useParams()

  const navigate = useNavigate()
  const context = useContext(ToastContext)

  async function sendForm(form: ApiCreateAttractionForm) {
    const formData = createFormData(form)

    if (form.images) {
      for (let i = 0; i < form.images.length; i++) {
        if (form.images[i].file) {
          formData.append(`images[]`, form.images[i]?.file ?? '')
        }
      }
    }
    formData.delete('images')
    const idSend = form?.id ? form?.id : id
    const { status, errors } = await logic.updateAttraction(formData, String(idSend) ?? -1)

    if (status) {
      navigateTo(AppRoutesPaths.SettingAttraction)
    } else {
      context?.toastShowHandler({ status: status, errors })
    }
  }

  useEffect(() => {
    loadTicket()
    return () => resetUpdateAttraction()
  }, [])

  async function loadTicket() {
    if (!id) {
      navigateToAttraction()
      return
    }

    const response = await logic.loadAttractionById(id)

    if (!response) {
      navigateToAttraction()
      return
    }
  }

  function navigateToAttraction() {
    navigate(AppRoutesPaths.SettingAttraction)
  }

  if (loadById === LoadState.Loading) {
    return (
      //@ts-ignore
      <div style={styles.container}>
        <ProgressSpinner />
      </div>
    )
  }
  return (
    <>
      {selectedPark && (
        <AttractionContainer
          title={title}
          sendForm={sendForm}
          selectedPark={selectedPark}
          initialValue={selectedUpdateAttraction}
        />
      )}
    </>
  )
})

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: appColors.white,
    gap: '24px',
  },
}
