import { ApiCreateApplicationForm, ApiUser } from '@@types/apiLogicTypes'
import FormInput from '@components/ui/Form/FormInput'
import { UiText } from '@components/ui/UiText/UiText'
import { useLogic } from '@hooks/storeHook'
import { IDisabled } from '@modules/TicketSale/Form/Form'
import { formatPhoneNumber } from '@utils/formatForm'
import { formatPhone, normalizePhone } from '@utils/textUtils'
import cn from 'classnames'
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from 'primereact/autocomplete'
import { Avatar } from 'primereact/avatar'
import { FC, useState } from 'react'
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form'

const ClientPart: FC<IDisabled> = ({ disable }) => {
  const methods = useFormContext<ApiCreateApplicationForm>()
  const logic = useLogic()
  const [filteredUsers, setFilteredUsers] = useState<ApiUser[]>([])
  const [isUserSelected, setIsUserSelected] = useState<boolean>(false)

  const search = async (event: AutoCompleteCompleteEvent) => {
    let _filteredUsers: ApiUser[] = []

    if (event.query.trim().length < 7) {
      _filteredUsers = []
    } else {
      const response = await logic.searchUserByPhone(event.query)
      //@ts-ignore
      _filteredUsers = response
    }

    setFilteredUsers(_filteredUsers)
  }

  const onChangePhone = (
    field: ControllerRenderProps<ApiCreateApplicationForm, 'order.user.phone'>,
    e: AutoCompleteChangeEvent
  ) => {
    if (e.target.value.phone) {
      setIsUserSelected(true)
      field.onChange(formatPhoneNumber(e.target.value.phone))
      methods.setValue('order.user.name', e.target.value.name)
      methods.setValue('order.user.email', e.target.value.email)
      methods.setValue('order.user.children', e.target.value.children)
      methods.setValue('order.user.bonus', e.target.value.bonus?.sum ?? 0)
      methods.clearErrors('order.user.name')
    } else {
      handleInputChange(field, e)
      setIsUserSelected(false)
    }
  }

  const handleInputChange = (
    field: ControllerRenderProps<ApiCreateApplicationForm, 'order.user.phone'>,
    e: AutoCompleteChangeEvent
  ) => {
    if (isUserSelected) {
      methods.setValue('order.children', [])
      methods.setValue('order.newKid', undefined)
      methods.setValue('order.user.name', '')
      methods.setValue('order.user.email', '')
      methods.setValue('order.user.children', [])
      methods.setValue('order.user.bonus', 0)
    }
    const inputValue = e.target.value
    const formattedValue = formatPhone(normalizePhone(inputValue))
    if (e.target.value.length <= 16) {
      field.onChange(formattedValue)
    }
  }

  return (
    <div className='flex flex-col gap-4 w-full'>
      <UiText className='h-[36px] text-lg font-bold content-center'>Гость</UiText>
      <Controller
        name='order.user.phone'
        control={methods.control}
        rules={{ required: 'Value is required.', minLength: 16 }}
        render={({ field, fieldState }) => (
          <div className={`flex flex-col gap-[4px]`}>
            <AutoComplete
              maxLength={16}
              field='name'
              value={field.value}
              suggestions={filteredUsers}
              delay={500}
              completeMethod={search}
              itemTemplate={(e) => ItemTemplate(e)}
              placeholder='Номер телефона'
              onChange={(e) => {
                onChangePhone(field, e)
              }}
              className={cn(
                fieldState.error && 'border-red',
                'text-field bg-white px-[8px] py-[10px] h-[36px] border-solid text-grey border-green rounded-[3px] focus:outline-none border focus:shadow-none !p-0 w-full'
              )}
              disabled={disable}
            />
          </div>
        )}
      />
      <FormInput name='order.user.name' placeholder='Имя' required={true} completed={disable || isUserSelected} />
      <FormInput
        placeholder='E-mail'
        name='order.user.email'
        customPattern={
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        }
        completed={disable || isUserSelected}
      />
    </div>
  )
}

export const ItemTemplate = (item: ApiUser, query?: string) => {
  const index = query ? item.name.toLocaleLowerCase().indexOf(query.toLocaleLowerCase().replace(' ', '')) : -1
  const phone = formatPhoneNumber(item.phone!)
  const indexPhone = query ? phone.indexOf(query) : -1
  let parts_array: string[] = []
  let parts_array_phone: string[] = []

  if (index !== -1) {
    const first_part = item.name.substring(0, index)
    const second_part = item.name.substring(index, query!.length)
    const third_part = item.name.substring(index - 1 + query!.length)

    parts_array = [first_part, second_part, third_part]
  }

  if (indexPhone !== -1) {
    const first_part = phone.substring(0, index)
    const second_part = phone.substring(index, query!.length)
    const third_part = phone.substring(index + 1 + query!.length)

    parts_array_phone = [first_part, second_part, third_part]
  }

  return (
    <div className='flex gap-4 items-center'>
      <Avatar icon='pi pi-user' size='normal' shape='circle' />
      <div className='gap flex flex-col gap-1'>
        <div>
          {index !== -1 ? (
            parts_array.map((e, i) => (
              <span key={i} className={i === 1 ? 'font-bold text-base' : 'text-base'}>
                {e}
              </span>
            ))
          ) : (
            <UiText className='text-base'>{item.name}</UiText>
          )}
        </div>
        <div>
          {indexPhone !== -1 ? (
            parts_array_phone.map((e, i) => (
              <span key={i} className={i === 1 ? 'font-bold text-sm' : 'text-sm'}>
                {e}
              </span>
            ))
          ) : (
            <UiText className='text-sm'>{phone}</UiText>
          )}
        </div>
      </div>
    </div>
  )
}

export default ClientPart
