import { ApiSale } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import {
  buttonBodyTemplate,
  checkBoxBodyTemplate,
  nameBodyTemplateWithIcon,
} from '@components/ui/ColumnContents/ColumnContents'
import { SettingsHeaderBtn } from '@components/ui/SettingsHeaderBtn/SettingsHeaderBtn'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ArchiveContext } from '@context/archiveContext'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { onAdd } from '@utils/tableUtils'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { FC, useContext, useEffect } from 'react'

export interface ILoyalty {
  isAdmin?: boolean
  inArchive?: boolean
}

export const triggers = [
  { name: 'Первая оплата', id: 1 },
  { name: 'Не ходили более X дней', id: 2 },
  { name: 'Продажа услуги', id: 3 },
  { name: 'Продажа билета', id: 4 },
  { name: 'Проведение праздника', id: 9 },
]

export const bonusTypes = [
  { name: 'Рубли', id: 1 },
  { name: 'Проценты', id: 2 },
]

export const LoyaltyPage: FC<ILoyalty> = observer(({ isAdmin, inArchive = false }) => {
  const logic = useLogic()
  const context = useContext(ToastContext)

  const {
    advertising: { loading, sales },
    auth: { selectedPark },
  } = useStore()

  useEffect(() => {
    if (!selectedPark) return
    isAdmin ? logic.loadAdminSales() : logic.loadSales(selectedPark.id)
  }, [selectedPark])

  const toggleActive = async (item: ApiSale) => {
    await logic.updateSale(`?active=${Number(!Boolean(item.active))}`, item.id)
  }

  const onDelete = async (id: number) => {
    const { status, errors } = await logic.deleteSale(id)
    if (!status) context?.toastShowHandler({ status: false, errors: errors })
  }

  const onArchive = async (item: ApiSale) => {
    const { status, errors } = await logic.updateSale(`?deleted=${Number(!Boolean(item.deleted))}`, item.id)
    if (!status) context?.toastShowHandler({ status: false, errors: errors })
  }

  const toArchive = () => {
    navigateTo(AppRoutesPaths.AdvertisingLoyaltyArchive)
  }

  return (
    <Layout title='Программа лояльности'>
      <ArchiveContext.Provider value={{ inArchive, onArchive: toArchive }}>
        <SettingsHeaderBtn
          textAdd={'Начисление'}
          onClickAdd={() => onAdd(isAdmin ? AppRoutesPaths.AdminPanelAddLoyalty : AppRoutesPaths.AdvertisingAddLoyalty)}
          textArchive={'Начисления'}
          isAdmin={isAdmin}
        />
        <DataTable
          value={isAdmin ? sales : sales.filter((e) => e.deleted === (inArchive ? 1 : 0))}
          showGridlines
          className='bg-white text-base'
          style={{ fontSize: '12px' }}
          removableSort
          sortOrder={1}
          emptyMessage='Нет данных'
          loading={loading === LoadState.Loading}
        >
          <Column
            field='name'
            header='Название'
            sortable
            body={(item) =>
              nameBodyTemplateWithIcon(
                item,
                isAdmin ? AppRoutesPaths.AdminPanelEditLoyalty : AppRoutesPaths.AdvertisingEditStories
              )
            }
          />
          <Column
            field='trigger'
            header='Условие'
            sortable
            body={(e: ApiSale) => triggerBodyTemplate(e.trigger_type)}
          />
          <Column field='type' header='Тип' sortable body={(e: ApiSale) => typeBodyTemplate(e.bonus_type)} />
          <Column field='discount_value' header='Размер' sortable />
          <Column field='activations' header='Активаций' sortable />
          {!isAdmin && !inArchive && (
            <Column
              field='active'
              header='Сайт'
              body={(item) => checkBoxBodyTemplate(item, () => toggleActive(item))}
            />
          )}
          <Column
            field=''
            header=''
            body={(item) =>
              buttonBodyTemplate({
                item: item,
                path: isAdmin ? AppRoutesPaths.AdminPanelEditLoyalty : AppRoutesPaths.AdvertisingEditLoyalty,
                onArchive: !isAdmin ? () => onArchive(item) : undefined,
                onDelete: !isAdmin ? () => onDelete(item.id) : undefined,
              })
            }
          />
        </DataTable>
      </ArchiveContext.Provider>
    </Layout>
  )
})

const triggerBodyTemplate = (id: number) => <span>{triggers.find((e) => e.id === id)?.name}</span>
export const typeBodyTemplate = (id: number) => <span>{bonusTypes.find((e) => e.id === id)?.name}</span>
