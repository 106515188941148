export const lang = {
  en: null,
  ru: {
    week: 'Неделя',
    work_week: 'Рабочая неделя',
    day: 'День',
    month: 'Месяц',
    previous: 'Предыдущий',
    next: 'Следующий',
    today: 'Сегодня',
    agenda: 'Повестка дня',
    showMore: (total: any) => `+${total} еще`,
  },
}
