import { ApiCreateStoriesForm, ApiStories, ApiValidationResponse } from '@@types/apiLogicTypes'
import { itemPhotoFile } from '@components/Settings/ServicePhotoItem'
import { renderItemTemplate, renderLabelTemplate } from '@components/ui/DropdownColorTemplate/DropdownColorTemplate'
import FormInput from '@components/ui/Form/FormInput'
import { LoadPhoto } from '@components/ui/LoadPhoto/LoadPhoto'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { createFormData } from '@utils/createDataForm'
import { colorOptions } from '@utils/variableApp'
import { observer } from 'mobx-react-lite'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { FC, useContext, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { IStories } from '../StoriesPage'

const StoriesFormScreen: FC<IStories> = observer(({ isAdmin }) => {
  const {
    auth: { selectedPark },
    advertising: { stories },
  } = useStore()
  const { id } = useParams()
  const [initialValue, setInitialValue] = useState<ApiStories | undefined>()

  useEffect(() => {
    const loadStories = async () => {
      if (!isAdmin && selectedPark) {
        await logic.getStories(selectedPark.id)
      } else if (isAdmin) await logic.getAdminStories()
    }
    if (stories.length === 0) {
      loadStories()
    } else setInitialValue(stories.find((e) => e.id === Number(id)))
  }, [selectedPark])

  useEffect(() => {
    setInitialValue(stories.find((e) => e.id === Number(id)))
  }, [stories])

  useEffect(() => {
    methods.reset({
      name: initialValue?.name,
      tag: initialValue?.tag,
      sort: initialValue?.sort,
      tag_color: { color: initialValue?.tag_color },
      active: initialValue?.active ?? 0,
    })
    setFiles(initialValue?.images ?? [])
    setPreview(initialValue?.preview_image ? [{ path: initialValue?.preview_image ?? '', name: '' }] : [])
  }, [initialValue])

  const methods = useForm<ApiCreateStoriesForm>({
    mode: 'onChange',
  })

  const logic = useLogic()
  const context = useContext(ToastContext)

  const [isDisabledFile, setDisabledFile] = useState<boolean>(false)
  const [files, setFiles] = useState<itemPhotoFile[]>(initialValue?.images ?? [])
  const [filesDeleteId, setFilesDeleteId] = useState<number[]>([])

  const [preview, setPreview] = useState<itemPhotoFile[]>([])
  const [, setDisabledPreview] = useState<boolean>(false)

  function setUploadPreview(item: itemPhotoFile) {
    setPreview((prev) => [...prev, item])
  }
  function onClickPreviewDelete(indexFileDelete: number) {
    setPreview((prev) => prev.filter((_, index) => index !== indexFileDelete))
    setDisabledPreview(false)
  }
  function setUploadFile(item: itemPhotoFile) {
    setFiles((prev) => [...prev, item])
  }
  function onClickDelete(indexFileDelete: number, id?: number) {
    setFiles((prev) => prev.filter((_, index) => index !== indexFileDelete))
    if (id) {
      setFilesDeleteId((prev) => [...prev, id])
    }
  }

  const onSubmit = async () => {
    const formData = createFormData({
      ...methods.getValues(),
      active: +methods.getValues().active,
      tag_color: methods.getValues().tag_color.color,
      deleted: 0,
      delete_images: filesDeleteId,
      park_id: selectedPark?.id,
    })
    if (preview[0] && preview[0].file) {
      formData.append('preview_image', preview[0].file)
    }
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].file) {
          formData.append(`images[]`, files[i]?.file ?? '')
        }
      }
    }

    let response: ApiValidationResponse = { status: false }
    if (initialValue) {
      if (isAdmin) response = await logic.editAdminStory(formData, initialValue.id)
      else response = await logic.editStory(formData, initialValue.id)
    } else {
      if (isAdmin) response = await logic.createAdminStory(formData)
      else response = await logic.createStory(formData)
    }
    if (response.status) {
      navigateTo(isAdmin ? AppRoutesPaths.AdminPanelStories : AppRoutesPaths.AdvertisingStories)
    } else {
      context?.toastShowHandler({ status: response.status, errors: response.errors })
    }
  }

  return (
    <Layout scrollable title='Добавить сторис'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className='flex flex-col justify-between h-full'>
          <section className='flex flex-col gap-4 mb-5'>
            <div className='flex gap-8'>
              <FormInput
                className={`w-[233px] text-field bg-white px-[8px] py-[10px] h-[36px] border-solid border-[1px] text-grey border-green rounded-[3px] focus:outline-none`}
                title='Название'
                name='name'
              />
              <FormInput
                className={`w-[233px] text-field bg-white px-[8px] py-[10px] h-[36px] border-solid border-[1px] text-grey border-green rounded-[3px] focus:outline-none`}
                title='Тег'
                name='tag'
                required={true}
              />
            </div>
            <div className='flex gap-8'>
              <FormInput
                className={`w-[233px] text-field bg-white px-[8px] py-[10px] h-[36px] border-solid border-[1px] text-grey border-green rounded-[3px] focus:outline-none`}
                title='Сортировка'
                name='sort'
                inputMode='numeric'
                type='number'
                required={true}
                placeholder='Введите сортировку'
              />

              <div className='gap-[4px] flex flex-col max-w-[233px] w-[100%]'>
                <UiText className='text-grey'>Цвет тега</UiText>

                <Controller
                  name='tag_color'
                  control={methods.control}
                  rules={{ required: '' }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      itemTemplate={renderItemTemplate}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={colorOptions}
                      optionLabel='color'
                      valueTemplate={renderLabelTemplate}
                      placeholder='Цвет сторис'
                      className={`w-full md:w-14rem border-green worker ${fieldState.error ? 'border-red' : ''}`}
                      panelClassName='worker-panel color-picker'
                      color='green'
                    />
                  )}
                />
              </div>
            </div>
            <div className='flex gap-[38px]'>
              <div className='flex flex-row gap-[33px]'>
                <LoadPhoto
                  isDisabledFile={preview.length === 1}
                  setDisabledFile={(isDisabledFile) => setDisabledPreview(isDisabledFile)}
                  arrayFile={preview}
                  setUploadFile={setUploadPreview}
                  onClickDelete={onClickPreviewDelete}
                  title='Превью (480x600)'
                  buttonClassName='file-upload-workers-green'
                  chooseLabel={'Прикрепить превью'}
                  imgClassName='!w-[160px] !h-[200px]'
                />
              </div>
              <div className='flex flex-row gap-[33px]'>
                <LoadPhoto
                  isDisabledFile={isDisabledFile}
                  setDisabledFile={(isDisabledFile) => setDisabledFile(isDisabledFile)}
                  arrayFile={files}
                  setUploadFile={setUploadFile}
                  onClickDelete={onClickDelete}
                  title='Содержимое (450x900)'
                  chooseLabel={'Прикрепить картинки'}
                  imgClassName='!w-[150px] !min-h-[300px]'
                  accept='image/*,video/*'
                />
              </div>
            </div>
          </section>

          <div className='flex gap-8 border-0 border-t-[1px] border-grey-secondary border-solid pt-4'>
            <UiButton className='hover:bg-green-[#16A34A] w-[100%] !font-black  justify-center max-h-[36px] max-w-[233px] uppercase !text-sm'>
              {initialValue ? 'Изменить' : 'Добавить'}
            </UiButton>
            {!isAdmin && (
              <div className='flex gap-2 items-center'>
                <Controller
                  name='active'
                  control={methods.control}
                  render={({ field }) => (
                    <Checkbox
                      checked={Boolean(field.value)}
                      onChange={(e) => field.onChange(e.checked)}
                      inputRef={field.ref}
                    />
                  )}
                />
                <UiText className='text-grey mb-[3px]'>Доступно на сайте</UiText>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    </Layout>
  )
})

export default StoriesFormScreen
