import { ApiCreateTicketForm, ApiValidationResponse } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { SkalalandSpiner } from '@components/ui/SkalalandSpiner/SkalalandSpiner'
import { TicketContainer } from '@components/ui/TicketContainer/TicketContainer'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'

import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const TicketUpdateScreen = observer(() => {
  const {
    services: { units: unitsStore, measures },
    ticket: { types, resetUpdateTicket, loadById, selectedUpdateTicket },
    auth: { selectedPark },
  } = useStore()
  const logic = useLogic()
  const { id } = useParams()
  const context = useContext(ToastContext)
  const navigate = useNavigate()

  const title = 'Редактирование билета'

  async function sendForm(form: ApiCreateTicketForm) {
    const { status, errors }: ApiValidationResponse = await logic.updateTicket(form)

    if (status) {
      navigateTo(AppRoutesPaths.SettingTicket)
    } else {
      context?.toastShowHandler({ status: status, errors })
    }
  }

  useEffect(() => {
    loadTicket()

    return () => resetUpdateTicket()
  }, [])

  async function loadTicket() {
    if (!id) {
      navigateToTicket()
      return
    }

    const response = await logic.loadTicketById(id)

    if (!response) {
      navigateToTicket()
      return
    }
  }

  function navigateToTicket() {
    navigate(AppRoutesPaths.SettingTicket)
  }
  if (loadById === LoadState.Loading) {
    return <SkalalandSpiner />
  }
  return (
    <>
      {selectedPark && selectedUpdateTicket && (
        <TicketContainer
          title={title}
          initialValue={selectedUpdateTicket}
          types={types ?? []}
          measures={measures ?? []}
          sendForm={sendForm}
          selectedPark={selectedPark}
          unitsStore={unitsStore ?? []}
        />
      )}
    </>
  )
})
