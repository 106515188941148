//DD MM
export function formatDate(inputDate: string): string {
  inputDate = inputDate.split('T')[0]
  const [year, month, day] = inputDate.split('-')

  const dateObject = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))

  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' }

  const dateFormatter = new Intl.DateTimeFormat('ru-RU', options)
  const formattedDate: string = dateFormatter.format(dateObject)

  const formattedDateWithoutDot: string = formattedDate.replace(/\./, '')

  const [formattedDay, formattedMonth] = formattedDateWithoutDot.split(' ')

  return `${formattedDay} ${formattedMonth.toUpperCase()}`
}

export function formatRelativeTime(dateTimeString: string): string {
  const currentDate = new Date()
  const dateToCompare = new Date(dateTimeString)

  const timeDifference = currentDate.getTime() - dateToCompare.getTime()

  const seconds = Math.floor(timeDifference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (days > 0) {
    const daysString = getDaysString(days)
    return `${days} ${daysString}`
  } else if (hours > 0) {
    return `${hours} ${getHoursString(hours)}`
  } else if (minutes > 0) {
    return `${minutes} ${getMinutesString(minutes)}`
  } else {
    return 'Только что'
  }
}

function getDaysString(days: number): string {
  if (days === 1) {
    return 'день'
  } else if (days >= 2 && days <= 4) {
    return 'дня'
  } else {
    return 'дней'
  }
}

function getHoursString(hours: number): string {
  if (hours === 1) {
    return 'час'
  } else if (hours >= 2 && hours <= 4) {
    return 'часа'
  } else {
    return 'часов'
  }
}

function getMinutesString(minutes: number): string {
  if (minutes === 1) {
    return 'минуту'
  } else if (minutes >= 2 && minutes <= 4) {
    return 'минуты'
  } else {
    return 'минут'
  }
}

export const isWeekend = (date?: Date) => {
  if (!date) date = new Date()
  return date.getDay() === 6 || date.getDay() === 0
}

export function getNearestMondayAndSunday(inputDate: Date): {
  monday: Date
  sunday: Date
} {
  const result = { monday: new Date(inputDate), sunday: new Date(inputDate) }

  const currentDayOfWeek = inputDate.getDay()

  const daysUntilMonday = (currentDayOfWeek + 6) % 7

  result.monday.setDate(inputDate.getDate() - daysUntilMonday)
  result.sunday.setDate(inputDate.getDate() + (6 - daysUntilMonday))

  return { monday: result.monday, sunday: result.sunday }
}

export const formatDateForApi = (stringDate: string): string => {
  const date = new Date(stringDate)
  const year: number = date.getFullYear()
  const month: string = (date.getMonth() + 1).toString().padStart(2, '0')
  const day: string = date.getDate().toString().padStart(2, '0')
  const hours: string = date.getHours().toString().padStart(2, '0')
  const minutes: string = date.getMinutes().toString().padStart(2, '0')
  const seconds: string = date.getSeconds().toString().padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}
