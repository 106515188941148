import { LoadState } from '@@types/loadState'
import { observable } from 'mobx'
import { AdvertisingStore } from './advertisingStore'
import { ApplicationStore } from './applicationsStore'
import { AttractionStore } from './attractionStore'
import { AuthStore } from './authStore'
import { CategoryStore } from './categoryStore'
import { GameStore } from './gameStore'
import { HolidayStore } from './holidayStore'
import { ParkStore } from './parksStore'
import { PaymentsStore } from './paymentsStore'
import { ProductStore } from './productStore'
import { RoomsStore } from './roomsStore'
import { ServicesStore } from './servicesStore'
import { TicketStore } from './ticketStore'
import { UsersStore } from './usersStore'

export class Store {
  @observable
  rootLoading = LoadState.Initial

  @observable
  auth = new AuthStore()

  @observable
  park = new ParkStore()

  @observable
  category = new CategoryStore()

  @observable
  services = new ServicesStore()

  @observable
  rooms = new RoomsStore()

  @observable
  ticket = new TicketStore()

  @observable
  attraction = new AttractionStore()

  @observable
  product = new ProductStore()

  @observable
  holiday = new HolidayStore()

  @observable
  applications = new ApplicationStore()

  @observable
  advertising = new AdvertisingStore()

  @observable
  game = new GameStore()

  @observable
  users = new UsersStore()

  @observable
  payments = new PaymentsStore()
}
