import { FormRoom } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { RoomContainer } from '@components/ui/RoomContainer/RoomContainer'
import { SkalalandSpiner } from '@components/ui/SkalalandSpiner/SkalalandSpiner'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { createFormData } from '@utils/createDataForm'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const RoomUpdateScreen = observer(() => {
  const {
    auth: { selectedPark },
    rooms: { selectedUpdateRoom, loadById, types, resetUpdateAttraction },
  } = useStore()
  const logic = useLogic()
  const { id } = useParams()
  const context = useContext(ToastContext)
  const navigate = useNavigate()

  const title = 'Редактирование патирума'

  async function sendForm(form: FormRoom) {
    const formData = createFormData(form)
    if (form.images) {
      for (let i = 0; i < form.images.length; i++) {
        if (form.images[i].file) {
          formData.append(`images[]`, form.images[i]?.file ?? '')
        }
      }
    }
    formData.delete('images')

    const { status, errors } = await logic.editRoom(formData, form.id ?? -1)

    if (status) {
      navigateTo(AppRoutesPaths.SettingsRooms)
    } else {
      context?.toastShowHandler({ status: status, errors })
    }
  }

  useEffect(() => {
    loadRoom()

    return () => resetUpdateAttraction()
  }, [])

  async function loadRoom() {
    if (!id) {
      navigateToRoom()
      return
    }

    const response = await logic.loadRoomById(id)

    if (!response) {
      navigateToRoom()
      return
    }
  }

  function navigateToRoom() {
    navigate(AppRoutesPaths.SettingsRooms)
  }
  if (loadById === LoadState.Loading) {
    return <SkalalandSpiner />
  }
  return (
    <>
      {selectedPark && selectedUpdateRoom && (
        <RoomContainer
          title={title}
          initialValue={selectedUpdateRoom}
          types={types ?? []}
          callbackForm={sendForm}
          selectedPark={selectedPark}
        />
      )}
    </>
  )
})
