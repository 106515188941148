import { Park } from '@@types/apiLogicTypes'
import { TableFilter } from '@components/ui/TableFilter/TableFilter'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { PropsWithChildren } from 'react'

export type CenterTableProps = {
  centers: Park[]
  onClickPark(park: Park): void
}
export const CenterTable = ({ centers, onClickPark }: CenterTableProps) => {
  return (
    <table className='table-auto border-collapse border-solid border-[1px] border-grey-secondary'>
      <thead>
        <tr>
          <TableFilter placeholder='Город' />
          <TableFilter placeholder='Название' />
          <TableFilter placeholder='Адрес' />

          <TH width={172}></TH>
        </tr>
      </thead>
      <tbody>
        {centers.map((item) => {
          return (
            <tr key={item.id}>
              <TD>
                <UiText>{item.city.name}</UiText>
              </TD>
              <TD>
                <UiText>{item.name}</UiText>
              </TD>
              <TD>
                <UiText>{item.address}</UiText>
              </TD>

              <TD center>
                <div className='flex flex-row gap-[16px] px-[8px] justify-center'>
                  <UiButton onClick={() => onClickPark(item)} className='min-w-[140px]'>
                    Открыть
                  </UiButton>
                </div>
              </TD>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const TH = ({ children, width }: PropsWithChildren & { width?: number }) => (
  <th
    style={{ width: width ? `${width}px` : '193px' }}
    className='px-[8px]  py-[10px] !text-sm !font-bold   border-solid border-[1px] border-grey-secondary '
  >
    {children}
  </th>
)
const TD = ({ children, center }: PropsWithChildren & { center?: boolean }) => (
  <td className={`p-[8px] border-solid border-[1px] border-grey-secondary  ${center ? 'text-center' : ''}`}>
    {children}
  </td>
)
