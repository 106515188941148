import { LoadState } from '@@types/loadState'
import {
  buttonBodyTemplate,
  checkBoxBodyTemplate,
  nameBodyTemplateWithIcon,
} from '@components/ui/ColumnContents/ColumnContents'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { AppRoutesPaths } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { onAdd, onToggleActive } from '@utils/tableUtils'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { FC, useContext, useEffect } from 'react'

export interface IStories {
  isAdmin?: boolean
}

const StoriesPage: FC<IStories> = observer(({ isAdmin }) => {
  const logic = useLogic()
  const context = useContext(ToastContext)

  const {
    advertising: { stories, loading },
    auth: { selectedPark },
  } = useStore()

  useEffect(() => {
    if (!selectedPark) return
    isAdmin ? logic.getAdminStories() : logic.getStories(selectedPark.id)
  }, [selectedPark])

  const onDelete = async (id: number) => {
    const { status, errors } = await logic.deleteStories(id)
    if (!status) context?.toastShowHandler({ status: false, errors: errors })
  }

  return (
    <Layout title='Сторис'>
      <UiButton
        onClick={() => onAdd(isAdmin ? AppRoutesPaths.AdminPanelAddStories : AppRoutesPaths.AdvertisingAddStories)}
        className='w-[252px]'
      >
        Добавить сторис
      </UiButton>
      <DataTable
        value={stories.length > 0 ? stories : []}
        showGridlines
        className={cn('bg-white text-base')}
        style={{ fontSize: '12px' }}
        removableSort
        sortField='name'
        sortOrder={1}
        emptyMessage='Нет данных'
        loading={loading === LoadState.Loading}
      >
        <Column
          field='name'
          header='Название'
          sortable
          body={(item) =>
            nameBodyTemplateWithIcon(
              item,
              isAdmin ? AppRoutesPaths.AdminPanelEditStories : AppRoutesPaths.AdvertisingEditStories
            )
          }
        />
        <Column field='tag' header='Тег' sortable />
        <Column field='sort' header='Сортировка' sortable />
        {!isAdmin && (
          <Column
            field='active'
            header='Сайт'
            body={(item) => checkBoxBodyTemplate(item, () => onToggleActive(item, logic.editStory))}
          />
        )}
        <Column
          field=''
          header=''
          body={(item) =>
            buttonBodyTemplate({
              item: item,
              path: isAdmin ? AppRoutesPaths.AdminPanelEditStories : AppRoutesPaths.AdvertisingEditStories,
              onDelete: () => onDelete(item.id),
            })
          }
        />
      </DataTable>
    </Layout>
  )
})

export default StoriesPage
