import { ApiServicesMeasures, ApiServicesTypes, ApiServicesUnits, ApiTicket, Park } from '@@types/apiLogicTypes'
import { useBoolState } from '@hooks/commonHooks'
import { useInput } from '@hooks/useInput'
import { separateHoursAndMinutes } from '@utils/separateHoursAndMinutes'

import { useEffect, useState } from 'react'

import { DropdownChangeEvent } from 'primereact/dropdown'
import { TicketForm } from '../TicketForm/TicketForm'

export type TicketContainerProps = {
  title: string
  initialValue?: ApiTicket
  types: ApiServicesTypes[]
  measures: ApiServicesMeasures[]
  sendForm: (form: {
    weekday_price: number
    category_id: number
    weekend_price: number
    name: string
    park_id: number
    active: number
    sort: number
    id: number
    measure_id: number
    unit_id: number
  }) => void
  selectedPark: Park
  unitsStore: ApiServicesUnits[]
  isAdmin?: boolean
}
export const TicketContainer = ({
  title,
  initialValue,
  types,
  measures,
  sendForm,
  selectedPark,
  unitsStore,
  isAdmin = false,
}: TicketContainerProps) => {
  const name = useInput(initialValue?.name ?? '')
  const priceWorkday = useInput(String(initialValue?.weekday_price ?? ''))
  const priceWeekday = useInput(String(initialValue?.weekend_price ?? ''))
  const sort = useInput(String(initialValue?.sort ?? ''))
  const useBoolStateCheckbox = useBoolState(Boolean(initialValue?.active) ?? false)

  const [selectedMetering, setSelectedMetering] = useState<ApiServicesUnits | null>(initialValue?.measure ?? null)
  const [selectedValue, setSelectedValue] = useState<ApiServicesMeasures | null>(
    initialValue ? unitsStore?.find((e) => e.id === initialValue?.unit.id) ?? null : null
  )
  const [units, setUnits] = useState<ApiServicesMeasures[] | null>(null)

  const objSeparateUnits = separateHoursAndMinutes(unitsStore ?? [])
  const [selectedCategory, setSelectedCategory] = useState<ApiServicesTypes | null>(initialValue?.category ?? null)

  function changeUnits(event: DropdownChangeEvent) {
    setSelectedMetering(event.value)
  }
  function callbackForm() {
    if (selectedCategory && selectedValue && selectedMetering && selectedPark) {
      sendForm({
        name: name.value,
        weekday_price: Number(priceWorkday.value),
        weekend_price: Number(priceWeekday.value),
        sort: Number(sort.value),

        park_id: selectedPark.id,
        category_id: selectedCategory.id,
        measure_id: selectedMetering.id,
        unit_id: selectedValue.id,
        id: initialValue?.id ?? -1,
        active: Number(useBoolStateCheckbox[0]),
      })
    }
  }

  useEffect(() => {
    if (!selectedMetering) {
      return
    }

    if (selectedMetering.text === 'Часы') {
      setUnits(objSeparateUnits.hours)
    } else if (selectedMetering.text === 'Минуты') {
      setUnits(objSeparateUnits.minutes)
    } else if (selectedMetering.text === 'За ребенка') {
      setUnits(unitsStore?.filter((e) => e.text === 'человек'))
      setSelectedValue(unitsStore?.filter((e) => e.text === 'человек')[0] ?? null)
    }
  }, [selectedMetering])
  return (
    <TicketForm
      title={title}
      name={name}
      sort={sort}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      types={types}
      measures={measures}
      selectedMetering={selectedMetering}
      changeUnits={changeUnits}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
      priceWorkday={priceWorkday}
      priceWeekday={priceWeekday}
      units={units}
      sendForm={callbackForm}
      useBoolStateCheckbox={useBoolStateCheckbox}
      isAdmin={isAdmin}
    />
  )
}
