import { ServicesContainer } from '@components/ui/ServicesContainer/ServicesContainer'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

export const AdminAddServices = observer(() => {
  const {
    services: { units: unitsStore, measures, types },

    auth: { selectedPark },
    rooms: { rooms },
  } = useStore()
  const logic = useLogic()

  const context = useContext(ToastContext)

  async function sendForm(form: FormData) {
    form.delete('sort')
    form.delete('price')
    form.delete('park_id')
    form.delete('active')
    const { status, errors } = await logic.createAdminService(form)

    if (status) {
      navigateTo(AppRoutesPaths.AdminPanelServices)
    } else {
      context?.toastShowHandler({ status, errors })
    }
  }

  const title = 'Создать услуги'
  const subtitle = 'Создание услуги'

  return (
    <>
      {selectedPark && rooms && (
        <ServicesContainer
          subtitle={subtitle}
          isAdmin
          title={title}
          rooms={rooms}
          types={types ?? []}
          measures={measures ?? []}
          callbackForm={sendForm}
          selectedPark={selectedPark}
          unitsStore={unitsStore ?? []}
        />
      )}
    </>
  )
})
