import { ServicesTable } from '@components/ui/ContainerTable/ServicesTable'
import { SettingsHeaderBtn } from '@components/ui/SettingsHeaderBtn/SettingsHeaderBtn'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ArchiveContext } from '@context/archiveContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const ServiceScreen = observer(() => {
  const logic = useLogic()
  const {
    services: { services },
    auth: { selectedPark },
  } = useStore()

  const onAdd = () => {
    navigateTo(AppRoutesPaths.SettingsAddService)
  }

  const onArchive = () => {
    navigateTo(AppRoutesPaths.SettingsArchiveService)
  }

  const location = useLocation()
  const inArchive = location && location.pathname === AppRoutesPaths.SettingsArchiveService

  useEffect(() => {
    if (inArchive) logic.loadServices(true)
    else logic.loadServices()
    logic.loadRooms()
  }, [location, selectedPark])

  return (
    <Layout title='Услуги' scrollable={true}>
      <ArchiveContext.Provider value={{ inArchive, onArchive }}>
        <div className={cn('flex flex-col h-full gap-[24px] bg-white overflow-scroll', 'no-scroll')}>
          <div className='flex flex-col h-full overflow-x-auto rounded-[3px] gap-[16px]'>
            <SettingsHeaderBtn textAdd={'Услугу'} onClickAdd={onAdd} textArchive={'Услуги'} />
            <ServicesTable services={services} />
          </div>
        </div>
      </ArchiveContext.Provider>
    </Layout>
  )
})
