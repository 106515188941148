import { ApiHoliday } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'

import { computed, observable } from 'mobx'

export class HolidayStore {
  @observable
  mainLoading = LoadState.Initial

  @observable
  holidays: ApiHoliday[] | [] = []

  @observable
  adminHolidays: Pick<ApiHoliday, 'name' | 'has_animator' | 'shows' | 'id'>[] | [] = []

  @observable
  loadById = LoadState.Initial

  @observable
  selectedUpdateHoliday: ApiHoliday | null = null

  @computed
  get activeHolidays() {
    return this.holidays.filter((e) => e.active === 1)
  }

  @computed
  get crmHolidays() {
    return this.holidays.filter((e) => e.show_in_crm === 1)
  }

  resetUpdateHoliday = () => {
    this.selectedUpdateHoliday = null
  }

  @observable
  addHoliday = (product: ApiHoliday) => {
    this.holidays = [...this.holidays, product]
  }

  @observable
  addAdminHoliday = (product: ApiHoliday) => {
    this.adminHolidays = [...this.adminHolidays, product]
  }

  updateHoliday(response: ApiHoliday) {
    if (this.holidays) {
      const index = this.holidays.findIndex((item) => item.id === response.id)
      if (index !== -1) {
        this.holidays = [...this.holidays.map((item) => (item.id === response.id ? response : item))]
      }
    } else {
      this.holidays = [response]
    }
  }

  updateAdminHoliday(response: ApiHoliday) {
    if (this.adminHolidays) {
      const index = this.adminHolidays.findIndex((item) => item.id !== response.id)
      if (index >= 0) {
        this.adminHolidays = [...this.adminHolidays.map((item) => (item.id === response.id ? response : item))]
      }
    } else {
      this.adminHolidays = [response]
    }
  }
}
