import { UiButton } from '@components/ui/UiButton/UiButton'
import { useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { TCashboxAction } from '../IntegrationPage'

interface IControlPanel {
  cashManipulateCallback: (flag: boolean, action: TCashboxAction) => void
}

export const ControlPanel: FC<IControlPanel> = observer(({ cashManipulateCallback }) => {
  const {
    auth: { user },
  } = useStore()

  const minusCashboxHandler = async (type: 2 | 3 | 4) => {
    cashManipulateCallback(true, type)
  }

  return (
    <div className='flex gap-4 z-10'>
      <UiButton className='w-[184px]' color='blue' onClick={() => minusCashboxHandler(4)}>
        РКО
      </UiButton>
      <UiButton className='w-[184px]' onClick={() => minusCashboxHandler(3)}>
        Авансовый отчет
      </UiButton>
      <UiButton
        className='w-[184px]'
        color='yellow'
        onClick={() => minusCashboxHandler(2)}
        disabled={!user?.roles.some((e) => e.name === 'manager')}
      >
        Инкассация
      </UiButton>
    </div>
  )
})
