import { ApiAttraction, ApiCreateAttractionForm, Park } from '@@types/apiLogicTypes'
import { useBoolState } from '@hooks/commonHooks'
import { useInput } from '@hooks/useInput'

import { useEffect, useState } from 'react'

import { itemPhotoFile } from '@components/Settings/ServicePhotoItem'
import { convertingImgForFile } from '@utils/convertingImgForFile'
import { AttractionForm } from '../AttractionForm/AttractionForm'

export type AttractionContainerProps = {
  title: string
  initialValue?: ApiAttraction | null
  sendForm: (form: ApiCreateAttractionForm) => void
  selectedPark: Park
}
export const AttractionContainer = ({ title, initialValue, sendForm, selectedPark }: AttractionContainerProps) => {
  const name = useInput(initialValue?.name ?? '')
  const desciption = useInput(initialValue?.description ?? '')
  const [files, setFiles] = useState<itemPhotoFile[]>(convertingImgForFile(initialValue?.images))

  const useBoolStateCheckbox = useBoolState(Boolean(initialValue?.active) ?? false)
  const [isDisabledFile, setDisabledFile] = useState<boolean>(false)

  const [filesDeleteId, setFilesDeleteId] = useState<number[]>([])

  function addFile(item: itemPhotoFile) {
    setFiles((prev) => [...prev, item])
  }
  function callbackForm() {
    if (selectedPark) {
      sendForm({
        name: name.value,
        park_id: selectedPark.id,
        id: initialValue?.id,
        active: Number(useBoolStateCheckbox[0]),
        description: desciption.value,
        delete_images: filesDeleteId,
        images: files,
      })
    }
  }
  function onClickDeleteFile(indexFileDelete: number, id?: number) {
    setFiles((prev) => prev.filter((_, index) => index !== indexFileDelete))
    if (id) {
      setFilesDeleteId((prev) => [...prev, id])
    }
  }

  useEffect(() => {
    if (files.length >= 1) {
      setDisabledFile(true)
    } else {
      setDisabledFile(false)
    }
  }, [files])
  return (
    <>
      <AttractionForm
        title={title}
        name={name}
        sendForm={callbackForm}
        useBoolStateCheckbox={useBoolStateCheckbox}
        desciption={desciption}
        isDisabledFile={isDisabledFile}
        setDisabledFile={setDisabledFile}
        files={files}
        onClickDelete={onClickDeleteFile}
        setUploadFile={addFile}
      />
    </>
  )
}
