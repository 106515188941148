import { ApiDiscount, ApiNewsLetter, ApiSale, ApiStories } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { action, observable } from 'mobx'

export class AdvertisingStore {
  @observable
  loading = LoadState.Initial

  @observable
  stories: ApiStories[] = []

  @observable
  sales: ApiSale[] = []

  @observable
  newsletter: ApiNewsLetter[] = []

  @observable
  discounts: ApiDiscount[] = []

  @observable
  addStories = (stories: ApiStories[]) => {
    this.stories = [...stories]
  }

  @action
  updateStories(response: ApiStories) {
    const index = this.stories.findIndex((item) => item.id === response.id)
    if (index !== -1) {
      this.stories = [...this.stories.map((item) => (item.id === response.id ? { ...response } : item))]
    }
  }

  @action
  updateSales(response: ApiSale) {
    const index = this.sales.findIndex((item) => item.id === response.id)
    if (index !== -1) {
      this.sales = [...this.sales.map((item) => (item.id === response.id ? { ...response } : item))]
    }
  }

  @action
  updateNewsletter(response: ApiNewsLetter) {
    const index = this.newsletter.findIndex((item) => item.id === response.id)
    if (index !== -1) {
      this.newsletter = [...this.newsletter.map((item) => (item.id === response.id ? { ...response } : item))]
    }
  }

  @action
  updateDiscount(response: ApiDiscount) {
    const index = this.discounts.findIndex((item) => item.id === response.id)
    if (index !== -1) {
      this.discounts = [...this.discounts.map((item) => (item.id === response.id ? { ...response } : item))]
    }
  }

  @action
  deleteStories = (id: number) => {
    const index = this.stories.findIndex((story) => story.id === id)
    if (index !== -1) {
      this.stories.splice(index, 1)
    }
  }

  @action
  deleteSale = (id: number) => {
    this.sales = this.sales.filter((e) => e.id !== id)
  }

  @action
  deleteNewsLetter = (id: number) => {
    this.newsletter = this.newsletter.filter((e) => e.id !== id)
  }

  @action
  deleteDiscount = (id: number) => {
    this.discounts = this.discounts.filter((e) => e.id !== id)
  }
}
