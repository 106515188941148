import { ApiApplication } from '@@types/apiLogicTypes'
import KanbanBoard from '@components/ui/KanbanBoard/KanbanBoard'
import { ButtonTypes } from '@components/ui/KanbanBoard/KanbanButton/KanbanButton'
import KanbanColumn from '@components/ui/KanbanBoard/KanbanColumn/KanbanColumn'
import KanbanControlPanel from '@components/ui/KanbanBoard/KanbanControlPanel/KanbanControlPanel'
import KanbanTask from '@components/ui/KanbanBoard/KanbanTask/KanbanTask'
import { MainSearch } from '@components/ui/MainSearch/MainSearch'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths } from '@config/navigation'
import { kanbanColors } from '@config/theme'
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { useLogic, useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { SelectButton } from 'primereact/selectbutton'
import { FC, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { generatePath, useNavigate } from 'react-router-dom'
import { Table } from './ Table/Table'
import './styles.css'

export const typeViews = ['В работе', 'Завершенные', 'Таблица']

const ApplicationsPage: FC = observer(() => {
  const logic = useLogic()
  const navigate = useNavigate()

  const [activeApplication, setActiveApplication] = useState<ApiApplication | null>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [isOverButtons, setIsOverButtons] = useState(false)
  const [viewType, setViewType] = useState(typeViews[0])

  useEffect(() => {
    logic.loadApplications()
  }, [])

  const {
    applications: { applications, sortApplications, deleteApplications },
  } = useStore()

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  )

  const newApplications = applications.filter((e) => e.status === 1)
  const recallApplications = applications.filter((e) => e.status === 2)
  const waitForPaymentApplications = applications.filter((e) => e.status === 3)
  const preorderApplications = applications
    .filter((e) => e.status === 5)
    .sort((a, b) => new Date(a.schedule.starts_at).getTime() - new Date(b.schedule.starts_at).getTime())
  const paidApplications = applications
    .filter((e) => e.status === 4)
    .sort((a, b) => new Date(a.schedule.starts_at).getTime() - new Date(b.schedule.starts_at).getTime())
  const deletedApplications = applications.filter((e) => e.status === 1000)
  const finishedApplications = applications.filter((e) => e.status === 100)
  const notRealisedApplications = applications.filter((e) => e.status === 0)

  const sumOfApplication = (applications: ApiApplication[]) => {
    return applications.reduce((sum, currentObject) => {
      const price = currentObject?.price
      return sum + (price ?? 0)
    }, 0)
  }

  function onDragStart(event: DragStartEvent) {
    setIsDragging(true)
    if (event.active.data.current?.type === 'Application') {
      setActiveApplication(event.active.data.current.application)
      return
    }
  }

  function onDragEnd(event: DragEndEvent) {
    setIsDragging(false)

    const { over, active } = event
    const isButton = over?.data?.current?.type === 'Button'

    if (!isButton) {
      const status = over?.data?.current?.application?.status ?? over?.id

      if (status) {
        const array = applications.filter((e) => e.status === Number(status)).map((e, i) => ({ id: e.id, sort: i }))

        logic.updateApplicationsByStatus(status, array)
      }
    } else {
      deleteApplications(event)

      const buttonId = over?.id

      if (buttonId === ButtonTypes.NOT_REALISED) {
        logic.updateApplicationsById(Number(active?.id), 0)
      } else if (buttonId === ButtonTypes.SUCCESFULL) {
        logic.updateApplicationsById(Number(active?.id), 100)
      } else if (buttonId === ButtonTypes.DELETE) {
        logic.updateApplicationsById(Number(active?.id), 1000)
      } else if (buttonId === ButtonTypes.TO_NEW) {
        logic.updateApplicationsById(Number(active?.id), 1)
      }
    }
  }

  function onDragOver(event: DragOverEvent) {
    if (event?.over?.data?.current?.type === 'Button') {
      setIsOverButtons(true)
    } else {
      setIsOverButtons(false)
    }
    sortApplications(event)
  }

  const onEditHandler = (application: ApiApplication) => {
    const path = generatePath(AppRoutesPaths.ConstructorEdit, {
      id: String(application.id),
    })

    navigate(path)
  }

  return (
    <div className='flex flex-col  min-h-full w-full max-w-[1440px] mx-auto'>
      <header className=''>
        <MainSearch />
      </header>

      <main className='flex flex-col h-[calc(100vh_-_82px)] w-full  mt-[14px] bg-white p-[16px]'>
        <header className='flex gap-6 items-center'>
          <UiText className='font-bold text-lg'>Заявки</UiText>
          <SelectButton
            value={viewType}
            onChange={(e) => setViewType(e.value)}
            options={typeViews}
            className='selectButton'
            allowEmpty={false}
          />
        </header>
        {viewType === typeViews[2] ? (
          <Table />
        ) : (
          <DndContext onDragOver={onDragOver} onDragStart={onDragStart} onDragEnd={onDragEnd} sensors={sensors}>
            <div className='relative mt-[16px] flex flex-col h-full justify-between overflow-hidden items-center'>
              {viewType === typeViews[0] ? (
                <KanbanBoard>
                  <KanbanColumn
                    id='1'
                    title='Новые'
                    subtitle={`${newApplications.length} праздников: ${sumOfApplication(newApplications)} ₽`}
                    color={kanbanColors.grey}
                    childArray={newApplications}
                    onDoubleClick={onEditHandler}
                  ></KanbanColumn>
                  <KanbanColumn
                    id='2'
                    title='Перезвонить'
                    subtitle={`${recallApplications.length} праздников: ${sumOfApplication(recallApplications)} ₽`}
                    color={kanbanColors.blue}
                    childArray={recallApplications}
                    onDoubleClick={onEditHandler}
                  ></KanbanColumn>
                  <KanbanColumn
                    id='3'
                    title='Ждем оплату'
                    subtitle={`${waitForPaymentApplications.length} праздников: ${sumOfApplication(
                      waitForPaymentApplications
                    )} ₽`}
                    color={kanbanColors.yellow}
                    childArray={waitForPaymentApplications}
                    onDoubleClick={onEditHandler}
                  ></KanbanColumn>
                  <KanbanColumn
                    id='5'
                    title='Предоплаченные'
                    subtitle={`${preorderApplications.length} праздников: ${sumOfApplication(preorderApplications)} ₽`}
                    color={kanbanColors.violet}
                    childArray={preorderApplications}
                    onDoubleClick={onEditHandler}
                  ></KanbanColumn>
                  <KanbanColumn
                    id='4'
                    title='Оплаченные'
                    subtitle={`${paidApplications.length} праздников: ${sumOfApplication(paidApplications)} ₽`}
                    color={kanbanColors.green}
                    childArray={paidApplications}
                    onDoubleClick={onEditHandler}
                  ></KanbanColumn>
                </KanbanBoard>
              ) : (
                <KanbanBoard>
                  <KanbanColumn
                    id='100'
                    title='Завершенные'
                    subtitle={`${finishedApplications.length} праздников: ${sumOfApplication(finishedApplications)} ₽`}
                    color={kanbanColors.green}
                    childArray={finishedApplications}
                  ></KanbanColumn>

                  <KanbanColumn
                    id='0'
                    title='Нереализованные'
                    subtitle={`${notRealisedApplications.length} праздников: ${sumOfApplication(
                      notRealisedApplications
                    )} ₽`}
                    color={kanbanColors.grey}
                    childArray={notRealisedApplications}
                  ></KanbanColumn>

                  <KanbanColumn
                    id='1000'
                    title='Удаленные'
                    subtitle={`${deletedApplications.length} праздников: ${sumOfApplication(deletedApplications)} ₽`}
                    color={kanbanColors.red}
                    childArray={deletedApplications}
                  ></KanbanColumn>
                </KanbanBoard>
              )}
              <KanbanControlPanel typeView={viewType} isVisible={isDragging} />
            </div>
            {activeApplication &&
              createPortal(
                <DragOverlay>
                  <KanbanTask
                    color={kanbanColors.grey}
                    application={activeApplication}
                    className={`gradient transition-transform ${isOverButtons && 'scale-50 opacity-50'}`}
                    disable={false}
                  />
                </DragOverlay>,
                document.body
              )}
          </DndContext>
        )}
      </main>
    </div>
  )
})

export default ApplicationsPage
