import { ApiUser } from '@@types/apiLogicTypes'
import { Loader } from '@components/ui/Loader/Loader'
import { useLogic } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import NotesForm from '@modules/Schedule/Constructor/NotesForm/NotesForm'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Main } from './Main/Main'

const ContactPage = () => {
  const [contact, setContact] = useState<ApiUser | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const updateUserHandler = (user: ApiUser) => {
    setContact(user)
  }

  const logic = useLogic()
  const { id } = useParams()

  useEffect(() => {
    if (!id) return
    logic.loadClientById(+id).then((data) => {
      data.data && setContact(data.data)
      setIsLoading(false)
    })
  }, [])

  return (
    <Layout className='!p-0'>
      {isLoading && <Loader />}
      {!contact && !isLoading && <p>Контакт не найден</p>}
      {contact && !isLoading && (
        <div className='grid grid-cols-[2fr_1fr] h-full bg-grey-hover w-full gap-3 relative overflow-hidden'>
          <Main contact={contact} updateUserHandler={updateUserHandler} />
          <NotesForm initialState={contact} />
        </div>
      )}
    </Layout>
  )
}

export default ContactPage
