import { ApiFormCreateHoliday } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { PartyContainer } from '@components/ui/PartyContainer/PartyContainer'
import { SkalalandSpiner } from '@components/ui/SkalalandSpiner/SkalalandSpiner'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const AdminUpdateParty = observer(() => {
  const title = 'Редактирование Праздника'
  const logic = useLogic()
  const {
    rooms: { rooms },
    auth: { selectedPark },
    services: { entertaimentPrograms, adminShows },
    product: { products },
    holiday: { resetUpdateHoliday, loadById, selectedUpdateHoliday },
  } = useStore()
  const { id } = useParams()
  const navigate = useNavigate()
  const context = useContext(ToastContext)

  useEffect(() => {
    logic.loadAdminShowsServices()
    logic.loadEntertaimentProgramsServices()
    logic.loadProduct()
  }, [])

  async function sendForm(form: ApiFormCreateHoliday) {
    if (selectedPark) {
      const ticketsForm: ApiFormCreateHoliday = {
        name: form.name ?? '',
        has_animator: form.has_animator,
        shows: form.shows,
      }
      ticketsForm.id = id

      const { status, errors } = await logic.editAdminHoliday(ticketsForm)
      if (status) {
        navigateTo(AppRoutesPaths.AdminPanelParty)
      } else {
        context?.toastShowHandler({ status, errors })
      }
    }
  }

  useEffect(() => {
    loadRoom()

    return () => resetUpdateHoliday()
  }, [])

  async function loadRoom() {
    if (!id) {
      navigateToRoom()
      return
    }

    const response = await logic.loadAdminHolidayById(id)

    if (!response) {
      navigateToRoom()
      return
    }
  }

  function navigateToRoom() {
    navigate(AppRoutesPaths.AdminPanelParty)
  }
  if (loadById === LoadState.Loading) {
    return <SkalalandSpiner />
  }

  return (
    <>
      {selectedUpdateHoliday && (
        <PartyContainer
          initialValue={selectedUpdateHoliday}
          shows={adminShows}
          entertaimentPrograms={entertaimentPrograms}
          products={products}
          callbackForm={sendForm}
          partyRooms={rooms ?? []}
          title={title}
          isAdmin
        />
      )}
    </>
  )
})
