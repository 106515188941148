import { ApiOrder, ApiPayment, ApiTicket } from '@@types/apiLogicTypes'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { Calendar } from 'primereact/calendar'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { DataTable } from 'primereact/datatable'
import { Row } from 'primereact/row'
import { useContext, useEffect, useState } from 'react'

type TTableObject = {
  title: string
  count: number
  price: number
}

type TPaymentsTableObject = {
  cash: number
  card: number
  online: number
  bonuses: number
}

export const TotalReport = observer(() => {
  const logic = useLogic()
  const [loading, setLoading] = useState(true)

  const [dateFrom, setDateFrom] = useState<Date>(new Date())
  const [dateTo, setDateTo] = useState<Date>(new Date())
  const [payments, setPayments] = useState<TTableObject[]>([])
  const [paymentType, setPaymentsType] = useState<TPaymentsTableObject>({
    cash: 0,
    card: 0,
    online: 0,
    bonuses: 0,
  })

  const context = useContext(ToastContext)

  const {
    auth: { selectedPark },
  } = useStore()

  const loadData = async () => {
    setLoading(true)

    const response = await logic.loadTotalReport(
      moment(dateFrom, 'YYYY-MM-DD').toDate().toLocaleDateString('en-CA'),
      moment(dateTo, 'YYYY-MM-DD').toDate().toLocaleDateString('en-CA')
    )

    const map = new Map()

    if (response && response?.data?.orders?.length > 0) {
      response.data?.orders.forEach((element: ApiOrder & { tickets: ApiTicket[]; payments: ApiPayment[] }) => {
        setPaymentsType((prev) => ({ ...prev, bonuses: prev.bonuses + element.bonus_sum }))
        if (element.payments.length > 0)
          element.payments.forEach((p) => {
            if (p.status !== 2) return
            if (p.type === 0) setPaymentsType((prev) => ({ ...prev, cash: prev.cash + p.sum }))
            else if (p.type === 1) setPaymentsType((prev) => ({ ...prev, card: prev.card + p.sum }))
            else if (p.type === 7) setPaymentsType((prev) => ({ ...prev, online: prev.online + p.sum }))
          })

        if (element.holiday)
          if (!map.has(element.holiday.name))
            map.set(element.holiday.name, {
              price: element.payments.filter((e) => e.status === 2).reduce((sum, item) => sum + item.sum, 0),
              count: 1,
            })
          else
            map.set(element.holiday.name, {
              price:
                map.get(element.holiday.name).price +
                element.payments.filter((e) => e.status === 2).reduce((sum, item) => sum + item.sum, 0),
              count: ++map.get(element.holiday.name).count,
            })
        else if (element.tickets.length > 0)
          element.tickets.forEach((t) => {
            if (!map.has(t.name)) map.set(t.name, { price: t.discount_price ?? t.price, count: t.count })
            else
              map.set(t.name, {
                price: map.get(t.name).price + (t.discount_price ?? t.price),
                count: map.get(t.name).count + t.count,
              })
          })
        if (!element.holiday && element.services.length > 0) {
          element.services.forEach((s) => {
            if (!map.has(s.name)) map.set(s.name, { price: s.discount_price ?? s.price, count: s.count })
            else
              map.set(s.name, {
                price: map.get(s.name).price + (s.discount_price ?? s.price),
                count: map.get(s.name).count + s.count,
              })
          })
        }
      })

      setPayments(
        Array.from(map, ([key, value]) => ({
          name: key, // Изменили key на name
          ...value,
        }))
      )
    }

    setLoading(false)
  }

  useEffect(() => {
    if (!selectedPark) return
    loadData()
  }, [selectedPark])

  const onClickHandler = () => {
    if (!dateFrom || !dateTo || dateTo < dateFrom)
      context?.toastShowHandler({ status: false, errors: 'Некорректное заполнение дат' })
    else loadData()
  }

  const countTotal = () => payments.reduce((sum, item) => sum + item.count, 0)
  const sumTotal = () => payments.reduce((sum, item) => sum + item.price, 0)

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer='Итого:' colSpan={1} footerStyle={{ textAlign: 'right' }} />
        <Column footer={countTotal} />
        <Column footer={sumTotal} />
      </Row>
    </ColumnGroup>
  )

  return (
    <Layout title='Отчет по сотрудникам'>
      <div className='flex gap-4'>
        <Calendar
          value={dateFrom}
          onChange={(e) => setDateFrom(e.value!)}
          showIcon={false}
          dateFormat='dd.mm.yy'
          locale='ru'
          readOnlyInput={true}
          className={`worker shadow-none w-[85px]`}
          inputClassName='shadow-none border-green'
          placeholder='от'
        />

        <Calendar
          value={dateTo}
          onChange={(e) => setDateTo(e.value!)}
          showIcon={false}
          dateFormat='dd.mm.yy'
          locale='ru'
          readOnlyInput={true}
          className={`worker shadow-none w-[85px]`}
          inputClassName='shadow-none border-green'
          placeholder='до'
        />

        <UiButton onClick={onClickHandler}>Сформировать отчет</UiButton>
      </div>

      <DataTable
        value={payments}
        loading={loading}
        showGridlines
        size={'small'}
        filterLocale='ru'
        className={'bg-white text-base ui-paginator-table'}
        style={{ fontSize: '12px' }}
        removableSort
        emptyMessage='Данные не найдены'
        paginator
        rows={20}
        scrollable
        footerColumnGroup={footerGroup}
      >
        <Column header='Номенклатура' field='name' />
        <Column header='Количество' field='count' />
        <Column header='Сумма' field='price' />
      </DataTable>

      <DataTable
        value={[paymentType]}
        loading={loading}
        showGridlines
        size={'small'}
        filterLocale='ru'
        className={'bg-white text-base ui-paginator-table'}
        style={{ fontSize: '12px' }}
        removableSort
        emptyMessage='Данные не найдены'
        rows={1}
        scrollable
      >
        <Column header='Наличный расчет' field='cash' />
        <Column header='Безналичный расчет' field='card' />
        <Column header='Оплачено бонусами' field='bonuses' />
        <Column header='Оплачено с сайта' field='online' />
      </DataTable>
    </Layout>
  )
})
