import { ApiApplication } from '@@types/apiLogicTypes'
import { UiText } from '@components/ui/UiText/UiText'
import { kanbanColors } from '@config/theme'
import { useDroppable } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import { FC, useMemo } from 'react'
import KanbanTask from '../KanbanTask/KanbanTask'

type KanbanColumnProps = {
  id: string
  title: string
  subtitle?: string
  childArray: any[]
  onDoubleClick?: (event: ApiApplication) => void
  color?: (typeof kanbanColors)[keyof typeof kanbanColors]
}

const KanbanColumn: FC<KanbanColumnProps> = ({
  id,
  title,
  subtitle,
  color = kanbanColors.grey,
  childArray,
  onDoubleClick,
}) => {
  const isDropDisabled = id === '4' || id === '5'

  const { setNodeRef } = useDroppable({
    id: id,
    data: {
      type: 'Column',
    },
  })

  const applicationIds = useMemo(() => {
    return childArray.map((app) => app.id)
  }, [childArray])

  return (
    <div className='w-full flex flex-col items-center gap-2 mb-2'>
      <header
        className={`w-full flex flex-col gap-2 items-center pb-2 border-solid border-0 border-b-4 sticky top-0 bg-white z-10`}
        style={{ borderColor: color.primary }}
      >
        <UiText size='md' weight='bold'>
          {title}
        </UiText>
        <UiText className='text-grey font-normal text-center leading-tight'>{subtitle}</UiText>
      </header>
      <div className={`flex flex-col gap-2 w-full h-full`} ref={!isDropDisabled ? setNodeRef : null}>
        {!isDropDisabled && (
          <SortableContext items={applicationIds}>
            {childArray.map((e) => (
              <KanbanTask key={e.id} color={color} application={e} onDoubleClick={onDoubleClick} disable={false} />
            ))}
          </SortableContext>
        )}
        {isDropDisabled &&
          childArray.map((e) => (
            <KanbanTask key={e.id} color={color} application={e} onDoubleClick={onDoubleClick} disable={true} />
          ))}
      </div>
    </div>
  )
}

export default KanbanColumn
