export const Checkbox = ({ useBoolState }: { useBoolState?: any }) => {
  if (!useBoolState) {
    return <></>
  }
  const [selected, select, unSelect] = useBoolState

  const onSelect = () => (selected ? unSelect() : select())
  return (
    <span onClick={onSelect} className='cursor-pointer'>
      {selected ? (
        <svg width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g clipPath='url(#clip0_426_6778)'>
            <rect x='0.5' y='1' width='20' height='20' rx='3' fill='#39B54A' />
            <path
              d='M15 7.5L8.8125 14.5L6 11.3182'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
          <rect x='0.5' y='1' width='20' height='20' rx='3' stroke='#39B54A' />
          <defs>
            <clipPath id='clip0_426_6778'>
              <rect x='0.5' y='1' width='20' height='20' rx='3' fill='white' />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g clipPath='url(#clip0_426_6420)'>
            <rect x='0.5' y='1' width='20' height='20' rx='3' fill='white' />
          </g>
          <rect x='0.5' y='1' width='20' height='20' rx='3' stroke='#39B54A' />
          <defs>
            <clipPath id='clip0_426_6420'>
              <rect x='0.5' y='1' width='20' height='20' rx='3' fill='white' />
            </clipPath>
          </defs>
        </svg>
      )}
    </span>
  )
}
