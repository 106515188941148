import { LoadState } from '@@types/loadState'
import { ReactNode, useEffect } from 'react'

import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { setNavigate } from '@config/navigation'
import { appColors } from '@config/theme'
import { useStore } from '@hooks/storeHook'
import { ProgressSpinner } from 'primereact/progressspinner'

export const RootLoader = observer(({ children }: { children: ReactNode }) => {
  const { rootLoading } = useStore()

  const navigate = useNavigate()

  useEffect(() => {
    setNavigate(navigate)
  }, [])

  if (rootLoading === LoadState.Error) {
    return (
      // @ts-ignore
      <div style={styles.container}>
        <text className='text-white'>{'Ошибка на сервере :('}</text>
      </div>
    )
  }

  if (rootLoading === LoadState.Loading) {
    return (
      //@ts-ignore
      <div style={styles.container}>
        <ProgressSpinner />
      </div>
    )
  }

  if (rootLoading === LoadState.Success) return <>{children}</>
  else return null
})

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: appColors.white,
    gap: '24px',
  },
}
