import { ApiTicket, ApiValidationResponse } from '@@types/apiLogicTypes'

import { Checkbox } from '../Checkbox/Checkbox'

import logo from '@assets/images/skala.png'
import { AppRoutesPaths } from '@config/navigation'
import { ArchiveContext } from '@context/archiveContext'
import { ToastContext } from '@context/toastContext'
import { useLogic } from '@hooks/storeHook'
import { useContext, useState } from 'react'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { UiText } from '../UiText/UiText'
import { TD } from './ContainerTable'
import { TableButtons } from './TableButtons'

export type TicketTableItemProps = {
  ticket: ApiTicket
  isAdmin?: boolean
}

export const TicketTableItem = ({ ticket, isAdmin }: TicketTableItemProps) => {
  const logic = useLogic()
  const navigate = useNavigate()

  const archiveContext = useContext(ArchiveContext)
  const toastContext = useContext(ToastContext)

  const [isActive, setActive] = useState<boolean>(Boolean(ticket.active))

  function onClickEdit() {
    let path: string = ''

    if (isAdmin) {
      path = generatePath(AppRoutesPaths.AdminPanelTicketsUpdate, {
        id: String(ticket.id),
      })
    } else {
      path = generatePath(AppRoutesPaths.SettingTicketUpdate, {
        id: String(ticket.id),
      })
    }

    navigate(path)
  }
  async function onChangeActive() {
    let response: ApiValidationResponse = { status: false }

    if (isAdmin) {
      response = await logic.updateAdminTickets({
        ...ticket,
        active: Number(!isActive),
      })
    } else {
      response = await logic.updateTicket({
        ...ticket,
        active: Number(!isActive),
      })
    }

    if (response) {
      setActive((prev) => !prev)
    }
  }

  async function onDelete() {
    if (ticket.deleted === 0) {
      const { status, errors } = await logic.deleteTicket(ticket.id)
      if (!status) {
        toastContext?.toastShowHandler({ status, errors })
      } else logic.loadTicket()
    } else {
      const { status, errors } = await logic.updateTicket({
        id: ticket.id,
        deleted: 0,
      })

      if (status) {
        logic.loadTicket(true)
      } else {
        toastContext?.toastShowHandler({ status, errors })
      }
    }
  }

  return (
    <>
      <tr>
        <TD cursorPointer>
          <div className='flex justify-between items-center'>
            <UiText onClick={onClickEdit}>{ticket.name}</UiText>
            {ticket.group_id && <img src={logo} alt='Создано администратором' className='w-3 h-3 ml-2' />}
          </div>
        </TD>
        <TD>
          <UiText>{ticket?.category?.name}</UiText>
        </TD>
        {!isAdmin && (
          <>
            <TD>
              <UiText>{`${ticket?.weekday_price} ₽`}</UiText>
            </TD>
            <TD>
              <UiText>{`${ticket?.weekend_price} ₽`}</UiText>
            </TD>
          </>
        )}
        <TD>
          <UiText>{ticket?.unit.text}</UiText>
        </TD>

        {!isAdmin && !archiveContext?.inArchive && (
          <TD center>
            <Checkbox useBoolState={[isActive, onChangeActive, onChangeActive]}></Checkbox>
          </TD>
        )}
        <TD center>
          <TableButtons
            onClickEdit={!archiveContext?.inArchive ? onClickEdit : undefined}
            onClickBox={!isAdmin ? onDelete : undefined}
          />
        </TD>
      </tr>
    </>
  )
}
