import { ToastContext } from '@context/toastContext'
import { useLogic } from '@hooks/storeHook'
import { useInput } from '@hooks/useInput'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { useContext } from 'react'
const Authorization = () => {
  const logic = useLogic()
  const context = useContext(ToastContext)

  const email = useInput()
  const password = useInput()

  function onSendAuthorization() {
    if (email.value.length > 0 && password.value.length > 0) {
      logic.onSendAuthorization(email.value, password.value)
    } else {
      context?.toastShowHandler({ status: false, errors: 'Заполните все поля' })
    }
  }
  return (
    <div className='w-[100%] h-[100vh] flex-col items-center justify-center flex bg-grey-200'>
      <div className='p-[40px] px-[60px] bg-white rounded-[24px] flex flex-col gap-[20px] max-w-[600px] w-[100%]'>
        <div className='flex gap-[20px] justify-center items-center'>
          <div className='text-center w-[100%]'>Авторизация</div>
        </div>
        <div className='p-inputgroup flex-1'>
          <InputText {...email} placeholder='email ' />
        </div>
        <div className='p-inputgroup flex-1'>
          <Password {...password} feedback={false} tabIndex={1} placeholder='пароль' inputClassName='auth' />
        </div>
        <div className='w-[100%]'>
          <Button onClick={onSendAuthorization} className='w-[100%] text-center justify-center'>
            Вход
          </Button>
        </div>
      </div>
    </div>
  )
}
export default Authorization
