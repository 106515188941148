import { ApiServicesCreateFormImg, ImagePark } from '@@types/apiLogicTypes'
import { itemPhotoFile } from '@components/Settings/ServicePhotoItem'

export const getImagesServices = (images?: ApiServicesCreateFormImg[] | ImagePark[]): itemPhotoFile[] => {
  if (!images) {
    return []
  }
  const photoName = 'Фото '
  const newArray = images.map((item, index) => {
    const name = `${photoName}` + (index + 1)
    return {
      path: item.path,
      name: name,
      id: item.id,
    }
  })

  return newArray
}
