import { dateBodyTemplate } from '@components/ui/ColumnContents/ColumnContents'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths } from '@config/navigation'
import { Column } from 'primereact/column'
import { DataTable, DataTableRowClickEvent } from 'primereact/datatable'
import { FC } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { IMain } from '../Main'

export const BonusesTable: FC<Pick<IMain, 'contact'>> = ({ contact }) => {
  const navigate = useNavigate()

  const onDoubleClick = (e: DataTableRowClickEvent) => {
    if (e.data.payment_id) {
      const path = generatePath(AppRoutesPaths.TicketSaleEdit, {
        id: String(e.data.payment_id),
      })

      navigate(path)
    }
  }

  return (
    <main className='flex flex-col gap-[10px]'>
      <UiText className='text-lg font-bold h-[36px] content-center'>История начислений</UiText>
      <DataTable
        value={contact.bonus_logs}
        showGridlines
        className='bg-white text-base'
        style={{ fontSize: '12px' }}
        removableSort
        sortField='name'
        sortOrder={1}
        emptyMessage='Нет данных'
        rowClassName={(e) => (e.payment_id ? 'cursor_pointer' : '')}
        onRowDoubleClick={onDoubleClick}
      >
        <Column
          field='created_at'
          header='Дата'
          dataType='date'
          body={(e) => dateBodyTemplate(e.created_at)}
          sortable
        />
        <Column field='sum' header='Сумма' sortable body={(e) => sumBodyTemplate(+e.sum)} />
        <Column field='text' header='Название' sortable />
      </DataTable>
    </main>
  )
}

const sumBodyTemplate = (sum: number) => {
  return <UiText className={`${sum < 0 && '!text-red'}`}>{sum}</UiText>
}
