import { ApiValidationError } from '@@types/apiLogicTypes'

export function concatErrorMessages(errors: ApiValidationError): string {
  const concatenatedErrors: string[] = []

  for (const field in errors) {
    const fieldErrors = errors[field]
    concatenatedErrors.push(fieldErrors.join(', '))
  }

  return concatenatedErrors.join(' ')
}
