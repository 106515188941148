const times = [
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
]

export const holidayDurations = [
  { title: '1 час', minutes: 60 },
  { title: '1 час 30 минут', minutes: 90 },
  { title: '2 часа', minutes: 120 },
  { title: '2 часа 30 минут', minutes: 150 },
  { title: '3 часа', minutes: 180 },
  { title: '3 часа 30 минут', minutes: 210 },
  { title: '4 часа', minutes: 240 },
  { title: '4 часа 30 минут', minutes: 270 },
  { title: '5 часов', minutes: 300 },
]

export const parseTimeFromText = (str: string) => {
  const [num, unit] = str.split(' ')

  const amount = parseInt(num)

  if (unit === 'минут') {
    return amount
  } else if (unit === 'час' || unit === 'часа' || unit === 'часов') {
    return amount * 60
  } else {
    return NaN
  }
}

export default times
