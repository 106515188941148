export const tableNameTicket = ['Название', 'Категория', 'Стоимость в будни', 'Стоимость в выходные', 'Измерение']

export const tableNameTicketAdmin = ['Название', 'Категория', 'Измерение']

export const tableNameAttraction = ['Название', 'Сортировка']
export const tableNameRooms = ['Фото', 'Название', 'Категория', 'Вместимость', 'Цвет']

export const tableNameHoliday = ['Название', 'Цена будни', 'Цена выходные', 'Кол-во билетов']

export const colorOptions = [{ color: '#00B4D2' }, { color: '#F8CA46' }, { color: '#39B54A' }]
