export const createFormData = (formDataValues: Record<string, any>): FormData => {
  const formData = new FormData()

  if (!formDataValues) {
    return formData
  }

  for (const [key, value] of Object.entries(formDataValues)) {
    addToFormData(formData, key, value)
  }

  return formData
}

const addToFormData = (formData: FormData, key: string, value: any) => {
  if (value !== undefined && value !== null) {
    formData.append(key, String(value))
  }
}
