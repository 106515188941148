import { ApiRoom } from '@@types/apiLogicTypes'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths } from '@config/navigation'
import { ArchiveContext } from '@context/archiveContext'
import { ToastContext } from '@context/toastContext'
import { useLogic } from '@hooks/storeHook'
import { Checkbox } from '@ui/Checkbox/Checkbox'
import { IconElement } from '@ui/IconElement/IconElement'
import { createFormData } from '@utils/createDataForm'
import { useContext, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { TD } from './ContainerTable'
import { TableButtons } from './TableButtons'

export type RoomTableItemProps = { item: ApiRoom }

export const RoomTableItem = ({ item }: RoomTableItemProps) => {
  const logic = useLogic()
  const navigate = useNavigate()

  const archiveContext = useContext(ArchiveContext)
  const toastContext = useContext(ToastContext)

  const [isActive, setActive] = useState<boolean>(Boolean(item.active))

  function onClickEdit() {
    const path = generatePath(AppRoutesPaths.SettingsEditRoom, {
      id: String(item.id),
    })
    navigate(path)
  }

  async function onChangeActive() {
    const form = new FormData()
    form.append('active', String(Number(!isActive)))

    const response = await logic.editRoom(form, item.id)
    if (response) {
      setActive((prev) => !prev)
    }
  }

  async function onDelete() {
    if (item.deleted === 0) {
      const { status, errors } = await logic.deleteRoom(item.id)
      if (!status) {
        toastContext?.toastShowHandler({ status, errors })
      } else logic.loadRooms()
    } else {
      const { status, errors } = await logic.editRoom(createFormData({ deleted: 0 }), item.id)
      if (!status) {
        toastContext?.toastShowHandler({ status, errors })
      } else logic.loadRooms(true)
    }
  }

  return (
    <>
      <tr>
        <TD>
          {item?.images?.[0]?.path ? (
            <IconElement
              // width={80}
              height={80}
              icon={item?.images?.[0]?.path ?? ''}
            ></IconElement>
          ) : (
            <>Нет фото</>
          )}
        </TD>
        <TD cursorPointer>
          <UiText onClick={onClickEdit}>{item.name}</UiText>
        </TD>
        <TD>
          <UiText>{item.category?.name}</UiText>
        </TD>
        <TD>
          <UiText>{item.capacity}</UiText>
        </TD>
        <td className={`p-[8px] border-solid border-[1px] border-grey-secondary`} style={{ background: item.color }}>
          &nbsp;
        </td>
        {!archiveContext?.inArchive && (
          <TD center>
            <Checkbox useBoolState={[isActive, onChangeActive, onChangeActive]}></Checkbox>
          </TD>
        )}
        <TD center>
          <TableButtons onClickEdit={!archiveContext?.inArchive ? onClickEdit : undefined} onClickBox={onDelete} />
        </TD>
      </tr>
    </>
  )
}
