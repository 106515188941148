import { ApiGame, ApiGameZone, ApiPC } from '@@types/apiLogicTypes'
import { Loader } from '@components/ui/Loader/Loader'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { HappyBirthdayForm } from './HappyBirthdayForm/HappyBirthdayForm'

export const GamesPage = observer(() => {
  const context = useContext(ToastContext)
  const {
    auth: { selectedPark },
    game: { computers, gameZones, games },
  } = useStore()
  const logic = useLogic()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!selectedPark) return
    Promise.all([logic.loadIntegrationByPark(), logic.getIntegrationBirthday(selectedPark.id)]).then(() =>
      setLoading(false)
    )
  }, [selectedPark])

  const startGameHandler = async (zone: ApiGameZone, game: ApiGame) => {
    const formData = new FormData()
    formData.append('game_id', String(game.id))
    formData.append('zone_id', String(zone.id))
    const { status, message } = await logic.sendIntegrationGame(formData)

    if (status) context?.toastShowHandler({ status, summary: 'Запрос на запуск игры ушел' })
    else context?.toastShowHandler({ status, errors: message })
  }

  const restartPCHandler = async (e: ApiPC) => {
    const formData = new FormData()
    formData.append('id', String(e.id))
    const { status, message } = await logic.sendIntegrationPC(formData)

    if (status) context?.toastShowHandler({ status, summary: 'Запрос на перезагрузку ПК ушел' })
    else context?.toastShowHandler({ status, errors: message })
  }

  if (loading)
    return (
      <Layout>
        <Loader />
      </Layout>
    )

  return (
    <Layout title='Квесты' scrollable={true}>
      <div className='flex flex-col gap-9 overflow-x-hidden overflow-y-auto'>
        {gameZones.map((zone, i) => (
          <section className='grid grid-cols-2 gap-x-7 max-sm:gap-x-2 gap-y-[17px] w-[300px] max-sm:w-full' key={i}>
            <UiText className='col-start-1 col-end-3' size='md'>
              {zone.name}
            </UiText>
            {games
              .filter((game) => (zone.is_solo && game.type_id === 2) || (!zone.is_solo && game.type_id !== 2))
              .filter((game) => game.type_id !== 3)
              .map((game, i) => (
                <UiButton color='blue' key={i} onClick={() => startGameHandler(zone, game)} className='max-w-full'>
                  {game.name}
                </UiButton>
              ))}
          </section>
        ))}

        <section className='grid grid-cols-2 gap-x-7 max-sm:gap-x-2 gap-y-[17px] w-[300px] max-sm:w-full'>
          <UiText className='col-start-1 col-end-3 text-lg font-bold'>Перезагрузка ПК</UiText>
          {computers.map((pc) => (
            <UiButton key={pc.id} onClick={() => restartPCHandler(pc)} className='max-w-full'>
              {pc.name}
            </UiButton>
          ))}
        </section>

        <HappyBirthdayForm />
      </div>
    </Layout>
  )
})
