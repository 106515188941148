import { ServicePhotoItem, itemPhotoFile } from '@components/Settings/ServicePhotoItem'
import { ToastContext } from '@context/toastContext'
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload'
import { useContext, useRef } from 'react'
import { UiText } from '../UiText/UiText'

export type LoadPhotoProps = {
  arrayFile: itemPhotoFile[]
  setUploadFile(item: itemPhotoFile): void
  onClickDelete(index: number, id?: number): void
  isDisabledFile: boolean
  setDisabledFile(isDisabled: boolean): void
  title?: string
  small?: boolean
  buttonClassName?: string
  chooseLabel?: string
  imgClassName?: string
  accept?: string
}

export const LoadPhoto = ({
  arrayFile,
  setUploadFile,
  onClickDelete,
  isDisabledFile,
  setDisabledFile,
  title = 'Фото',
  small,
  buttonClassName,
  chooseLabel,
  imgClassName,
  accept,
}: LoadPhotoProps) => {
  const fileRef = useRef(null)
  const context = useContext(ToastContext)

  function onUpload() {
    context?.toastShowHandler({ status: true })
  }
  function onFailedUpload() {
    context?.toastShowHandler({ status: false, errors: 'Не удалось загрузить файл' })
  }

  const customBaseUploader = async (event: FileUploadHandlerEvent) => {
    setDisabledFile(true)
    const file = event.files[0]

    const isSuccess = true

    const reader = new FileReader()
    const nameFile = file.name

    if (file.type.includes('video')) reader.readAsArrayBuffer(file)
    else reader.readAsDataURL(file)
    reader.onloadend = function () {
      const base64String = reader?.result?.toString().replace('data:', '').replace(/^.+,/, '').replaceAll(' ', '')

      if (base64String && nameFile) {
        setUploadFile({
          path: `data:${file.type};base64,${base64String.replaceAll(' ', '')}`,
          name: nameFile.replaceAll(' ', ''),
          file: file,
        })
      }
    }

    if (isSuccess) {
      onUpload()
    } else {
      onFailedUpload()
    }

    if (fileRef.current) {
      const fileUpload = fileRef.current as FileUpload
      fileUpload.clear()
    }

    setDisabledFile(false)
  }
  return (
    <div className='flex flex-col '>
      <UiText className='text-grey'>{title}</UiText>
      <div className='flex flex-row gap-[33px] mt-[4px] w-[233px] '>
        <FileUpload
          ref={fileRef}
          customUpload
          uploadHandler={customBaseUploader}
          chooseLabel={chooseLabel ? chooseLabel : 'Прикрепить фото'}
          disabled={isDisabledFile}
          maxFileSize={300000000}
          name='invoice'
          accept={accept ? accept : 'image/*'}
          onUpload={onUpload}
          mode='basic'
          auto={true}
          className={`file-upload-workers ${buttonClassName}`}
        />
      </div>
      <div className={`flex  gap-8 ${small ? 'mt-8' : 'mt-4'} flex-wrap`}>
        {arrayFile.map((item, index: number) => {
          return (
            <ServicePhotoItem
              className={imgClassName}
              onClickDelete={onClickDelete}
              key={index + item.path}
              index={index}
              small={small}
              {...item}
            />
          )
        })}
      </div>
    </div>
  )
}
