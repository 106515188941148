import { ApiCategoryType, ApiCreateCategoryResponse } from '@@types/apiLogicTypes'

export interface TreeNodePrimereact {
  key: string
  label: string
  id?: number
  children: TreeNodePrimereact[]

  type?: number
  created_at?: string
  updated_at?: string
  park_id?: number
}

export function createNestedArray(typeMap: ApiCategoryType, data: ApiCreateCategoryResponse[]): TreeNodePrimereact[] {
  const result: TreeNodePrimereact[] = [
    {
      key: '0',
      label: 'Категории',
      children: [],
    },
  ]

  for (const item of Object.values(typeMap)) {
    result[0].children.push({
      key: `0-${result[0].children.length}`,
      label: item,
      children: [],
    })
  }

  for (const item of data) {
    const { id, name, type, created_at, updated_at, park_id } = item

    const category = result[0].children.find((c) => c.label === typeMap[type])
    if (!category) {
      result[0].children.push({
        key: `0-${result[0].children.length}`,
        label: typeMap[type],
        children: [],
      })
    }

    const subCategory = result[0].children.find((c) => c.label === typeMap[type])

    if (subCategory) {
      subCategory.children.push({
        key: `${subCategory.key}-${subCategory.children.length}`,
        label: name,
        id,
        created_at,
        updated_at,
        park_id,
        children: [],
      })
    }
  }

  return result
}
