import { Loader } from '@components/ui/Loader/Loader'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { AdditionalTrack } from './AdditionalTrack/AdditionalTrack'
import { WorkTimeTrack } from './WorkTimeTrack/WorkTimeTrack'

export const StaffPage = observer(() => {
  const logic = useLogic()
  const [loading, setLoading] = useState(true)

  const {
    auth: { selectedPark },
  } = useStore()

  useEffect(() => {
    if (!selectedPark) return
    Promise.all([
      logic.getEmployeeTracking(selectedPark.id, 1),
      logic.getEmployeeTracking(selectedPark.id, 2),
      logic.loadEmployees(),
      logic.loadRoles(),
    ]).then(() => setLoading(false))
  }, [selectedPark])

  if (loading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    )
  }

  return (
    <Layout className='!grid grid-cols-2 !p-0 !bg-inherit gap-2'>
      <WorkTimeTrack />
      <AdditionalTrack />
    </Layout>
  )
})
