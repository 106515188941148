import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths } from '@config/navigation'
import { FC } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import EventForm, { IEventForm } from './EventForm/EventForm'
import NotesForm from './NotesForm/NotesForm'

interface IConstructor extends IEventForm {
  hideHandler: () => void
  changeEventStatus: (status: number) => void
}

const Constructor: FC<IConstructor> = (props) => {
  const navigate = useNavigate()

  const toContactPageHandler = (flag?: string) => {
    flag = flag || ''

    const path = generatePath(AppRoutesPaths.ContactEdit, {
      id: String(props.initialState!.user.id),
    })
    navigate(path + flag)
  }

  return (
    <div className='min-h-full bg-grey-hover w-full flex flex-col gap-4 items-center'>
      <div className='bg-white py-3 px-4 flex gap-4 items-center w-full'>
        {!props.initialState && (
          <>
            <i className='pi pi-angle-left text-grey cursor-pointer' onClick={props.hideHandler}></i>
            <UiText>Новая заявка</UiText>
          </>
        )}
        {props.initialState && (
          <UiText>
            <span className='text-blue cursor-pointer' onClick={() => toContactPageHandler()}>
              {props.initialState.user.name}
            </span>{' '}
            –{' '}
            <span className='text-blue cursor-pointer' onClick={() => toContactPageHandler('#payments')}>
              Продажи
            </span>{' '}
            – Заявка №{props.initialState.order_id ?? props.initialState.id}
          </UiText>
        )}
      </div>
      <div
        className={`grid ${
          props.initialState ? 'grid-cols-[2fr_1fr]' : 'grid-cols-1'
        } bg-grey-hover w-full gap-3 relative overflow-hidden max-w-[1440px] h-full`}
      >
        <EventForm {...props} />
        {props.initialState && <NotesForm initialState={props.initialState} />}
      </div>
    </div>
  )
}

export default Constructor
