import { ApiApplication, ApiNote, ApiScheduleApplication } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { ButtonTypes } from '@components/ui/KanbanBoard/KanbanButton/KanbanButton'
import { DragEndEvent, DragOverEvent } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { action, observable } from 'mobx'

export class ApplicationStore {
  @observable
  loading = LoadState.Initial

  @observable
  applications: ApiApplication[] = []

  @observable
  scheduleApplications: ApiScheduleApplication[] = []

  @action
  sortApplications = (event: DragOverEvent) => {
    const { active, over } = event
    if (!over) return

    const activeId = active.id
    const overId = over.id

    if (activeId === overId) return

    const isActiveATask = active.data.current?.type === 'Application'
    const isOverATask = over.data.current?.type === 'Application'

    if (!isActiveATask) return

    if (isActiveATask && isOverATask) {
      const activeIndex = this.applications.findIndex((t) => t.id === activeId)
      const overIndex = this.applications.findIndex((t) => t.id === overId)

      if (this.applications[activeIndex].status !== this.applications[overIndex].status) {
        this.applications[activeIndex].status = this.applications[overIndex].status
        this.applications = arrayMove(this.applications, activeIndex, overIndex - 1)
      } else {
        this.applications = arrayMove(this.applications, activeIndex, overIndex)
      }
    }

    const isOverAColumn = over.data.current?.type === 'Column'

    if (isActiveATask && isOverAColumn) {
      const activeIndex = this.applications.findIndex((t) => t.id === activeId)

      this.applications[activeIndex].status = Number(overId)
      this.applications = arrayMove(this.applications, activeIndex, activeIndex)
    }
  }

  @action
  deleteApplications = (event: DragEndEvent) => {
    const activeId = event.active.id
    if (event?.over?.id === ButtonTypes.NOT_REALISED) {
      const activeIndex = this.applications.findIndex((t) => t.id === activeId)
      const overIndex = this.applications.findIndex((t) => t.status === 0)

      this.applications[activeIndex].status = 0
      this.applications = arrayMove(this.applications, activeIndex, overIndex)
    } else if (event?.over?.id === ButtonTypes.SUCCESFULL) {
      const activeIndex = this.applications.findIndex((t) => t.id === activeId)
      const overIndex = this.applications.findIndex((t) => t.status === 100)

      this.applications[activeIndex].status = 100
      this.applications = arrayMove(this.applications, activeIndex, overIndex - 1)
    } else if (event?.over?.id === ButtonTypes.DELETE) {
      const activeIndex = this.applications.findIndex((t) => t.id === activeId)
      const overIndex = this.applications.findIndex((t) => t.status === 1000)

      this.applications[activeIndex].status = 1000
      this.applications = arrayMove(this.applications, activeIndex, overIndex - 1)
    } else if (event?.over?.id === ButtonTypes.TO_NEW) {
      const activeIndex = this.applications.findIndex((t) => t.id === activeId)
      const overIndex = this.applications.findIndex((t) => t.status === 1)

      this.applications[activeIndex].status = 1
      this.applications = arrayMove(this.applications, activeIndex, overIndex - 1)
    }
  }

  @action
  setApplications = (events: ApiApplication[]) => {
    this.applications = events
  }

  @action
  setScheduleApplication = (events: ApiScheduleApplication[]) => {
    this.scheduleApplications = events
  }

  @action
  addNoteToApplication = (id: number, note: ApiNote) => {
    this.applications.find((e) => e.id === id)?.notes.push(note)
  }
}
