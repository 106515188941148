import { RoleNameEnum } from '@@types/apiLogicTypes'
import { IconElement } from '@components/ui/IconElement/IconElement'
import { UiText } from '@components/ui/UiText/UiText'
import { UserAvatar } from '@components/ui/UserAvatar/UserAvatar'
import { AppRoutesPaths, NavigationMenuItems, navigateTo } from '@config/navigation'
import { appColors } from '@config/theme'
import { useLogic, useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const RootNavigation = observer(() => {
  const {
    auth,
    applications: { applications },
  } = useStore()

  const onSelect = (route: string) => {
    navigateTo(route)
  }

  const newApplications = applications.filter((e) => e.status === 1)
  const navigate = useNavigate()

  const location = useLocation()
  const logic = useLogic()

  useEffect(() => {
    logic.loadApplications()
  }, [auth.selectedPark])

  const toEditProfileHander = () => {
    navigate(AppRoutesPaths.Profile)
  }

  return (
    <div className='max-w-[100px] min-w-[100px] mr-2'>
      <div className='fixed flex flex-col max-w-[100px] min-w-[100px] bg-white pt-[12px] pb-[16px] gap-[16px] rounded-[3px] min-h-[calc(100svh_-_32px)] max-h-[calc(100svh_-_32px)] z-[2] overflow-y-auto'>
        {NavigationMenuItems.map((item) => {
          if (!item.allowedRole?.some((allowedRole) => auth.roles?.includes(allowedRole))) {
            return <></>
          }
          if (item.path === AppRoutesPaths.ChoiseCenter || item.path === AppRoutesPaths.Profile) {
            return <></>
          }
          if (item.path === AppRoutesPaths.Games && auth.selectedPark?.show_games_crm === 0) {
            return <></>
          }
          return (
            <div
              key={item.name}
              onClick={onSelect.bind(this, item.path)}
              style={{
                backgroundColor: location.pathname.includes(item.path) ? appColors['bg'] : appColors.white,
              }}
              className='relative flex flex-col justify-center items-center w-full h-[53px] cursor-pointer hover:!bg-bg gap-[6px]'
            >
              {item.icon && <IconElement icon={item.icon}></IconElement>}
              {item.path === AppRoutesPaths.Applications && newApplications && newApplications?.length > 0 && (
                <div
                  className={`flex justify-center items-center absolute -top-1 right-4 w-6 h-6 bg-blue rounded-[50%]`}
                >
                  <UiText size='sm' weight='bold' className='text-white'>
                    {newApplications?.length}
                  </UiText>
                </div>
              )}
              <UiText className=' text-center'>{item.name}</UiText>
            </div>
          )
        })}
        <UserAvatar className='mt-auto' user={auth.user!} onClick={toEditProfileHander} />
        {auth.roles && (auth.roles.includes(RoleNameEnum.manager) || auth.roles.includes(RoleNameEnum.superadmin)) && (
          <div
            onClick={onSelect.bind(this, AppRoutesPaths.ChoiseCenter)}
            style={{
              backgroundColor: location.pathname.includes(AppRoutesPaths.ChoiseCenter)
                ? appColors['bg']
                : appColors.white,
            }}
            className='flex flex-col justify-center items-center w-full h-[53px] cursor-pointer hover:!bg-bg gap-[6px]'
          >
            <IconElement icon={'/icons/PlaceIcon.svg'}></IconElement>
            <UiText className='!text-xs text-center'>{auth.selectedPark?.name}</UiText>
          </div>
        )}
      </div>
    </div>
  )
})
