import { ApiFormCreateHoliday, ApiValidationResponse } from '@@types/apiLogicTypes'
import { PartyContainer } from '@components/ui/PartyContainer/PartyContainer'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'

export const PartyAddScreen = observer(() => {
  const title = 'Создание праздника '
  const logic = useLogic()
  const {
    rooms: { rooms },
    auth: { selectedPark },
    services: { showsArray: shows, entertaimentPrograms },
    product: { products },
  } = useStore()

  const context = useContext(ToastContext)

  useEffect(() => {
    logic.loadShowsServices()
    logic.loadEntertaimentProgramsServices()
    logic.loadProduct()
  }, [])

  async function sendForm(form: ApiFormCreateHoliday) {
    if (selectedPark) {
      form.park_id = String(selectedPark.id)
      const { status, errors }: ApiValidationResponse = await logic.createHoliday(form)

      if (status) {
        navigateTo(AppRoutesPaths.SettingParty)
      } else {
        context?.toastShowHandler({ status: status, errors })
      }
    }
  }

  return (
    <>
      <PartyContainer
        shows={shows}
        entertaimentPrograms={entertaimentPrograms}
        products={products}
        callbackForm={sendForm}
        partyRooms={rooms ?? []}
        title={title}
      />
    </>
  )
})
