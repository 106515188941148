import { ApiGame } from '@@types/apiLogicTypes'
import FormInput from '@components/ui/Form/FormInput'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useLogic } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { Avatar } from 'primereact/avatar'
import { Dropdown } from 'primereact/dropdown'
import { useContext, useEffect, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { TAdminGamesForm, TGameType } from './types'

export const AdminGamesPage = () => {
  const methods = useForm<TAdminGamesForm>({
    defaultValues: {
      games: [{ name: '', externalId: null, type: null, photo: null }],
    },
  })

  const { fields, append, remove } = useFieldArray({
    name: 'games',
    control: methods.control,
  })

  const [previews, setPreviews] = useState<string[]>([])
  const [types, setTypes] = useState<TGameType[]>([])
  const logic = useLogic()
  const context = useContext(ToastContext)

  const fetchGamesAndTypes = async () => {
    const games = await logic.getAdminGames()
    const types = await logic.getAdminGamesTypes()
    setTypes(types.data)
    methods.setValue(
      'games',
      games.data.map((e: ApiGame) => ({
        name: e.name,
        externalId: e.external_id,
        type: types.data.find((type: TGameType) => type.id === e.type_id),
        photo: e.poster,
      }))
    )
    setPreviews(games.data.map((e: ApiGame) => e.poster))
  }

  useEffect(() => {
    fetchGamesAndTypes()
  }, [])

  const onSubmit = async (data: TAdminGamesForm) => {
    const formData = new FormData()
    data.games.forEach((game, index) => {
      formData.append(`games[${index}][name]`, game.name)
      formData.append(`games[${index}][external_id]`, String(game.externalId))
      formData.append(`games[${index}][type_id]`, String(game.type?.id))
      if (game.photo && typeof game.photo === 'object') {
        formData.append(`games[${index}][image]`, game.photo)
      }
    })

    const { status, errors } = await logic.setAdminGames(formData)

    if (status) context?.toastShowHandler({ status: true })
    else context?.toastShowHandler({ status: false, errors: errors })
  }

  const handleImageChange = (index: number, file: File | null) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setPreviews((prev) => {
          const newPreviews = [...prev]
          newPreviews[index] = reader.result as string
          return newPreviews
        })
      }
      reader.readAsDataURL(file)
      methods.setValue(`games.${index}.photo`, file)
    } else {
      setPreviews((prev) => {
        const newPreviews = [...prev]
        newPreviews[index] = ''
        return newPreviews
      })
      methods.setValue(`games.${index}.photo`, null)
    }
  }

  const addGame = () => {
    append({ name: '', externalId: null, type: null, photo: null })
  }

  const removeGame = async (index: number) => {
    remove(index)
  }

  return (
    <Layout>
      <header className='flex flex-row gap-2 items-center'>
        <UiText className='text-lg font-bold'>Игры</UiText>
        <UiButton className='w-[36px] h-[36px]' onClick={addGame}>
          <i className='pi pi-plus text-sm'></i>
        </UiButton>
      </header>

      <FormProvider {...methods}>
        <form className='flex flex-col gap-4 h-full overflow-auto' onSubmit={methods.handleSubmit(onSubmit)}>
          {fields.map((game, i) => (
            <div key={game.id} className='grid grid-cols-[60px_215px_145px_36px] grid-rows-2 gap-y-2 gap-x-7'>
              {previews[i] ? (
                <img src={previews[i]} alt='Preview' className='w-[60px] h-[90px] row-start-1 row-end-3' />
              ) : (
                <Avatar shape='square' className='w-[60px] h-[90px] row-start-1 row-end-3' />
              )}
              <FormInput className='w-[215px]' name={`games.${i}.name`} placeholder='Введите название игры' required />
              <FormInput
                className='w-[215px]'
                containerClassName='row-start-2'
                name={`games.${i}.externalId`}
                placeholder='Введите ID игры из лаунчера'
                required
              />
              <div>
                <label htmlFor={`fileInput${i}`}>
                  <UiButton
                    className='w-full'
                    color='blue'
                    onClick={() => document!.getElementById(`fileInput${i}`)!.click()}
                    type='button'
                  >
                    {previews[i] ? 'Сменить постер' : 'Добавить постер'}
                  </UiButton>
                </label>
                <Controller
                  name={`games.${i}.photo`}
                  control={methods.control}
                  render={({ field }) => (
                    <input
                      id={`fileInput${i}`}
                      type='file'
                      accept='image/*'
                      style={{ display: 'none' }} // Скрыть input
                      onChange={(e) => {
                        const file = e.target.files ? e.target.files[0] : null
                        field.onChange(file)
                        handleImageChange(i, file)
                      }}
                    />
                  )}
                />
              </div>
              <Controller
                control={methods.control}
                name={`games.${i}.type`}
                rules={{ required: 'Game type is required.' }}
                render={({ field, fieldState }) => (
                  <Dropdown
                    value={field.value}
                    optionLabel='name'
                    className={`w-full worker shadow-none h-[36px] row-start-2
                      ${fieldState.error && '!border-red rounded-sm border-solid border'}`}
                    panelClassName='worker-panel'
                    placeholder='Тип игры'
                    color='green'
                    options={types}
                    onChange={(e) => methods.setValue(`games.${i}.type`, e.value)}
                  />
                )}
              />
              <UiButton className='w-[36px] h-[36px]' onClick={() => removeGame(i)} color='red'>
                <i className='pi pi-trash text-sm'></i>
              </UiButton>
            </div>
          ))}
          <UiButton className='mt-auto min-h-[36px] w-[215px]'>Сохранить</UiButton>
        </form>
      </FormProvider>
    </Layout>
  )
}
