import { ApiEmployee, Park, Role } from '@@types/apiLogicTypes'
import { itemPhotoFile } from '@components/Settings/ServicePhotoItem'
import { LoadPhoto } from '@components/ui/LoadPhoto/LoadPhoto'
import { MultiSelectSkalaland } from '@components/ui/MultiSelectSkalaland/MultiSelectSkalaland'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { UiTextArea } from '@components/ui/UiTextArea/UiTextArea'
import { UiTextField } from '@components/ui/UiTextField/UiTextField'
import { AppRoutesPaths } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { useInput } from '@hooks/useInput'
import Layout from '@layouts/NonScrollableLayout'
import { IStories } from '@modules/Advertising/Stories/StoriesPage'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { Dropdown } from 'primereact/dropdown'
import { Password } from 'primereact/password'
import { FC, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './EmployeesAddPage.css'

interface IEmployeeFormPage extends IStories {
  isProfile?: boolean
}

//Этот компонент работает в 3-х режимах:
//1) Создание/редактирование сотрудника в разделе Настройка
//2) Создание/редактирование сотрудника в разделе Админка (isAdmin = true)
//3) Редактирование своего профиля (isProfile = true)

export const EmployeesFormPage: FC<IEmployeeFormPage> = observer(({ isAdmin, isProfile }) => {
  const [initialValue, setInitialValue] = useState<ApiEmployee | undefined>()
  const [roles, setRoles] = useState<Role[]>([])
  const [role, setRole] = useState<Role | null>(null)

  const { id } = useParams()

  const phone = useInput(initialValue?.phone ?? '', true)
  const name = useInput(initialValue?.name ?? '')
  const workingRate = useInput(String(initialValue?.working_rate) ?? '' ?? '')
  const secondName = useInput(initialValue?.surname ?? '')
  const patronymic = useInput(initialValue?.patronymic ?? '')
  const notation = useInput(initialValue?.comment ?? '')
  const [parksSelected, setParksSelected] = useState<Park[]>([])

  const email = useInput(initialValue?.email ?? '')
  const password = useInput(initialValue?.password ?? '')

  const context = useContext(ToastContext)
  const logic = useLogic()
  const navigate = useNavigate()

  const [photo, setPhoto] = useState<itemPhotoFile[]>([])

  const [, setDisabledPhoto] = useState<boolean>(false)

  const {
    park: { parks },
    auth: { selectedPark, user, updateUser },
    users: { employees, managers, addEmployee, addManager, editEmployee, editManager },
  } = useStore()

  const setInitialProfile = () => {
    setInitialValue(user!)
    setRole(user!.roles[0])
  }

  useEffect(() => {
    if (isProfile) {
      setInitialProfile()
      return
    }

    logic.loadRoles().then((res) => setRoles(res.data!.filter((e) => (isAdmin ? e.id === 3 : e.id !== 3))))

    if (!id) return

    if (!isAdmin && employees.length > 0) {
      const existingEmployee = employees.find((e) => e.id === +id)
      existingEmployee && setInitialValue(existingEmployee)
    } else if (isAdmin && managers.length > 0) {
      const existingManager = managers.find((e) => e.id === +id)
      existingManager && setInitialValue(existingManager)
    } else {
      logic.loadEmployeeById(+id).then((response) => response.data && setInitialValue(response.data))
    }
  }, [])

  useEffect(() => {
    initialValue?.image && setPhoto([{ name: 'Фото', path: initialValue.image }])
  }, [initialValue])

  useEffect(() => {
    setRole(roles.find((e) => e.id === initialValue?.roles[0].id) ?? null)
  }, [initialValue, roles])

  useEffect(() => {
    setParksSelected(parks.filter((e) => initialValue?.parks.some((park) => park.id === e.id)))
  }, [parks, initialValue])

  function setUploadPhoto(item: itemPhotoFile) {
    setPhoto((prev) => [...prev, item])
  }

  function onClickPhotoDelete(indexFileDelete: number) {
    setPhoto((prev) => prev.filter((_, index) => index !== indexFileDelete))
    setDisabledPhoto(false)
  }

  const onSubmit = async () => {
    const formData = new FormData()

    formData.append('name', name?.value)
    formData.append('surname', secondName?.value)
    formData.append('phone', phone?.value)
    email.value && formData.append('email', email.value)
    password.value && formData.append('password', password.value)

    formData.append('patronymic', patronymic.value ?? '')
    formData.append('comment', notation.value ?? '')
    formData.append('working_rate', workingRate.value ?? '')

    isAdmin && formData.append('role_id', String(3))
    role && formData.append('role_id', String(role.id))
    isProfile && formData.append('role_id', String(user!.roles[0].id))

    if (initialValue?.image && !photo[0]) formData.append('delete_image', '1')

    if (photo[0] && photo[0].file) {
      formData.append('image', photo[0].file)
    }

    if (isAdmin || isProfile) {
      if (parksSelected.length > 0) parksSelected.forEach((e, i) => formData.append(`parks[${i}]`, String(e.id)))
    } else selectedPark && formData.append(`parks[${0}]`, String(selectedPark.id))

    const { status, errors } = initialValue
      ? await logic.updateEmployee(formData, initialValue.id).then((res) => {
          if (res.data) isProfile ? updateUser(res.data) : isAdmin ? editManager(res.data) : editEmployee(res.data)
          return res
        })
      : await logic.createEmployee(formData).then((res) => {
          if (res.data) isAdmin ? addManager(res.data) : addEmployee(res.data)
          return res
        })

    context?.toastShowHandler({ status: status, errors: errors })
  }

  function onSelectedParks(items: Park[]) {
    setParksSelected([...items])
  }

  function deleteSelectedParks(id: number) {
    setParksSelected((prev) => {
      if (prev && prev?.length > 0) {
        return prev.filter((item) => item.id !== id)
      }
      return []
    })
  }

  const onExit = () => {
    logic.logOutApp()
    navigate(AppRoutesPaths.Authorization)
  }

  return (
    <Layout title={isProfile ? 'Профиль' : initialValue ? 'Редактирование сотрудника' : 'Добавление сотрудника'}>
      <div className={cn('flex flex-col h-full overflow-x-auto rounded-[3px] gap-[16px]')}>
        <div className='flex flex-row  gap-[32px] items-center'>
          <UiTextField className='!w-[233px]' name='Фамилия' {...secondName} />
          <UiTextField className='!w-[233px]' name='Имя' {...name} />
          <UiTextField className='!w-[233px]' name='Отчество' {...patronymic} />
        </div>
        <div className='flex flex-row  gap-[32px] items-center '>
          <div className='flex flex-col gap-[4px] w-[233px]'>
            <UiText className='text-grey'>{'Должность'}</UiText>

            <Dropdown
              value={isProfile ? user!.roles[0] : isAdmin ? roles[0] : role}
              onChange={(e) => setRole(e.value)}
              options={isProfile ? user!.roles : roles}
              optionLabel='ru'
              placeholder='Выбор должности'
              className='w-full md:w-14rem max-w-[233px] border-green worker'
              panelClassName='worker-panel'
              color='green'
              disabled={isProfile}
            />
          </div>
          <UiTextField className='!w-[233px]' name='Телефон' {...phone} disabled={isProfile && !!user!.phone} />
          {(isAdmin || isProfile) && (
            <div className='gap-[4px] flex flex-col max-w-[233px] w-[100%]'>
              <UiText className='text-grey'>Центры</UiText>

              <MultiSelectSkalaland
                setSelectedItem={onSelectedParks}
                selectedItems={parksSelected}
                deleteSelected={deleteSelectedParks}
                options={parks}
                disabled={isProfile}
              />
            </div>
          )}
        </div>
        <UiTextField className='!w-[233px]' name='Ставка' {...workingRate} type='number' disabled={isProfile} />
        <div className='flex flex-row  gap-[32px]'>
          <UiTextArea className='!w-[763px]' title='Примечание' {...notation} disabled={isProfile} />
        </div>
        <div className='flex flex-row  gap-[32px] items-center'>
          <UiTextField className='!w-[233px]' name='Почта (логин)' {...email} disabled={isProfile} />
          <div className='flex flex-col gap-[4px]'>
            <UiText className='text-grey'>{initialValue ? 'Новый пароль' : 'Пароль'}</UiText>

            <Password
              {...password}
              className={`text-field bg-white h-[36px] border-solid border-[1px] text-grey border-green rounded-[3px] focus:outline-none !w-[233px] ${
                isProfile ? 'border-opacity-[0.6]' : ''
              }`}
              feedback={false}
              tabIndex={1}
              placeholder='Пароль'
              toggleMask
              disabled={isProfile}
            />
          </div>
        </div>
        <div className='flex flex-col  gap-[4px]'>
          <div className='flex flex-row gap-[33px] w-[233px]'>
            <LoadPhoto
              isDisabledFile={!!photo.length}
              setDisabledFile={(isDisabledFile) => setDisabledPhoto(isDisabledFile)}
              arrayFile={photo}
              setUploadFile={setUploadPhoto}
              onClickDelete={onClickPhotoDelete}
              buttonClassName='file-upload-workers-green'
              imgClassName='!w-[160px] !h-[200px]'
            />
          </div>
        </div>

        <div className='mt-auto h-[52px] flex flex-row items-end gap-[16px] border-solid border-0 border-t-[1px] border-grey-secondary'>
          <UiButton className='w-[233px]' onClick={onSubmit}>
            {initialValue ? 'Изменить' : 'Сохранить'}
          </UiButton>
          {isProfile && (
            <UiButton className='w-[233px]' onClick={onExit} color='red'>
              Выйти
            </UiButton>
          )}
        </div>
      </div>
    </Layout>
  )
})
