import { DropdownProps } from 'primereact/dropdown'

export function renderItemTemplate(option: DropdownProps) {
  return <div className='w-[100%] h-[24px]' style={{ backgroundColor: option.color }} data-type='color'></div>
}
export function renderLabelTemplate(props: DropdownProps) {
  if (props?.color) {
    return <div className='w-[100%] h-[24px]' style={{ backgroundColor: props.color }}></div>
  } else {
    ;<div>Цвет календаря</div>
  }

  return <div>Цвет календаря</div>
}
