import { AppRoutesType, navigateTo } from '@config/navigation'
import { useStore } from '@hooks/storeHook'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { UiText } from '../UiText/UiText'

export const NavigationNavbar = ({ routes, title }: { routes: AppRoutesType; title: string }) => {
  const onSelect = (route: string) => {
    navigateTo(route)
  }
  const { auth } = useStore()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const pathArray = location.pathname.split('/').filter((i) => i)
    const str = pathArray[0]

    if (pathArray.length === 1) {
      navigate(routes[0].path)
    }
    if (pathArray.indexOf(str) >= 0) {
      return
    }
    navigate(routes[0].path)
  }, [location.pathname])

  return (
    <div className='flex flex-row  min-h-full w-full overflow-x-hidden '>
      <div className='flex flex-row mr-[8px] min-h-full max-h-[992px] min-w-[204px] max-w-[204px] z-[2]'>
        <div className='bg-white px-[8px] fixed py-[16px] flex flex-col max-w-[204px] min-w-[204px]  gap-[16px] rounded-[3px] h-[100%] min-h-[calc(100vh_-_32px)] max-h-[calc(100vh_-_32px)]'>
          <div className='flex  flex-col justify-start items-start w-full h-[36px]  pb-[16px] border-solid border-0 border-green border-b-[1px]'>
            <UiText size='lg' weight='bold' className='text-left '>
              {title}
            </UiText>
          </div>
          <div className='h-[100vh] flex flex-col gap-[16px]  rounded-[3px]'>
            {routes.map((item) => {
              if (!item.allowedRole?.some((allowedRole) => auth.roles?.includes(allowedRole))) {
                return <></>
              }
              return (
                <div
                  key={item.name}
                  onClick={onSelect.bind(this, item.path)}
                  className='flex min-h-[32px] flex-col justify-center items-start w-full h-[28px] cursor-pointer p-[8px]'
                >
                  <UiText
                    style={{
                      fontWeight: location.pathname.includes(item.path) ? 800 : 400,
                    }}
                    className=' text-left text-2base'
                  >
                    {item.name}
                  </UiText>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className={'w-full min-h-full mx-[auto] overflow-x-auto max-w-[1440px] no-scroll'}>
        <Outlet />
      </div>
    </div>
  )
}
