import { ApiRoom, ApiRoomType } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'

import { observable } from 'mobx'

export class RoomsStore {
  @observable
  mainLoading = LoadState.Initial

  @observable
  loadById = LoadState.Initial

  @observable
  selectedUpdateRoom: ApiRoom | null = null

  @observable
  rooms: ApiRoom[] | null = null

  @observable
  types: ApiRoomType[] | null = null

  @observable
  addRoom = (room: ApiRoom) => {
    this.rooms = this.rooms ? [...this.rooms, room] : [room]
  }

  resetUpdateAttraction = () => {
    this.selectedUpdateRoom = null
  }

  updateActiveRoom(response: ApiRoom) {
    if (this.rooms) {
      const index = this.rooms.findIndex((item) => item.id === response.id)
      if (index !== -1) {
        this.rooms = [
          ...this.rooms.map((item) => (item.id === response.id ? { ...item, active: response.active } : item)),
        ]
      }
    } else {
      this.rooms = [response]
    }
  }
}
