import { ApiObjProduct, ApiProduct } from '@@types/apiLogicTypes'

export const getProducts = (shows: ApiProduct[]) => {
  const array: ApiObjProduct[] = []

  shows.forEach((item) => {
    const obj = { id: item.id, count: item.count ?? 0 }
    array.push(obj)
  })

  return array
}
