import { ApiDiscount } from '@@types/apiLogicTypes'
import FormInput from '@components/ui/Form/FormInput'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { bonusTypes } from '@modules/Advertising/Loyalty/LoyaltyPage'
import { IStories } from '@modules/Advertising/Stories/StoriesPage'
import { createFormData } from '@utils/createDataForm'
import { observer } from 'mobx-react-lite'
import { Dropdown } from 'primereact/dropdown'
import { useContext, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { TDiscountForm } from '../types'

export const DiscountFormPage = observer(({ isAdmin }: IStories) => {
  const methods = useForm<TDiscountForm>()
  const logic = useLogic()
  const context = useContext(ToastContext)

  const [initialValue, setInitialValue] = useState<ApiDiscount | undefined>()
  const [loading, setLoading] = useState<boolean>(true)

  const { id } = useParams()
  const {
    auth: { selectedPark },
    advertising: { discounts },
  } = useStore()

  const init = async () => {
    if (!selectedPark) return
    setLoading(true)

    if (id && discounts.length === 0) {
      logic
        .loadDiscountById(+id)
        .then((res) => setInitialValue(res.data))
        .finally(() => setLoading(false))
    } else if (discounts.length !== 0 && id) {
      setInitialValue(discounts.find((e) => e.id === +id))
      setLoading(false)
    } else setLoading(false)
  }

  useEffect(() => {
    init()
  }, [selectedPark])

  useEffect(() => {
    methods.reset({
      name: initialValue?.name,
      type: bonusTypes.find((e) => e.id === initialValue?.type),
      amount: Number(initialValue?.amount) ?? 0,
    })
  }, [initialValue])

  const onCreate = async (data: TDiscountForm) => {
    const formData = createFormData({
      name: data.name,
      type: data.type.id,
      active: 1,
      amount: data.amount,
    })

    !isAdmin && formData.append('park_id', String(selectedPark!.id))

    const { status, errors } = isAdmin
      ? await logic.createAdminDiscount(formData)
      : await logic.createDiscount(formData)
    if (status) navigateTo(isAdmin ? AppRoutesPaths.AdminPanelDiscount : AppRoutesPaths.AdvertisingDiscount)
    else context?.toastShowHandler({ status: false, errors: errors })
  }

  const onEdit = async (data: TDiscountForm) => {
    const query = `?value=${data.amount}&type=${data.type.id}&name=${data.name}`

    const { status, errors } = isAdmin
      ? await logic.updateAdminDiscount(query, +id!)
      : await logic.updateDiscount(query, +id!)
    if (status) navigateTo(isAdmin ? AppRoutesPaths.AdminPanelDiscount : AppRoutesPaths.AdvertisingDiscount)
    else context?.toastShowHandler({ status: false, errors: errors })
  }

  return (
    <Layout scrollable title={initialValue ? 'Изменить скидку' : 'Добавить скидку'} loading={loading}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(initialValue ? onEdit : onCreate)} className='h-full w-full flex flex-col'>
          <div className='grid grid-cols-[repeat(3,233px)] gap-y-4 gap-x-9'>
            <FormInput
              className={`text-field bg-white px-[8px] py-[10px] h-[36px] border-solid border-[1px] text-grey border-green rounded-[3px] focus:outline-none`}
              containerClassName='col-start-1 col-end-3'
              title='Название'
              name='name'
              required
            />

            <div className='gap-[4px] flex flex-col w-[100%] col-start-1'>
              <UiText className='text-grey'>Тип</UiText>

              <Controller
                name='type'
                control={methods.control}
                rules={{ required: 'Required' }}
                render={({ field, fieldState }) => (
                  <Dropdown
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={bonusTypes}
                    optionLabel='name'
                    placeholder='Выберите триггер'
                    className={`w-full md:w-14rem border-green worker ${fieldState.error ? '!border-red' : ''}`}
                    panelClassName='worker-panel'
                    color='green'
                    required
                  />
                )}
              />
            </div>

            <FormInput
              className={`text-field bg-white px-[8px] py-[10px] h-[36px] border-solid border-[1px] text-grey border-green rounded-[3px] focus:outline-none`}
              containerClassName='col-start-1 col-end-3'
              title='Размер'
              name='amount'
              required
            />
          </div>

          <UiButton className='w-[233px] hover:bg-green-[#16A34A] !font-black  justify-center max-h-[36px] uppercase !text-sm col-start-1 mt-auto'>
            {initialValue ? 'Изменить' : 'Добавить'}
          </UiButton>
        </form>
      </FormProvider>
    </Layout>
  )
})
