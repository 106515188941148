import { ApiTicket } from '@@types/apiLogicTypes'
import { TicketTableItem } from '@components/ui/ContainerTable/TicketTableItem'
import { TablePageContainer } from '@components/ui/TablePageContainer/TablePageContainer'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { useLogic, useStore } from '@hooks/storeHook'
import { tableNameTicketAdmin } from '@utils/variableApp'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useEffect } from 'react'

import { measureBodyTemplate } from '@components/ui/ColumnContents/ColumnContents'
import { TableButtons } from '@components/ui/ContainerTable/TableButtons'
import { generatePath, useNavigate } from 'react-router-dom'

export const AdminTicketsScreen = observer(() => {
  const logic = useLogic()
  const navigate = useNavigate()
  const {
    ticket: { adminTickets },
    services: { units },
  } = useStore()

  const onAdd = () => {
    navigateTo(AppRoutesPaths.AdminPanelTicketsAdd)
  }

  useEffect(() => {
    logic.loadAdminTickets()
  }, [])

  function onClickEdit(ticket: ApiTicket) {
    let path: string = ''

    path = generatePath(AppRoutesPaths.AdminPanelTicketsUpdate, {
      id: String(ticket.id),
    })

    navigate(path)
  }

  const buttonBodyTemplate = (item: ApiTicket) => {
    return <TableButtons onClickEdit={() => onClickEdit(item)} />
  }

  return (
    <>
      <TablePageContainer
        tableProps={{
          arrayHeadName: tableNameTicketAdmin,
          children: <TableList items={adminTickets ?? []} />,
          isDeletePhoto: true,
          isDeleteCheckbox: true,
        }}
        mainTitle='Билеты'
        textAdd={'Билет'}
        onClickAdd={onAdd}
        textArchive={'Билеты'}
        isAdmin={true}
      >
        <DataTable
          value={adminTickets ?? []}
          showGridlines
          className={classnames('bg-white text-base')}
          style={{ fontSize: '12px' }}
          removableSort
          sortField='name'
          sortOrder={1}
          emptyMessage='Нет данных'
          // filters={filters}
          // filterDisplay='row'
        >
          <Column
            field='name'
            header='Название'
            sortable
            // filter
            // filterPlaceholder='Поиск по названию'
            // showAddButton={false}
          ></Column>
          <Column field='category.name' header='Категория' sortable></Column>
          <Column
            field='unit_id'
            header='Измерение'
            body={(rowData: ApiTicket) => measureBodyTemplate(rowData, units ?? null)}
          ></Column>
          <Column field='' header='' body={buttonBodyTemplate}></Column>
        </DataTable>
      </TablePageContainer>
    </>
  )
})

export const TableList = (props: { items: ApiTicket[] }) =>
  props.items.map((item) => <TicketTableItem key={item.id} isAdmin ticket={item} />)
