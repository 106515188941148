import { ApiObjProduct } from '@@types/apiLogicTypes'

export const objectToSearchParams = (obj: any, params = new URLSearchParams(), prefix = '') => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const fullKey = prefix ? `${prefix}[${key}]` : key
      const value = obj[key]

      if (key === 'products') {
        obj.products.forEach((value: ApiObjProduct, index: number) => {
          params.append(`products[${index}][id]`, String(value.id))
          params.append(`products[${index}][count]`, String(value.count))
        })
      }
      if (key === 'programs') {
        obj.programs.forEach((value: string, index: number) => {
          params.append(`programs[${index}]`, String(value))
        })
      } else if (key === 'shows') {
        obj.shows.forEach((value: string, index: number) => {
          params.append(`shows[${index}]`, String(value))
        })
      } else {
        if (key !== 'products') {
          params.append(fullKey, value)
        }
      }
    }
  }

  return params
}
