import { appColors } from '@config/theme'

export const SortArrowDown = ({ color = appColors.grey }: { color?: string }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='10' height='15' viewBox='0 0 10 15' fill='none'>
    <path
      d='M6 1C6 0.447715 5.55229 4.82823e-08 5 0C4.44772 -4.82823e-08 4 0.447715 4 1L6 1ZM4 1L4 13L6 13L6 1L4 1Z'
      fill={color}
    />
    <path
      d='M5.40687 14.4304L9.43527 8.79062C9.67165 8.45968 9.43509 8 9.02841 8L0.971595 8C0.564909 8 0.328347 8.45968 0.564728 8.79062L4.59313 14.4304C4.79254 14.7096 5.20746 14.7096 5.40687 14.4304Z'
      fill={color}
    />
  </svg>
)
