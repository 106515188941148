import { appColors } from '@config/theme'

export const SortArrowUp = ({ color = appColors.grey }: { color?: string }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='10' height='15' viewBox='0 0 10 15' fill='none'>
    <path d='M4 14C4 14.5523 4.44772 15 5 15C5.55228 15 6 14.5523 6 14H4ZM6 14L6 2L4 2L4 14H6Z' fill={color} />
    <path
      d='M4.59313 0.569613L0.564728 6.20938C0.328346 6.54032 0.564909 7 0.971595 7L9.02841 7C9.43509 7 9.67165 6.54032 9.43527 6.20938L5.40687 0.569613C5.20746 0.290439 4.79254 0.290439 4.59313 0.569613Z'
      fill={color}
    />
  </svg>
)
