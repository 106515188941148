import { ApiDataCities, ApiDataParks } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'

import { observable } from 'mobx'

export class ParkStore {
  @observable
  mainLoading = LoadState.Initial

  @observable
  addPark = LoadState.Initial

  @observable
  parks: ApiDataParks = []

  @observable
  cities: ApiDataCities | null = null

  @observable
  getCity = (cityId: number) => {
    if (this?.cities) {
      const findIndex = this.cities.findIndex((item) => item.id === cityId)
      if (findIndex >= 0) {
        return this.cities[findIndex]
      }
    }
  }

  @observable
  getPark = (parkId: number) => {
    if (this?.parks) {
      const findIndex = this.parks.findIndex((item) => item.id === parkId)

      if (findIndex >= 0) {
        return this.parks[findIndex]
      }
    } else {
      return
    }
  }
}
