import { ScrollToTop } from '@common/scrollToTop'
import { exact } from '@common/tsUtils'
import { RootLoader } from '@components/ui/RootLoader/RootLoader'
import { AppRoutes, AppRoutesPaths, AppRoutesType } from '@config/navigation'
import { AppContext } from '@context/appContexts'
import { useAnyEffect } from '@hooks/commonHooks'
import { Logic } from '@logic/logic'
import { Store } from '@store/store'
import 'primereact/resources/primereact.css' // core css
import 'primereact/resources/themes/saga-green/theme.css' // theme
import { RefObject, useEffect, useRef, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import '@common/commonStyles.css'
import Authorization from '@components/Authorization/Authorization'
import { IToastShowHandler, ToastContext } from '@context/toastContext'
import ru from '@locales/ru.json'
import { concatErrorMessages } from '@utils/concatErrorMessages'
import { observer } from 'mobx-react-lite'
import 'primeicons/primeicons.css'
import { addLocale } from 'primereact/api'
import { Toast } from 'primereact/toast'

export type ToastTypeRef = RefObject<Toast>

export const App = observer(() => {
  const [appContext] = useState(() => {
    const store = new Store()
    return exact<AppContext>({
      store,
      logic: new Logic(store),
    })
  })

  useAnyEffect(async () => {
    await appContext.logic.loadInitialApp()
  })

  const toastRef: ToastTypeRef = useRef<Toast>(null)

  const toastShowHandler = ({ status, errors, summary }: IToastShowHandler) => {
    const error = typeof errors === 'string' ? errors : errors && concatErrorMessages(errors)
    const titleByStatus = summary ? summary : status ? 'Сохранено' : `Произошла ошибка`
    toastRef.current!.show({
      severity: status ? 'success' : 'error',
      summary: titleByStatus,
      detail: error,
      life: status ? 3000 : 30000,
    })
  }

  const renderRoutes = (routes?: AppRoutesType) => {
    if (!routes) {
      return null
    }

    return routes.map((route) => {
      if (!route.allowedRole?.some((allowedRole) => appContext.store.auth.roles?.includes(allowedRole))) {
        return <></>
      }

      return (
        <Route key={route.name} element={route.element} path={route.path}>
          {renderRoutes(route.childs)}
        </Route>
      )
    })
  }

  useEffect(() => {
    addLocale('ru', ru)
  }, [])

  return (
    <BrowserRouter>
      <Toast ref={toastRef} />

      <AppContext.Provider value={appContext}>
        <ToastContext.Provider value={{ toast: toastRef, toastShowHandler: toastShowHandler }}>
          <ScrollToTop>
            <RootLoader>
              <Routes>
                <Route path={AppRoutesPaths.Authorization} element={<Authorization />} />
                <Route path={AppRoutesPaths.Root} element={<Navigate to={AppRoutesPaths.Reception} replace />} />
                <Route path={'*'} element={<Navigate to={AppRoutesPaths.Reception} replace />} />

                {renderRoutes(AppRoutes)}
              </Routes>
            </RootLoader>
          </ScrollToTop>
        </ToastContext.Provider>
      </AppContext.Provider>
    </BrowserRouter>
  )
})
