import { ApiShowsServices } from '@@types/apiLogicTypes'

export const getShows = (shows: ApiShowsServices[]) => {
  const array: number[] = []

  shows.forEach((item) => {
    array.push(item.id)
  })

  return array
}
