import { UiText } from '@components/ui/UiText/UiText'
import classNames from 'classnames'
import { Dropdown, DropdownProps } from 'primereact/dropdown'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface IFormDropdown extends Omit<DropdownProps, 'size'> {
  name: string
  title?: string
  size?: 'small' | 'full'
}

export const FormDropdown: FC<IFormDropdown> = ({ name, title, size, ...props }) => {
  const { control } = useFormContext()

  return (
    <div className={`gap-[4px] flex flex-col ${size === 'small' ? 'w-[233px]' : 'w-full'}`}>
      {title && <UiText className='text-grey'>{title}</UiText>}
      <Controller
        name={name}
        control={control}
        rules={{ required: props.required && 'Поле обязательно' }}
        render={({ field, fieldState }) => (
          <Dropdown
            id={field.name}
            optionLabel='name'
            placeholder={props.placeholder ?? 'Все'}
            value={field.value}
            onChange={(e) => field.onChange(e.value)}
            panelClassName='worker-panel'
            color='green'
            emptyMessage='Не найдено 😢'
            {...props}
            className={classNames(
              `${fieldState.error && 'incorrect'} w-full md:w-14rem border-green worker h-[36px] flex items-center`,
              props.className
            )}
            required={undefined}
          />
        )}
      />
    </div>
  )
}
