export const appColors = {
  white: '#FFF',
  black: '#282828',
  green: '#39B54A',
  'bg-green': '#EBF8EE',
  'green-bright': '#2FDB13',
  blue: '#00B4D2',
  'bg-blue': '#E5F7FB',
  grey: '#60666F',
  'grey-200': '#e8e8e8',
  'grey-secondary': '#E4E4E4',
  'light-grey': '#c5c5c5',
  'grey-hover': '#F1F4F5',
  bg: '#F1F4F5',
  yellow: '#FFD94D',
  'bg-yellow': '#FFFBEE',
  orange: '#F8CA46',
  red: '#CA2728',
  'bg-red': '#ffeeee',
  violet: '#6c4dff',
  'bg-violet': '#efeeff',
}

export const kanbanColors = {
  grey: {
    primary: appColors.grey,
    secondary: appColors.bg,
  },
  blue: {
    primary: appColors.blue,
    secondary: appColors['bg-blue'],
  },
  yellow: {
    primary: appColors.yellow,
    secondary: appColors['bg-yellow'],
  },
  violet: {
    primary: appColors.violet,
    secondary: appColors['bg-violet'],
  },
  green: {
    primary: appColors.green,
    secondary: appColors['bg-green'],
  },
  red: {
    primary: appColors.red,
    secondary: appColors['bg-red'],
  },
}
