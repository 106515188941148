import { formatPhone, normalizePhone } from '@utils/textUtils'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
export type UseInputType = {
  value: string
  onChange(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>): void
  setValue: Dispatch<SetStateAction<string>>
}
export const useInput = (initialValue?: string, isPhone?: boolean): UseInputType => {
  const [value, setValue] = useState<string>(initialValue ?? '')

  useEffect(() => {
    initialValue && setValue(initialValue)
  }, [initialValue])

  const onChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    if (isPhone) {
      setValue(normalizePhone(e.target.value))
    } else {
      setValue(e.target.value)
    }
  }

  if (isPhone) {
    return { value: formatPhone(value), onChange, setValue }
  } else {
    return { value, onChange, setValue }
  }
}
