import { itemPhotoFile } from '@components/Settings/ServicePhotoItem'
import { TPaymentType } from '@modules/TicketSale/Form/TotalPrice/TotalPrice'

export type ApiAuthorizationData = {
  email: string
  password: string
}
export type ApiRegistrationData = {
  email: string
  password: string
  name: string
}

export type DataTimeInfo = {
  created_at: string
  updater_at: string
}

export interface ApiPeople extends DataTimeInfo {
  id: number
  birth: Date

  email: string
  email_verified_at: string

  name: string
  parent_id: number

  phone: string | null
  pivot?: { order_id: number; kid_id: number }

  sex: 0 | 1
}

export interface ApiUser extends ApiPeople {
  children?: ApiUser[]

  roles: Role[]
  parks: Park[]
  orders: ApiPaymentOrder[]

  logs: ApiLog[]
  notes: ApiNote[]
  bonus: ApiBonus
  bonus_logs: ApiBonusLog[]

  information_agree: 0 | 1
  loyalty_agree: 0 | 1
}

export interface ApiEmployee extends Omit<ApiUser, 'orders' | 'logs' | 'notes'> {
  working_rate: number | null
  image: string | null
  surname: string | null
  patronymic: string | null
  comment: string | null
  password: string | null
  parks: Park[]
}

export interface ApiAnimator extends Omit<ApiPeople, 'pivot'> {
  pivot?: {
    animator_id: number
    starts_at: string
    order_id: number
    ends_at: string
  }
}

export interface ApiEmployeeTrackingReports extends Partial<DataTimeInfo> {
  comment: string | null
  date: string
  format: 1 | 2
  id: number | undefined
  park_id: number
  type: 1 | 2
  user: ApiEmployee | undefined
  user_id: number | undefined
  from?: string | undefined
  to?: string | undefined
  sum?: number
}

export type ApiDataPost = string
export type ApiDataParks = Park[]
export type ApiDataCities = City[]

export interface ApiObjPark {
  name?: string
  active?: 1 | 0
  slug?: string
  address?: string
  emails: string[]
  location?: null | string[]
  city_id?: number
  vk?: null | string
  whatsapp?: null | string
  mall?: null | string
  worktime?: null | string
  title?: null | string
  description?: null | string
  company_abbreviation?: string
  show_kitchen?: 1 | 0
  brand_id?: number
}

export type City = {
  id: number
  name: string
  slug: string
}

export type Park = Partial<Omit<ApiObjPark, 'phone'>> & {
  active: number
  id: number
  phone?: number
  images: ImagePark[]
  city: City
  emails: string[]
  pivot: {
    user_id: number
    park_id: number
  }
  show_kitchen: 1 | 0
  show_ticket_payment: 1 | 0
  show_online_payment: 1 | 0
  show_dr: 1 | 0
  show_games_crm: 1 | 0
  yookassa_shop_id: number
  yookassa_key: number
  privacy_policy: string
  terms_of_use: string
  bonus_program: string
  safety_precautions: string
  requisites: string
  fh_1: string
  fh_3: string
  whatsapp_id_instance: string
  whatsapp_token: string
  yandex_map_id: string | null
}

export type ApiFormData = FormData | ApiPostFormImg
export type ApiPostFormImg = {
  image: string[]
  id: number
  type: string
}

export type ImagePark = {
  id: number
  path: string
  type: string
  park_id: number
}
export type Role = {
  id: number
  name: RoleNameEnum
  guard_name: string
  created_at: string
  updated_at: string
  description: string | null
  ru: string
  pivot?: {
    model_id: number
    role_id: number
    model_type: string
  }
}

export enum RoleNameEnum {
  manager = 'manager',
  superadmin = 'superadmin',
  animator = 'animator',
  client = 'user',
  default = '',
}

export type Employee = {
  name: string
  secondName: string
  patronymic: string
  jobTitle: string
  center: Park
  phone: number
  id: number
  photo: ImagePark
}

export type ApiCategory = {
  test: string
}
export type ApiCategoryType = {
  [key: string]: string
}
export type ApiCreateCategoryResponse = {
  name: string
  park_id: number
  type: number
  updated_at: string
  created_at: string
  id: number
}

export type ApiCreateCategory = {
  type: number
  name: string
}

export type ApiServicesUnits = {
  id: number
  text: string
}
export type ApiServicesMeasures = {
  id: number
  text: string
}

export type ApiServicesCreateForm = {
  name: string
  price: string
  room_id?: number
  deleted?: 1 | 0
  park_id: number
  category_id: number
  measure_id: number
  unit_id: number
  extra_price: number
  active: boolean
  show_in_crm: number
  images?: File[]
  days?: string[]
  description?: string
  base_count?: number
}

export interface ApiServices extends Omit<ApiServicesCreateForm, 'images'> {
  created_at: string
  id: number
  updated_at: string
  sort: string
  images?: ApiServicesCreateFormImg[]
  category: ApiCreateCategoryResponse
  group_id: number | null
  original_id: number
  discount_price: number | null
  count: number
}

export type ApiServicesCreateFormImg = {
  id: number
  option_id: number
  path: string
}

export type ApiRoomsCreateFormImg = {
  id: number
  room_id: number
  path: string
  created_at: string
  updated_at: string
}

export interface ApiServicesTypes extends ApiCreateCategoryResponse {}
export interface ApiRoomType extends ApiCreateCategoryResponse {}

export type ApiRoom = {
  id: number
  category_id: number
  name: string
  park_id: number
  services?: ApiServices[]
  category: ApiRoomType
  deleted?: 1 | 0

  active: number
  color: string
  capacity: number
  description: string
  images?: ApiRoomsCreateFormImg[] | []
}

export type ApiTicket = {
  name: string
  measure_id: number
  unit_id: number
  category_id: number
  active: number
  weekday_price: number
  count: number
  weekend_price: number
  park_id: number
  updated_at: string
  created_at: string
  group_id: number | null
  sort: number
  id: number
  category: ApiCreateCategoryResponse
  price: number
  measure: ApiServicesMeasures
  unit: ApiServicesUnits
  deleted: 0 | 1
  discount_price: number
}

export type ApiCreateTicketForm = {
  name?: string
  hours?: number
  category_id?: number
  measure_id?: number
  unit_id?: number
  weekday_price?: number
  weekend_price?: number
  deleted?: 1 | 0

  park_id?: number
  active?: number

  id?: number
}

export type ApiAttraction = {
  updated_at: string
  id: number
  deleted: 0 | 1
  group_id: number | null
  name: string
  description: string
  active: number
  images?: ApiAttractionImg[]
  park_id: number
}
export type ApiAttractionImg = {
  attraction_id: number
  created_at: string
  id: number
  path: string
  updated_at: string
}
export type ApiCreateAttractionForm = {
  id?: number
  name: string
  description: string
  active: number
  images?: itemPhotoFile[]
  park_id: number
  delete_images?: number[]
}

export type FormRoom = {
  color: string
  capacity: string
  description: string
  name: string

  id?: number
  active: string
  delete_images?: number[]
  images?: itemPhotoFile[]
  category_id: string
  park_id: string
}

export type ApiParty = {
  name: string
  weekday_price: number
  weekend_price: number
  count: number
  park_id: number
  weekday_additional_price: number
  weekend_additional_price: number
  active: number
  has_animator: number
  shows: []
  products: []
  room_id: number
  id: string
}

export type ApiShowsServices = {
  id: number
  name: string
  price: number
  created_at: string
  updated_at: string
  room_id?: number
  category_id: number
  measure_id: number
  unit_id: number
  extra_price: number
  active: number
  park_id: number
  description: string
  base_count: number

  count?: number
}

export type ApiProduct = {
  id: number
  name: string
  price: number
  img: any
  description: string
  expires_at: any
  created_at: string
  updated_at: string
  vendor_code: string
  category_id: number
  park_id: number
  category: ApiCreateCategoryResponse

  count?: number

  pivot?: Pivot
}

export type ApiFormCreateHoliday = {
  name?: string
  park_id?: string
  count?: string
  deleted?: 0 | 1
  weekday_price?: string
  weekday_additional_price?: string
  weekend_price?: string
  weekend_additional_price?: string
  active?: string
  show_in_crm?: string
  has_animator?: string
  shows?: number[]
  programs?: number[]
  products?: ApiObjProduct[]
  room_id?: string
  id?: string
  duration?: number
}

export type ApiHoliday = {
  id: number
  original_id?: number
  park_id: number
  name: string
  weekday_price: number
  weekend_price: number
  weekday_additional_price: number
  weekend_additional_price: number
  count: number
  kids_count: number
  has_animator?: number
  active: number
  show_in_crm: number
  created_at: string
  updated_at: string
  room_id: number
  group_id?: number
  starts_at: string
  ends_at: string
  shows: ApiHolidayShow[]
  programs: ApiHolidayShow[]
  products: ApiProduct[]
  room: ApiRoom & { original_id: number }
  deleted: 0 | 1
  duration: number
}

export type ApiObjProduct = {
  count: number
  id: number
}

export interface ApiHolidayShow {
  id: number
  name: string
  price: number
  created_at: string
  updated_at: string
  room_id: any
  category_id: number
  measure_id: number
  unit_id: number
  extra_price: number
  active: number
  park_id: number
  description: string
  base_count: number
  pivot: Pivot
}

export interface Pivot {
  holiday_id: number
  option_id: number
  count?: number
}

export interface ApiHolidayProduct {
  id: number
  name: string
  price: number
  img: any
  description: string
  expires_at: any
  created_at: string
  updated_at: string
  vendor_code: string
  category_id: number
  park_id: number
  pivot: Pivot
}

export type ApiCreateServiceForm = {
  base_count: string
  description: string
  name: string
  sort: string
  price: string
  park_id: string
  category_id: string
  measure_id: string
  unit_id: string
  extra_price: string
  active: string
}

export type ApiValidationError = Record<string, string[]>

export type ApiValidationResponse = {
  status: boolean
  errors?: Record<string, string[]>
}

export type ApiOrder = {
  bonus_sum: number
  id: number
  user_id: number
  park_id: number
  status: number
  promo: null
  created_at: Date
  updated_at: Date
  price: number
  holiday: ApiHoliday
  services: ApiServices[]
}

export type ApiSchedule = {
  id: number
  order_id: number
  room_id: number
  starts_at: string
  ends_at: string
  created_at: string
  updated_at: string
  park_id: number
  status: number
}

export type ApiLog = DataTimeInfo & {
  id: number
  message: string
  order_id: number
  user: ApiUser
  user_id?: number
}

export type ApiBonusLog = DataTimeInfo & {
  id: number
  sum: number
  order_id: number
  text: string
  owner_id: number
  payment_id: number | null
}

export type ApiNote = Omit<ApiLog, 'message'> & {
  text: string
}

export type ApiApplication = {
  id: number
  created_at: string
  order_id: number | null
  holiday: ApiHoliday
  animators: ApiAnimator[]

  children: ApiPeople[]
  kid_id: number

  logs: ApiLog[]
  notes: ApiNote[]

  services: ApiServices[]
  schedule: ApiSchedule
  park_id: number
  payments: ApiPayment[]
  price: number
  promo: string | null
  sort: number
  status: number
  user_id: number
  user: ApiUser
  type: 0 | 1
  updated_at: string
  utm: string | null
  source: string | null
}

export type ApiCreateApplicationForm = {
  park_id: string
  date: Date
  order: {
    holiday: ApiHoliday
    children: ApiPeople[]
    newKid?: { name: string; birth: Date }[]
    user?: {
      name: string
      phone: string
      email: string
      children: ApiPeople[]
      bonus: number
    }
    date: string
    time: string
    additional_kids: string
    additional_elders: string
    kids_count: string
    elder_count: string
    ends_at: string
    starts_at: string
    bonuses: number
  }
  room: ApiRoom
  services: ApiServices[]
  programs: ApiServices
  entertainments: ApiServices[]
  sum: number
  preorder_sum: number
  paymentType: TPaymentType
  count: number
  animators: { starts_at: string; ends_at: string; entity: ApiAnimator }[]
}

export type ApiCreateStoriesForm = {
  name: string
  tag: string
  sort: number
  tag_color: { color: string }
  preview: ApiServicesCreateFormImg
  img: ApiServicesCreateFormImg[]
  active: 0 | 1
  delete_images: number[]
  preview_image: ApiServicesCreateFormImg
}

export type ApiStories = {
  id: number
  name: string
  sort: number
  tag: string
  tag_color: string
  preview_image: string
  park_id: number
  active: 0 | 1
  created_at: string
  updated_at: string
  images: itemPhotoFile[]
  group_id?: number
}

export type ApiScheduleApplication = {
  id: number
  order_id: number
  room_id: number
  starts_at: string
  ends_at: string
  created_at: string
  updated_at: string
  park_id: 1
  status: 1
  order: ApiApplication
}

// export type ApiContact = {
//   id: number
//   name: string
//   phone: string
//   email: string
// }

export type ApiNoteForm = {
  comment: string
}

export type ApiUsersPaginationResponse = {
  current_page: number
  data: ApiUser[]
  first_page_url: string
  from: number
  last_page: number
  last_page_url: string
  links: string[]
  next_page_url: string
  path: string
  per_page: number
  prev_page_url: string
  to: number
  total: number
}

export type ApiPC = DataTimeInfo & {
  id: number
  location: string
  number: string
  park_id: number
  name: string
  type: number
  zone_id: number
  monitor_id: number
}

export type ApiGame = {
  external_id: number
  id: number
  name: string
  poster: string | null
  type_id: number | null
}

export type ApiBirthdayGame = DataTimeInfo & {
  active: 1 | 0
  duration: number
  ends_at: string | null
  game: string
  game_id: number
  id: number
  images: DataTimeInfo & { id: number; zone_birthday_id: number; type: string; path: string }[]
  name: string
  zone_id: number
}

export type ApiGameZone = {
  computer: ApiPC
  games: ApiGame[]
  id: number
  park_id: number
  name: string
  duration: number
  is_solo: 1 | 0
}

export type ApiPaymentOrder = Omit<ApiOrder, 'services'> & {
  type: number
  sort: number
  bonus_sum: number
  user: ApiUser
  children: ApiPeople[]
  tickets: (Omit<ApiTicket, 'weekend_price' | 'weekday_price'> & {
    count: number
    original_id: number
    price: number
    discount_id: number | null
    discount_price: number | null
  })[]
  services: (ApiServices & {
    count: number
    original_id: number
    price: number
    discount_id: number | null
    discount_price: number | null
  })[]
  schedule: ApiSchedule | null
  payments: ApiPayment[]
  pin: string | null
  pin_activated: 0 | 1
  logs: ApiLog[]
  notes: ApiNote[]
}

export type ApiPayment = DataTimeInfo & {
  id: number
  payment_id: number | null
  user_id: number
  sum: number
  order_id: number
  status: number
  comment: string
  order: ApiPaymentOrder
  type: 0 | 1 | 7
  bonus_sum: number

  check_id: number | null
  checks: ApiCheck[] | null
  online_token: string | null
}

export type ApiCheck = DataTimeInfo & {
  comment: string
  content: string
  id: number
  slip: string
  success: 1 | 0
  fiskal_number: string
}

export type ApiCashReport = DataTimeInfo & {
  cashbox_id: number
  check_number: number
  file_path: string
  format: number
  id: number
  name: string
  order_id: number
  park_id: number
  status: number
  sum: number
  type: 1 | 2 | 3 | 4
  payment_id: number | null
}

export type ApiSale = DataTimeInfo & {
  id: number
  active: 1 | 0
  deleted: 1 | 0
  bonus_type: number
  discount_value: number
  duration: number
  name: string
  services: ApiServices[]
  park_id: number
  tickets: ApiTicket[]
  trigger_type: number
  trigger_value: number | null
}

export type ApiBonus = DataTimeInfo & {
  id: number
  sum: number
  user_id: number
}

export type ApiNewsLetter = DataTimeInfo & {
  id: number
  name: string
  deleted: 1 | 0
  parkId: number
  text: string
  count: number
  percentage: number | null
  trigger_type: number
  active: 1 | 0
  trigger_value: number
  execute_at: string
}

export type ApiDiscount = DataTimeInfo & {
  id: number
  name: string
  deleted: 1 | 0
  parkId: number
  amount: string
  type: number
  active: 1 | 0
}
