import { ApiServicesUnits } from '@@types/apiLogicTypes'

interface SeparatedTime {
  hours: ApiServicesUnits[]
  minutes: ApiServicesUnits[]
}

export function separateHoursAndMinutes(arr: ApiServicesUnits[]): SeparatedTime {
  const hours: ApiServicesUnits[] = []
  const minutes: ApiServicesUnits[] = []

  arr.forEach((item) => {
    if (item.text.includes('час')) {
      hours.push(item)
    } else if (item.text.includes('мин')) {
      minutes.push(item)
    }
  })

  return { hours, minutes }
}
