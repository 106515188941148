import { ApiCashReport } from '@@types/apiLogicTypes'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths } from '@config/navigation'
import Layout from '@layouts/NonScrollableLayout'
import { Dialog } from 'primereact/dialog'
import { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { ControlPanel } from './ControlPanel/ControlPanel'
import { Form } from './Form/Form'
import { KKMPanel } from './KKMPanel/KKMPanel'
import { Table } from './Table/Table'

export type TCashboxAction = 2 | 3 | 4

export const KassaPage = () => {
  const [visible, setVisible] = useState(false)
  const [cashboxActionType, setCashboxActionType] = useState<TCashboxAction>(2)
  const [initialReport, setInitialReport] = useState<ApiCashReport | undefined>(undefined)

  const navigate = useNavigate()

  const setInitialReportHandler = (report: ApiCashReport) => {
    if (report.type === 1) {
      const path = generatePath(AppRoutesPaths.TicketSaleEdit, {
        id: String(report.payment_id),
      })
      navigate(path)
      return
    }
    setInitialReport(report)
    setCashboxActionType(report.type)
    setVisible(true)
  }

  const setVisibleHandler = (flag: boolean, action?: TCashboxAction) => {
    setVisible(flag)
    action && setCashboxActionType(action)
  }

  useEffect(() => {
    !visible && setInitialReport(undefined)
  }, [visible])

  return (
    <Layout className='!p-0'>
      <main className='grid grid-cols-[2fr_1fr] h-full bg-grey-hover w-full gap-3 relative overflow-hidden'>
        <section className='bg-white p-4 w-full h-full flex flex-col gap-6 overflow-scroll relative'>
          <UiText className='text-lg font-bold'>Касса</UiText>

          <ControlPanel cashManipulateCallback={setVisibleHandler} />
          <Table setInitialReportHandler={setInitialReportHandler} />
        </section>
        <KKMPanel />

        <Dialog
          visible={visible}
          onHide={() => setVisible(false)}
          className='w-[300px]'
          headerClassName='py-2 '
          contentClassName='pt-2 h-fit pb-4'
          draggable={false}
        >
          <Form type={cashboxActionType} callback={setVisibleHandler} initialState={initialReport} />
        </Dialog>
      </main>
    </Layout>
  )
}
