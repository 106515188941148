import { UiText } from '@components/ui/UiText/UiText'
import Layout from '@layouts/NonScrollableLayout'
import { observer } from 'mobx-react-lite'
import { Table } from './Table/Table'
import { TodaySchedule } from './TodaySchedule/TodaySchedule'

const ReceptionPage = observer(() => {
  return (
    <Layout className='!p-0'>
      <div className='w-full h-full bg-grey-hover grid grid-cols-6 gap-[14px]'>
        <div className='h-full bg-white px-5 pt-[25px] col-start-1 col-end-5 flex flex-col overflow-hidden gap-7'>
          <UiText className='!font-bold !text-lg mt-[25px] absolute top-[10px]'>Продажи</UiText>
          <Table />
        </div>
        <div className='h-full bg-white px-5 py-[25px] col-start-5 col-end-7 overflow-hidden flex flex-col gap-[16px]'>
          <UiText className='!font-bold !text-lg'>Праздники сегодня</UiText>
          <TodaySchedule />
        </div>
      </div>
    </Layout>
  )
})

export default ReceptionPage
