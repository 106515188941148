import { ApiLog, ApiNote, ApiNoteForm } from '@@types/apiLogicTypes'
import FormTextArea from '@components/ui/Form/FormTextArea'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { useLogic, useStore } from '@hooks/storeHook'
import moment from 'moment'
import { ProgressSpinner } from 'primereact/progressspinner'
import { FC, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import MonthBlock from './MonthBlock'

export interface INotesForm {
  initialState: {
    logs: ApiLog[]
    notes: ApiNote[]
    [key: string]: any
  }
  logOnClick?: (fiskalNumber: number) => void
  interactive?: boolean
}

const NotesForm: FC<INotesForm> = ({ initialState, interactive = true, logOnClick }) => {
  const methods = useForm<ApiNoteForm>()
  const logic = useLogic()
  const [loading, setLoading] = useState(false)

  const [groupedAndSortedLogsAndNotes, setGroupedAndSortedLogsAndNotes] = useState(
    initialState ? groupAndSortLogsAndNotes(initialState.logs, initialState.notes) : {}
  )

  const {
    applications: { addNoteToApplication },
  } = useStore()

  const onSubmit: SubmitHandler<ApiNoteForm> = (data) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('text', data.comment)
    if (!initialState) return
    if ('schedule' in initialState) {
      const id = initialState.schedule?.order_id ?? initialState.id
      logic
        .sendNoteForApplication(id, formData)
        .then((data) => {
          if ('status' in data) return
          setGroupedAndSortedLogsAndNotes((prev) => {
            prev
            const keys = Object.keys(prev)
            const lastKey = keys[keys.length - 1]
            return { ...prev, [lastKey]: [...prev[lastKey], data] }
          })
          methods.reset()
          addNoteToApplication(id, data)
        })
        .finally(() => setLoading(false))
    } else {
      const id = initialState.id
      logic
        .sendNoteForClient(id, formData)
        .then((data) => {
          if ('status' in data) return
          setGroupedAndSortedLogsAndNotes((prev) => {
            if (!Object.keys(prev).length) {
              const month = moment().format('MMMM, YYYY')
              return { [month]: [data] }
            }
            const keys = Object.keys(prev)
            const lastKey = keys[keys.length - 1]
            return { ...prev, [lastKey]: [...prev[lastKey], data] }
          })
          methods.reset()
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <div className='h-full bg-white flex flex-col py-6 px-4 gap-3 overflow-hidden'>
      <div className='flex flex-col w-full h-full overflow-auto relative '>
        {loading && (
          <div className='absolute inset-0 flex justify-center items-center z-10 bg-opacity-40 bg-black'>
            <ProgressSpinner className='w-[50px] h-[50px]' />
          </div>
        )}
        {Object.keys(groupedAndSortedLogsAndNotes).length > 0 ? (
          Object.keys(groupedAndSortedLogsAndNotes).map((month) => (
            <MonthBlock key={month} month={month} groupedLogs={groupedAndSortedLogsAndNotes} logOnClick={logOnClick} />
          ))
        ) : (
          <UiText>Нет логов</UiText>
        )}
      </div>
      {interactive && (
        <FormProvider {...methods}>
          <form className='flex flex-col gap-4' onSubmit={methods.handleSubmit(onSubmit)}>
            <FormTextArea name='comment' placeholder='Примечание' required={true} />
            <UiButton className='w-full'>Отправить</UiButton>
          </form>
        </FormProvider>
      )}
    </div>
  )
}

function groupAndSortLogsAndNotes(logs: ApiLog[], notes: ApiNote[]): { [month: string]: (ApiLog | ApiNote)[] } {
  const mergedArray = [...logs, ...notes].sort((a, b) =>
    moment(new Date(a.created_at)).isAfter(new Date(b.created_at)) ? 1 : -1
  )
  return mergedArray.reduce((result: { [month: string]: (ApiLog | ApiNote)[] }, log) => {
    const month = moment(log.created_at).format('MMMM, YYYY')
    if (!result[month]) {
      result[month] = []
    }
    result[month].push(log)
    return result
  }, {})
}

export default NotesForm
