import { ApiProduct, ApiRoom, ApiShowsServices } from '@@types/apiLogicTypes'
import { useBoolStateTypeReturn } from '@hooks/commonHooks'
import { UseInputType } from '@hooks/useInput'
import Layout from '@layouts/NonScrollableLayout'
import { holidayDurations } from '@utils/times'
import cn from 'classnames'
import { Dropdown } from 'primereact/dropdown'
import { Checkbox } from '../Checkbox/Checkbox'
import { MultiSelectSkalaland } from '../MultiSelectSkalaland/MultiSelectSkalaland'
import { TDuration } from '../PartyContainer/PartyContainer'
import { SelectProduct } from '../SelectProduct/SelectProduct'
import { UiButton } from '../UiButton/UiButton'
import { UiText } from '../UiText/UiText'
import { UiTextField } from '../UiTextField/UiTextField'

export type TestSelected = { name: string; code: string; count?: number }

export type PartyFormProps = {
  name: UseInputType
  count: UseInputType
  weekdayPrice: UseInputType
  weekendPrice: UseInputType
  weekdayAddPrice: UseInputType
  weekendAddPrice: UseInputType

  title: string

  partyArray: ApiRoom[]

  sendForm: () => void
  useBoolStateCheckbox: useBoolStateTypeReturn
  useBoolStateShowInCrm: useBoolStateTypeReturn
  useBoolStateAnimator: useBoolStateTypeReturn

  selectedRoom: ApiRoom | null
  selectedDuration: TDuration | null
  setSelectedRoom: (room: ApiRoom) => void
  onSelectDuration: (duration: TDuration | null) => void

  itemsProduct: ApiProduct[]
  itemsShows: ApiShowsServices[]
  itemsPrograms: ApiShowsServices[]

  onSelectedShows: (items: ApiShowsServices[]) => void
  onSelectedPrograms: (items: ApiShowsServices[]) => void
  deleteSelectedShows: (id: number) => void
  deleteSelectedPrograms: (id: number) => void
  deleteSelectedProduct: (id: number) => void
  decrementCounter: (selected: ApiProduct) => void
  incrementCounter: (selected: ApiProduct) => void

  showsSelected: ApiShowsServices[]
  programsSelected: ApiShowsServices[]
  productsSelected: ApiProduct[]
  setProductSelected: (items: ApiProduct[]) => void
  isAdmin: boolean
}

export const PartyForm = (props: PartyFormProps) => {
  return (
    <Layout title={props.title} scrollable={true}>
      <div className={cn('flex flex-col h-full overflow-x-auto rounded-[3px] gap-[16px]', '')}>
        <div className='flex flex-row  gap-[32px] items-center'>
          <UiTextField className='!w-[233px]' name='Название' {...props.name}></UiTextField>
        </div>
        {!props.isAdmin && (
          <>
            <div className='flex flex-row  gap-[32px] items-center'>
              <UiTextField className='!w-[233px]' name='Цена в будни' {...props.weekdayPrice} />
              <UiTextField className='!w-[233px]' name='Цена за доп ребенка' {...props.weekdayAddPrice} />
            </div>
            <div className='flex flex-row  gap-[32px] items-center'>
              <div className='flex flex-row  gap-[32px]'>
                <UiTextField className='!w-[233px]' name='Цена в выходные' {...props.weekendPrice} />
                <UiTextField className='!w-[233px]' name='Цена за доп ребенка' {...props.weekendAddPrice} />
              </div>
            </div>
            <div className='flex flex-row  gap-[32px] items-center'>
              <div className='flex flex-row  gap-[32px]'>
                <UiTextField className='!w-[233px]' name='Кол-во билетов' {...props.count} />
              </div>
            </div>

            {props.partyArray && (
              <div className='gap-[4px] flex flex-col max-w-[233px] w-[100%]'>
                <UiText className='text-grey'>Выбранный патирум</UiText>

                <Dropdown
                  value={props.selectedRoom}
                  onChange={(e) => props.setSelectedRoom(e.value)}
                  options={props.partyArray}
                  optionLabel='name'
                  placeholder='Любой'
                  showClear
                  className='w-full md:w-14rem border-green worker'
                  panelClassName='worker-panel'
                  color='green'
                />
              </div>
            )}

            <div className='gap-[4px] flex flex-col max-w-[233px] w-[100%]'>
              <UiText className='text-grey'>Длительность</UiText>

              <Dropdown
                value={props.selectedDuration}
                onChange={(e) => props.onSelectDuration(e.value)}
                options={holidayDurations}
                optionLabel='title'
                placeholder='Любой'
                className='w-full md:w-14rem border-green worker'
                panelClassName='worker-panel'
                color='green'
              />
            </div>
          </>
        )}
        <div className='gap-[4px] flex flex-col max-w-[233px] w-[100%]'>
          <UiText className='text-grey'>Включенные услуги</UiText>

          <div className='flex items-center gap-[8px]'>
            <Checkbox useBoolState={props.useBoolStateAnimator}></Checkbox>
            <UiText className='text-grey mb-[3px]'>Аниматор</UiText>
          </div>
        </div>
        <div className='gap-[32px] flex max-w-[233px] w-[100%]'>
          <div className='gap-[4px] flex flex-col max-w-[233px] w-[100%]'>
            <UiText className='text-grey'>Включенные шоу</UiText>

            <MultiSelectSkalaland
              setSelectedItem={props.onSelectedShows}
              selectedItems={props.showsSelected}
              deleteSelected={props.deleteSelectedShows}
              options={props.itemsShows}
            />
          </div>
          {!props.isAdmin && (
            <>
              <div className='gap-[4px] flex flex-col max-w-[233px] w-[100%]'>
                <UiText className='text-grey'>Включенные программы</UiText>

                <MultiSelectSkalaland
                  setSelectedItem={props.onSelectedPrograms}
                  selectedItems={props.programsSelected}
                  deleteSelected={props.deleteSelectedPrograms}
                  options={props.itemsPrograms}
                />
              </div>

              <div className='gap-[4px] flex flex-col max-w-[233px] w-[100%]'>
                <UiText className='text-grey'>Включенные товары</UiText>

                <SelectProduct
                  onDeleteSelected={props.deleteSelectedProduct}
                  incrementCounter={props.incrementCounter}
                  decrementCounter={props.decrementCounter}
                  items={props.itemsProduct}
                  selectedItems={props.productsSelected}
                  onChangeSelectedItems={props.setProductSelected}
                />
              </div>
            </>
          )}
        </div>

        <div className='mt-auto min-h-[52px] flex flex-row items-end gap-[16px] border-solid border-0 border-t-[1px] border-grey-secondary'>
          <UiButton onClick={props.sendForm} className='w-[233px]'>
            Сохранить
          </UiButton>
          {!props.isAdmin && (
            <>
              <div className='flex items-center gap-[8px]'>
                <Checkbox useBoolState={props.useBoolStateCheckbox}></Checkbox>
                <UiText className='text-grey mb-[3px]'>Доступно на сайте</UiText>
              </div>
            </>
          )}
          {!props.isAdmin && (
            <>
              <div className='flex items-center gap-[8px]'>
                <Checkbox useBoolState={props.useBoolStateShowInCrm}></Checkbox>
                <UiText className='text-grey mb-[3px]'>Доступно в CRM</UiText>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}
