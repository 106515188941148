import { ApiAttractionImg } from '@@types/apiLogicTypes'
import { itemPhotoFile } from '@components/Settings/ServicePhotoItem'

export const convertingImgForFile = (images?: ApiAttractionImg[]): itemPhotoFile[] => {
  if (!images) {
    return []
  }
  const array: itemPhotoFile[] = []

  images.forEach((item) => {
    const objNewFile = {
      path: item.path,
      name: 'Заглавная',
      id: item.id,
    }

    array.push(objNewFile)
  })

  return array
}
