import { useInput } from '@hooks/useInput'
import { DropdownChangeEvent } from 'primereact/dropdown'
import { useEffect, useState } from 'react'

import {
  ApiRoom,
  ApiServices,
  ApiServicesMeasures,
  ApiServicesTypes,
  ApiServicesUnits,
  Park,
} from '@@types/apiLogicTypes'
import { useLogic } from '@hooks/storeHook'
import { separateHoursAndMinutes } from '@utils/separateHoursAndMinutes'

import { useBoolState } from '@hooks/commonHooks'

import { createFormData } from '@utils/createDataForm'
import { findItem } from '@utils/getCategory'
import { getImagesServices } from '@utils/getImagesServices'

import { itemPhotoFile } from '@components/Settings/ServicePhotoItem'
import { ServicesForm } from '../ServicesForm/ServicesForm'

export const patriumOption = {
  name: 'Нет',
  id: -1,
  category_id: -1,
  park_id: -1,
  category: {
    name: '',
    park_id: -1,
    type: -1,
    updated_at: '',
    created_at: '',
    id: -1,
  },
  active: 0,
  sort: 0,
  color: '',
  capacity: 0,
  images: [],
  description: '',
}
export type ServicesContainerProps = {
  isAdmin?: boolean
  initialValue?: ApiServices | null
  callbackForm: (form: FormData) => void
  title: string
  subtitle: string

  types: ApiServicesTypes[]
  measures: ApiServicesMeasures[]
  selectedPark: Park
  unitsStore: ApiServicesUnits[]
  rooms: ApiRoom[]
}
export const ServicesContainer = ({
  callbackForm,
  isAdmin,
  initialValue,
  subtitle,
  title,
  rooms,
  types,
  measures,
  selectedPark,
  unitsStore,
}: ServicesContainerProps) => {
  const patriums = rooms ? [...rooms, patriumOption] : [patriumOption]

  const selectedServices = initialValue ?? null

  const images = getImagesServices(selectedServices?.images) ?? []

  const selectedTypes = findItem(types ?? [], selectedServices?.category_id ?? -1) as ApiServicesTypes
  const selectedMeasures = findItem(measures ?? [], selectedServices?.measure_id ?? -1) as ApiServicesUnits
  const selectedUnit = findItem(unitsStore ?? [], selectedServices?.unit_id ?? -1) as ApiServicesUnits
  const selectPatrium = findItem(patriums, selectedServices?.room_id ?? -1) as ApiRoom

  const useBoolStateCheckbox = useBoolState(Boolean(selectedServices?.active ?? false))

  const logic = useLogic()

  const [isDisabledFile, setDisabledFile] = useState<boolean>(false)

  const name = useInput(String(selectedServices?.name ?? ''))
  const sort = useInput(String(selectedServices?.sort ?? ''))
  const [selectedCategory, setSelectedCategory] = useState<ApiServicesTypes | null>(selectedTypes ?? null)
  const basedCountChild = useInput(String(selectedServices?.base_count ?? ''))

  const price = useInput(String(selectedServices?.price ?? ''))
  const [selectedMetering, setSelectedMetering] = useState<ApiServicesUnits | null>(selectedMeasures ?? null)
  const [selectedValue, setSelectedValue] = useState<ApiServicesMeasures | null>(selectedUnit ?? null)

  const objSeparateUnits = separateHoursAndMinutes(unitsStore ?? [])
  const [units, setUnits] = useState<ApiServicesMeasures[] | null>(null)
  const extraAdditionalChild = useInput(String(selectedServices?.extra_price ?? ''))

  const [description, setDescription] = useState(selectedServices?.description ?? '')

  const [selectedPatrium, setSelectedPatrium] = useState<ApiRoom | null>(selectPatrium)

  const [files, setFiles] = useState<itemPhotoFile[]>([...images])
  const [filesDeleteId, setFilesDeleteId] = useState<number[]>([])

  function changeUnits(event: DropdownChangeEvent) {
    setSelectedMetering(event.value)
  }
  function addFile(item: itemPhotoFile) {
    setFiles((prev) => [...prev, item])
  }
  function onClickDeleteFile(indexFileDelete: number, id?: number) {
    setFiles((prev) => prev.filter((_, index) => index !== indexFileDelete))
    if (id) {
      setFilesDeleteId((prev) => [...prev, id])
    }
  }
  useEffect(() => {
    if (!selectedMetering) {
      return
    }

    if (selectedMetering.text === 'Часы') {
      setUnits(objSeparateUnits.hours)
    } else if (selectedMetering.text === 'Минуты') {
      setUnits(objSeparateUnits.minutes)
    } else if (selectedMetering.text === 'За ребенка') {
      setUnits(unitsStore?.filter((e) => e.text === 'человек'))
      setSelectedValue(unitsStore?.filter((e) => e.text === 'человек')[0] ?? null)
      basedCountChild.setValue('1')
      extraAdditionalChild.setValue(price.value)
    }
  }, [selectedMetering])

  async function sendForm() {
    if (selectedCategory && selectedValue && selectedPark && selectedMetering && description) {
      const form = createFormData({
        base_count: basedCountChild.value,
        description: description,
        name: name.value,
        category_id: String(selectedCategory.id),
        measure_id: String(selectedMetering.id),
        unit_id: String(selectedValue.id),
        extra_price: String(Number(extraAdditionalChild.value)),

        sort: sort.value,
        price: price.value,
        park_id: String(selectedPark.id),
        active: String(Number(useBoolStateCheckbox[0])),
      })

      for (let i = 0; i < files.length; i++) {
        if (files[i].file) {
          form.append(`images[]`, files[i]?.file ?? '')
        }
      }
      if (selectedPatrium && selectedPatrium.id >= 0) {
        form.append(`room_id`, String(selectedPatrium.id))
      }

      if (filesDeleteId.length > 0) {
        form.append('delete_images', filesDeleteId.toString())
      }

      callbackForm(form)
    }
  }

  useEffect(() => {
    if (files.length > 3) {
      setDisabledFile(true)
    } else {
      setDisabledFile(false)
    }
  }, [files])

  useEffect(() => {
    logic.loadRooms()
  }, [])

  return (
    <>
      <ServicesForm
        name={name}
        sort={sort}
        types={types}
        title={title}
        subtitle={subtitle}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        measures={measures}
        selectedMetering={selectedMetering}
        changeUnits={changeUnits}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        units={units}
        sendForm={sendForm}
        useBoolStateCheckbox={useBoolStateCheckbox}
        isAdmin={isAdmin}
        description={description}
        setDescription={setDescription}
        isDisabledFile={isDisabledFile}
        setDisabledFile={setDisabledFile}
        files={files}
        onClickDelete={onClickDeleteFile}
        setUploadFile={addFile}
        basedCountChild={basedCountChild}
        price={price}
        extraAdditionalChild={extraAdditionalChild}
        selectedPatrium={selectedPatrium}
        setSelectedPatrium={setSelectedPatrium}
        patriums={patriums}
      />
    </>
  )
}
