import { itemPhotoFile } from '@components/Settings/ServicePhotoItem'
import { useBoolStateTypeReturn } from '@hooks/commonHooks'
import { UseInputType } from '@hooks/useInput'
import Layout from '@layouts/NonScrollableLayout'
import { Dispatch, SetStateAction } from 'react'
import { Checkbox } from '../Checkbox/Checkbox'
import { LoadPhoto } from '../LoadPhoto/LoadPhoto'
import { UiButton } from '../UiButton/UiButton'
import { UiText } from '../UiText/UiText'
import { UiTextArea } from '../UiTextArea/UiTextArea'
import { UiTextField } from '../UiTextField/UiTextField'

export type AttractionFormProps = {
  name: UseInputType
  desciption: UseInputType
  title: string
  sendForm: () => void
  useBoolStateCheckbox: useBoolStateTypeReturn
  isDisabledFile: boolean
  setDisabledFile: Dispatch<SetStateAction<boolean>>
  files: itemPhotoFile[]
  onClickDelete(index: number, id?: number): void
  setUploadFile(item: itemPhotoFile): void
  isAdmin?: boolean
}
export const AttractionForm = (props: AttractionFormProps) => {
  const {
    name,
    desciption,
    sendForm,
    useBoolStateCheckbox,
    title,
    isDisabledFile,
    setDisabledFile,
    files,
    onClickDelete,
    setUploadFile,
    isAdmin,
  } = props
  return (
    <Layout title={title} scrollable={true}>
      <div className='flex flex-col h-full overflow-x-auto rounded-[3px] gap-[16px]'>
        <div className='flex flex-row  gap-[32px] items-center'>
          <UiTextField className='!w-[233px]' name='Название' {...name} />
        </div>

        <div className='flex flex-row  gap-[32px] items-center'>
          <UiTextArea className='!w-[763px]' title='Описание' {...desciption} />
        </div>
        <div className='flex flex-row gap-[33px]'>
          <LoadPhoto
            isDisabledFile={isDisabledFile}
            setDisabledFile={(isDisabledFile) => setDisabledFile(isDisabledFile)}
            arrayFile={files}
            setUploadFile={setUploadFile}
            onClickDelete={onClickDelete}
          />
        </div>
        <div className='mt-auto min-h-[52px] flex flex-row items-end gap-[16px] border-solid border-0 border-t-[1px] border-grey-secondary'>
          <UiButton onClick={sendForm} className='w-[233px]'>
            Сохранить
          </UiButton>
          {isAdmin && (
            <div className='flex items-center gap-[8px]'>
              <Checkbox useBoolState={useBoolStateCheckbox}></Checkbox>
              <UiText className='text-grey mb-[3px]'>Доступно на сайте</UiText>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}
