export const normalizePhone = (value: string): string => {
  return value
    .split('')
    .filter((symbol) => '0123456789'.includes(symbol))
    .map((digit) => digit)
    .filter((_, index) => index < 11)
    .join('')
}

export const formatPhone = (value: string): string => {
  const firstDigit = value[0]

  if (!!!value) {
    return value
  }
  if (firstDigit === '8') {
    return formatPhone('7' + value.substring(1))
  }
  if (firstDigit !== '7') {
    return formatPhone('7' + value)
  }

  return value
    .split('')
    .map((digit, index) => {
      switch (index) {
        case 0:
          return '+7'

        case 1:
          return '(' + digit

        case 4:
          return ')' + digit

        case 7:
          return '-' + digit

        case 9:
          return '-' + digit

        default:
          return digit
      }
    })
    .join('')
}

export function isOdd(num: number) {
  return Boolean(num % 2)
}

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const formatFullName = (name: string, lastName?: string | null, patronymic?: string | null) => {
  let initials = name.charAt(0).toUpperCase() + '.'
  if (patronymic) {
    initials += ` ${patronymic.charAt(0).toUpperCase()}.`
  }
  if (lastName) {
    return `${lastName} ${initials}`
  }
  if (name && !lastName && !patronymic) return name

  return name
}
