import FormInput from '@components/ui/Form/FormInput'
import FormMultiselect from '@components/ui/Form/FormMultiselect/FormMultiselect'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { Checkbox } from 'primereact/checkbox'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Dropdown } from 'primereact/dropdown'
import { useContext } from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { TGameForm, TGameTime, TPc } from '../../types'

export const GameTimes: TGameTime[] = [
  { duration: 5, name: '5 минут' },
  { duration: 10, name: '10 минут' },
  { duration: 15, name: '15 минут' },
  { duration: 30, name: '30 минут' },
  { duration: 45, name: '45 минут' },
  { duration: 60, name: '60 минут' },
  { duration: 90, name: '90 минут' },
]

export const GameZone = observer(() => {
  const context = useContext(ToastContext)
  const methods = useFormContext<TGameForm>()

  const { fields, remove, append, update } = useFieldArray({
    name: 'gamezone',
    control: methods.control,
  })

  const gameZonesValues = useWatch({
    name: 'gamezone',
    control: methods.control,
  })

  const logic = useLogic()
  const {
    auth: { selectedPark },
    game: { games },
  } = useStore()

  const deleteZone = async (index: number, id: number) => {
    const { status, errors } = await logic.deleteGameZone(id)
    if (!status) {
      context?.toastShowHandler({ status, errors })
      return
    }

    context?.toastShowHandler({ status })
    remove(index)
  }

  const removeGameZoneHandler = async (index: number, id?: number) => {
    if (id)
      confirmDialog({
        message: 'Вы действительно хотите удалить зону?',
        header: 'Удаление зоны',
        icon: 'pi pi-info-circle',
        defaultFocus: 'reject',
        acceptLabel: 'Да',
        rejectLabel: 'Нет',
        acceptClassName: 'p-button-danger',
        accept: async () => await deleteZone(index, id),
      })
    else {
      remove(index)
    }
  }

  const addGameZoneHandler = async () => {
    append({
      name: '',
      pc: { name: '', api_id: '', key: '' },
      time: undefined,
      quests: [],
      api_id: undefined,
      is_solo: false,
    })
  }

  const saveGameZoneHandler = async (index: number) => {
    const formData = new FormData()
    gameZonesValues[index].name && formData.append('name', gameZonesValues[index].name!)
    gameZonesValues[index].pc?.api_id && formData.append('computer_id', gameZonesValues[index].pc!.api_id!)
    formData.append('park_id', String(selectedPark!.id))
    gameZonesValues[index].quests.length > 0 &&
      gameZonesValues[index].quests.forEach((game, i) => formData.append(`games[${i}]`, String(game.id)))

    gameZonesValues[index].time && formData.append('duration', String(gameZonesValues[index].time?.duration))
    formData.append('is_solo', String(+gameZonesValues[index].is_solo))

    const { status, id, errors } = await logic.createGameZone(formData)
    if (status) {
      context?.toastShowHandler({ status })
      update(index, { ...gameZonesValues[index], api_id: id })
    } else context?.toastShowHandler({ status, errors })
  }

  const editGameZoneHandler = async (index: number) => {
    const formData = new FormData()
    gameZonesValues[index].name && formData.append('name', gameZonesValues[index].name!)
    gameZonesValues[index].pc?.api_id && formData.append('computer_id', gameZonesValues[index].pc!.api_id!)
    gameZonesValues[index].quests.length > 0 &&
      gameZonesValues[index].quests.forEach((game, i) => formData.append(`games[${i}]`, String(game.id)))

    gameZonesValues[index].time && formData.append('duration', String(gameZonesValues[index].time?.duration))
    formData.append('is_solo', String(+gameZonesValues[index].is_solo))

    const { status, errors } = await logic.editGameZone(+gameZonesValues[index].api_id!, formData)
    if (status) context?.toastShowHandler({ status, errors })
    else context?.toastShowHandler({ status, errors })
  }

  const selectPCHandler = (pc: TPc, index: number) => {
    const existingGameZones = methods.getValues('gamezone')
    const isIncluded = existingGameZones.some((e) => e?.pc?.name === pc.name)
    !isIncluded
      ? methods.setValue(`gamezone.${index}.pc`, pc)
      : context?.toastShowHandler({ status: false, errors: 'Этот ПК уже выбран в другой зоне' })
  }

  return (
    <div className='flex flex-col gap-4'>
      <ConfirmDialog />
      <div className='flex gap-4 items-center'>
        <UiText size='lg' weight='bold'>
          Зоны квестов
        </UiText>
        <UiButton onClick={addGameZoneHandler} type='button'>
          <i className='pi pi-plus text-sm'></i>
        </UiButton>
      </div>
      {fields.map((e, i) => (
        <div className='flex gap-4' key={e.id}>
          <div className='flex flex-col gap-2'>
            <FormInput className='w-[215px]' name={`gamezone.${i}.name`} placeholder='Введите название зоны' />
            <Controller
              name={`gamezone.${i}.is_solo`}
              control={methods.control}
              rules={{ required: 'Accept is required.' }}
              render={({ field }) => (
                <div className='flex gap-2 items-center'>
                  <Checkbox
                    inputId={field.name}
                    checked={field.value}
                    inputRef={field.ref}
                    onChange={(e) => field.onChange(e.checked)}
                  />
                  <UiText className='text-grey'>Одиночная зона</UiText>
                </div>
              )}
            />
          </div>
          <Controller
            control={methods.control}
            name={`gamezone.${i}.pc`}
            rules={{ required: 'PC is required.' }}
            render={({ field, fieldState }) => (
              <Dropdown
                value={field.value}
                optionLabel='name'
                className={cn(
                  `min-w-[215px] worker shadow-none h-[36px]`,
                  fieldState.error && '!border-red rounded-sm border-solid border'
                )}
                panelClassName='worker-panel'
                placeholder='Выберите ПК'
                color='green'
                options={methods.watch('pcOptions')}
                onChange={(e) => selectPCHandler(e.value, i)}
              />
            )}
          />
          <Controller
            control={methods.control}
            name={`gamezone.${i}.time`}
            rules={{ required: 'Time is required.' }}
            render={({ field, fieldState }) => (
              <Dropdown
                value={field.value}
                optionLabel='name'
                className={cn(
                  `min-w-[215px] worker shadow-none h-[36px]`,
                  fieldState.error && '!border-red rounded-sm border-solid border'
                )}
                panelClassName='worker-panel'
                placeholder='Выберите длительность'
                color='green'
                options={GameTimes}
                onChange={(e) => field.onChange(e.value)}
              />
            )}
          />
          <FormMultiselect
            name={`gamezone.${i}.quests`}
            placeholder='Выберите квесты'
            color='green'
            options={games}
            maxSelectedLabels={10}
          />
          {e.api_id ? (
            <UiButton onClick={() => editGameZoneHandler(i)} type='button'>
              <i className='pi pi-pencil text-sm'></i>
            </UiButton>
          ) : (
            <UiButton onClick={() => saveGameZoneHandler(i)} type='button' color='blue'>
              <i className='pi pi-plus text-sm'></i>
            </UiButton>
          )}
          <UiButton onClick={() => removeGameZoneHandler(i, e.api_id)} type='button' color='red'>
            <i className='pi pi-times text-sm'></i>
          </UiButton>
        </div>
      ))}
    </div>
  )
})
