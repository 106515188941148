import { ApiValidationResponse, FormRoom } from '@@types/apiLogicTypes'
import { RoomContainer } from '@components/ui/RoomContainer/RoomContainer'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { createFormData } from '@utils/createDataForm'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

export const RoomAddScreen = observer(() => {
  const {
    auth: { selectedPark },
    rooms: { types },
  } = useStore()
  const logic = useLogic()

  const context = useContext(ToastContext)

  const title = 'Создание патирума'

  async function sendForm(form: FormRoom) {
    const formData = createFormData(form)
    if (form.images) {
      for (let i = 0; i < form.images.length; i++) {
        if (form.images[i].file) {
          formData.append(`images[]`, form.images[i]?.file ?? '')
        }
      }
    }
    formData.delete('images')
    formData.delete('delete_images')

    const { status, errors }: ApiValidationResponse = await logic.createRoom(formData)

    if (status) {
      navigateTo(AppRoutesPaths.SettingsRooms)
    } else {
      context?.toastShowHandler({ status: status, errors })
    }
  }

  return (
    <>
      {selectedPark && (
        <RoomContainer title={title} types={types ?? []} callbackForm={sendForm} selectedPark={selectedPark} />
      )}
    </>
  )
})
