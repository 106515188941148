import { ApiCreateApplicationForm, ApiPayment } from '@@types/apiLogicTypes'
import FormInput from '@components/ui/Form/FormInput'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import {
  GetDataCheck,
  IKkmClient,
  IKkmItem,
  IKkmPaymentCardResult,
  IKkmRegisterCheckResult,
  PayByPaymentCard,
  RegisterCheck,
} from '@logic/kkmLogic'
import { IEvent } from '@modules/Schedule/SchedulePage'
import { typePaymentArray } from '@modules/TicketSale/Form/TotalPrice/TotalPrice'
import { createFormData } from '@utils/createDataForm'
import { formatFullName, formatPhone } from '@utils/textUtils'
import { Dialog } from 'primereact/dialog'
import { SelectButton } from 'primereact/selectbutton'
import { FC, useCallback, useContext, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

interface IPaymentPart {
  sum: number
  initialState: IEvent
  changeEventStatus: (status: number) => void
}

const PaymentPart: FC<IPaymentPart> = ({ sum, initialState, changeEventStatus }) => {
  const methods = useFormContext<ApiCreateApplicationForm>()
  const logic = useLogic()
  const context = useContext(ToastContext)
  const [payButtonLoading, setIsPayButtonLoading] = useState(false)
  const [preorderButtonLoading, setIsPreorderButtonLoading] = useState(false)
  const [isPopupVisible, setIsPopapVisible] = useState(false)

  const [preorderSum, bonuses, orderSum, order] = useWatch({
    control: methods.control,
    name: ['preorder_sum', 'order.bonuses', 'sum', 'order'],
  })

  const {
    auth: { user },
  } = useStore()

  const operatorName = formatFullName(user!.name, user?.surname, user?.patronymic)

  const isOrderPrePaid = initialState?.status === 5
  const isOrderPaid = initialState.status === 4

  //Входная точка при оплате
  const onCreateOrder = async (isPreorder?: boolean, paymentType?: '0' | '1') => {
    const form = methods.getValues()
    !isPreorder && setIsPayButtonLoading(true)
    isPreorder && setIsPreorderButtonLoading(true)

    const formData = createFormData({
      sum: isPreorder ? preorderSum : (orderSum ?? sum) - bonuses - preorderSum,
      payment_type: paymentType !== undefined ? paymentType : form.paymentType.type === 'cash' ? '0' : '1',
      bonus_sum: bonuses,
    })

    const { status, payment } = await logic.createPaymentHoliday(initialState.id, formData)

    if (!status) {
      context?.toastShowHandler({
        status: false,
        errors: 'Произошла ошибка при подтверждении платежа. Пожалуйста, обратитесь к администратору',
      })
      setIsPayButtonLoading(false)
      setIsPreorderButtonLoading(false)
      return
    }

    onPay(payment.id, isPreorder, paymentType)
  }

  const calcPaymentData = useCallback(
    (isPreorder: boolean) => {
      const amount = isPreorder ? preorderSum : (orderSum ?? sum) - bonuses - preorderSum

      const kkmItems: IKkmItem[] = [
        {
          name: order.holiday.name,
          quantity: 1,
          price: isPreorder ? amount : orderSum ?? sum,
          amount: amount,
          type: 4,
          measure: 0,
          signMethodCalculation: isPreorder ? 2 : 4,
        },
      ]

      const kkmClient: IKkmClient = {
        name: order.user!.name,
        phone: formatPhone(order.user!.phone!),
        email: order.user?.email,
      }

      return { items: kkmItems, client: kkmClient, amount: amount }
    },
    [preorderSum, bonuses, orderSum, order]
  )

  const onPay = async (paymentId: number, isPreorder?: boolean, paymentType?: '0' | '1') => {
    const form = methods.getValues()

    !isPreorder && setIsPayButtonLoading(true)
    isPreorder && setIsPreorderButtonLoading(true)

    const { items: kkmItems, client: kkmClient, amount } = calcPaymentData(isPreorder ?? false)

    if (paymentType !== undefined ? paymentType === '0' : form?.paymentType.type === 'cash') {
      const formData = new FormData()
      isPreorder && formData.append('is_prepayment', '1')
      const { status, data } = await logic.applyPayment(formData, paymentId)
      if (!status) {
        context?.toastShowHandler({
          status: false,
          errors: 'Произошла ошибка при подтверждении платежа. Пожалуйста, обратитесь к администратору',
        })
        return
      }

      await RegisterCheck({
        typeCheck: 0,
        operatorName: operatorName,
        items: kkmItems,
        client: kkmClient,
        sum: amount,
        callback: (result: IKkmRegisterCheckResult) => registerPaymentCallback(result, 'cash', data),
        type: 'cash',
        advancePayment: 0,
        credit: 0,
      })
    } else {
      await PayByPaymentCard(amount, (result: any) =>
        payByPaymentCardCallback(result, amount, kkmItems, kkmClient, paymentId, isPreorder)
      )
    }
  }

  //Колбек для функции оплаты банковской картой.
  //Проверяет ответ от эквайрингового аппарата и в зависимости от этого либо печатает чек, либо выводит ошибку.
  const payByPaymentCardCallback = async (
    result: IKkmPaymentCardResult,
    sum: number,
    kkmItems: IKkmItem[],
    kkmClient: IKkmClient,
    paymentId: number,
    isPreorder?: boolean
  ) => {
    if (result.Status === 0) {
      const formData = new FormData()
      formData.append('payment_id', result.UniversalID)
      isPreorder && formData.append('is_prepayment', '1')
      const { status, data } = await logic.applyPayment(formData, paymentId)
      if (!status) {
        context?.toastShowHandler({
          status: false,
          errors: 'Произошла ошибка при подтверждении платежа. Пожалуйста, обратитесь к администратору',
        })
        return
      }
      await RegisterCheck({
        typeCheck: 0,
        operatorName: operatorName,
        items: kkmItems,
        client: kkmClient,
        sum: sum,
        callback: (newResult: IKkmRegisterCheckResult) => registerPaymentCallback(newResult, 'online', data),
        type: 'online',
        advancePayment: 0,
        credit: 0,
      })
    } else if (result.Status === 2) {
      context?.toastShowHandler({ status: false, errors: result.Error })
      setIsPayButtonLoading(false)
    } else if (result.Status === 3) {
      context?.toastShowHandler({ status: false, errors: 'Устройство не найдено' })
      setIsPayButtonLoading(false)
    }
  }

  const fiskalPrepayment = async () => {
    const paymentType = initialState.payments.find((e) => e.checks?.length === 0)!.type === 0 ? 'cash' : 'online'
    const { items: kkmItems, client: kkmClient, amount } = calcPaymentData(true)

    await RegisterCheck({
      typeCheck: 0,
      operatorName: operatorName,
      items: kkmItems,
      client: kkmClient,
      sum: amount,
      callback: (result: IKkmRegisterCheckResult) =>
        registerPaymentCallback(result, paymentType, initialState.payments[0]),
      type: paymentType,
      advancePayment: 0,
      credit: 0,
    })
  }

  //Колбек для печати чека платежа
  const registerPaymentCallback = async (
    result: IKkmRegisterCheckResult,
    paymentType: 'cash' | 'online',
    payment: ApiPayment
  ) => {
    const handleError = async (error: string) => {
      context?.toastShowHandler({ status: false, errors: error })
      const formData = createFormData({ success: 0, comment: error })
      await logic.checkSetTicket(formData, payment.id)
    }

    const notFoundError = 'Данные не найдены'

    switch (result.Status) {
      case 0:
        await setCheckHandler(result.CheckNumber, payment!.id, 1)
        break
      case 2:
        await handleError(result.Error)
        break
      case 3:
        context?.toastShowHandler({ status: false, errors: notFoundError })

        if (paymentType === 'online') {
          const formData = createFormData({ comment: notFoundError })
          await logic.errorPaymentTicket(formData, payment!.id)
        }
        break
    }
  }

  const setCheckHandler = async (fiskalNumber: number, id: number, success: 0 | 1) => {
    await GetDataCheck(fiskalNumber, async (result: any) => {
      if (result.Status === 0) {
        const formData = createFormData({ slip: result.Slip, id: id, success: success, fiskal_number: fiskalNumber })
        const { status, errors } = await logic.checkSetTicket(formData, id)
        if (!status) context?.toastShowHandler({ status: false, errors: errors })
        await changeEventStatus(4)
      }
      if (result.Status === 2) context?.toastShowHandler({ status: false, errors: result.Error })
      else if (result.Status === 3) context?.toastShowHandler({ status: false, errors: 'Данные не найдены' })
    })
  }

  const getLink = async () => {
    const formData = createFormData({ sum: methods.getValues('preorder_sum') })
    const { status, data, errors } = await logic.generateLink(initialState.id, formData)
    if (!status) context?.toastShowHandler({ status: false, errors: errors })
    else {
      await navigator.clipboard.writeText(data)
      context?.toastShowHandler({ status: true, summary: 'Ссылка скопирована' })
    }
  }

  const showPopup = async () => {
    setIsPopapVisible(true)
  }

  const PreOrderPaymentTypeSelect = () => {
    return (
      <>
        <div className='flex gap-3 mb-3'>
          <UiButton
            color='green'
            type='button'
            className='!min-w-[150px]'
            onClick={() => onCreateOrder(true, '0')}
            disabled={isOrderPrePaid}
            isLoading={preorderButtonLoading}
          >
            Наличными
          </UiButton>
          <UiButton
            color='yellow'
            type='button'
            className='!min-w-[150px]'
            onClick={() => onCreateOrder(true, '1')}
            disabled={isOrderPrePaid}
            isLoading={preorderButtonLoading}
          >
            Картой
          </UiButton>
        </div>
        <UiButton color='blue' type='button' className='!min-w-[150px]' onClick={getLink} disabled={isOrderPrePaid}>
          Ссылка
        </UiButton>
      </>
    )
  }

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex gap-4 items-center'>
        <UiText size='lg' weight='bold'>
          Оплата
        </UiText>
        <SelectButton
          value={methods.watch('paymentType')}
          onChange={(e) => methods.setValue('paymentType', e.value)}
          optionLabel='ru'
          options={typePaymentArray.slice(0, -1)}
          className='selectButton'
          allowEmpty={false}
          disabled={isOrderPaid}
        />
      </div>
      <div className='flex items-center gap-4'>
        <UiText className='min-w-[100px]'>
          Итого <UiText className='text-green text-sm'>({sum})</UiText>
        </UiText>

        <FormInput
          className='min-w-[142px]'
          name='sum'
          placeholder='Введите сумму'
          customPattern={/^\d+$/}
          type='number'
          disabled={isOrderPaid}
        />
      </div>
      <div className='flex items-center gap-4'>
        <UiText className='min-w-[100px]'>{isOrderPrePaid ? 'Оплачено' : 'Предоплата'}</UiText>

        <FormInput
          className='min-w-[142px]'
          name='preorder_sum'
          placeholder='Введите сумму'
          customPattern={/^\d+$/}
          type='number'
          disabled={isOrderPaid || isOrderPrePaid}
        />
      </div>
      <div className='flex items-center gap-4'>
        <UiText className='min-w-[100px]'>
          Бонусы <UiText className='text-grey text-sm'>({methods.watch('order.user.bonus')})</UiText>
        </UiText>

        <FormInput
          placeholder='Сумма'
          name='order.bonuses'
          type='number'
          min={0}
          max={Math.min(+methods.watch('order.user.bonus'), sum)}
          className='min-w-[142px]'
          disabled={isOrderPaid}
        />
      </div>
      <div className='flex gap-4 items-center h-[36px]'>
        <div className='flex items-center gap-4'>
          <UiText className='min-w-[100px]'>{isOrderPaid ? 'Оплачено' : 'К оплате'}</UiText>

          <UiText className='font-bold'>
            {isOrderPaid
              ? (methods.watch('sum') ?? sum) + +methods.watch('preorder_sum')
              : Math.max(
                  (methods.watch('sum') ?? sum) - +methods.watch('order.bonuses') - +methods.watch('preorder_sum'),
                  0
                )}
          </UiText>
        </div>
      </div>
      {!isOrderPaid && (
        <div className='flex gap-4 w-full'>
          <UiButton type='button' className='w-[60%]' onClick={() => onCreateOrder()} isLoading={payButtonLoading}>
            Оплатить
          </UiButton>
          <UiButton color='blue' type='button' className='w-[40%]' onClick={showPopup} disabled={isOrderPrePaid}>
            Предоплата
          </UiButton>
        </div>
      )}

      {/* если есть онлайн платеж без чека, то думаем, что заказ предоплачен и не фискализирован. */}
      {initialState.payments.some((e) => e.checks?.length === 0 && e.status === 2) && (
        <UiButton color='blue' type='button' className='w-[40%]' onClick={fiskalPrepayment}>
          Фискализация предоплаты
        </UiButton>
      )}
      <Dialog
        visible={isPopupVisible}
        onHide={() => setIsPopapVisible(false)}
        headerClassName='py-2 '
        header='Способ предоплаты'
        contentClassName='pt-2 h-fit pb-4'
        draggable={false}
      >
        <PreOrderPaymentTypeSelect />
      </Dialog>
    </div>
  )
}

export default PaymentPart
