import { ApiCreateTicketForm, ApiValidationResponse } from '@@types/apiLogicTypes'
import { TicketContainer } from '@components/ui/TicketContainer/TicketContainer'
import { useLogic, useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'

import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useContext } from 'react'

export const TicketAddScreen = observer(() => {
  const {
    services: { units: unitsStore, measures },
    ticket: { types },
    auth: { selectedPark },
  } = useStore()
  const logic = useLogic()

  const context = useContext(ToastContext)

  const title = 'Создание билета'

  async function sendForm(form: ApiCreateTicketForm) {
    const { status, errors }: ApiValidationResponse = await logic.createTicket(form)

    if (status) {
      navigateTo(AppRoutesPaths.SettingTicket)
    } else {
      context?.toastShowHandler({ status: status, errors: errors })
    }
  }

  return (
    <>
      {selectedPark && (
        <TicketContainer
          title={title}
          types={types ?? []}
          measures={measures ?? []}
          sendForm={sendForm}
          selectedPark={selectedPark}
          unitsStore={unitsStore ?? []}
        />
      )}
    </>
  )
})
